export const filterTypeStrings = {
  header: {
    filterTypeList: "Filters Register",
    createFilterType: "Create Filter Type",
    editFilterType: "Edit Filter Type",
    filterTypeDetails: "Filter Type Details",
    deleteItem: "Delete Filter Type",
  },
  labels: {
    id: "ID",
    name: "Name",
    createFilterType: "Create Filter Type",
    editFilterType: "Edit Filter Type",
    reset: "Reset",
    search: "Search",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    confirm: "Confirm",
    newFilterType: "New Filter Type",
    filterTypeName: "Filter Type Name",
    compatibleAssetType: "Compatible Asset Type",
    compatibleAssetTypes: "Compatible Asset Types",
    type: "Type",
    model: "Model",
    gtin: "GTIN",
    ref: "Ref",
    maxReplacementPeriod: "Max Replacement Period",
    create: "Create",
    update: "Update",
  },
  placeholders: {
    id: "ID",
    name: "Name",
  },
  text: {
    copyDepot: "Are you sure you would like to create a new Depot?",
    deleteConfirmation: "Are you sure you would like to delete this Filter Type?",
  },
};
