import {Masonry} from "@mui/lab";
import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import {BreadcrumbHeader} from "../components/general/BreadcrumbHeader";
import {LocationSelectBox} from "../components/general/controls/LocationSelectBox";
import AlertSummaryPane from "../Containers/dashboard/alertSummary/AlertSummaryPane";
import AssetFiltersList from "../Containers/dashboard/asset filters/AssetFiltersList";
import TaskSchedulePane from "../Containers/dashboard/taskSchedule/TaskSchedulePane";
import WorkStatusPane from "../Containers/dashboard/workStatus/WorkStatusPane";
import {useSessionStorage} from "../hooks/general/useSessionStorage";
import "../styles/Dashboard.css";
import {RouteProps} from "../types";

export interface ILocation {
  Id: string;
  Name: string;
}

const DashboardContent: Component = ({...routeProps}) => {
  const [locationId] = useSessionStorage<string>("", "locationId");
  const [locationName] = useSessionStorage<string>("No Location Selected", "locationName");
  const [masonryColumnCount, setMasonryColumnCount] = useState(2);
  const [selectedLocation, setSelectedLocation] = useState<ILocation>({
    Id: locationId,
    Name: locationName,
  });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1300) {
        setMasonryColumnCount(1);
      } else {
        setMasonryColumnCount(2);
      }
    }
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSelectedLocation({Id: locationId, Name: locationName});
  }, [locationId, locationName]);

  return (
    <>
      <Box className="dashboard-container" width="100%">
        <Masonry columns={masonryColumnCount} spacing={2}>
          {
            <>
              <div className="dashboard-masonry">
                <BreadcrumbHeader header={"Selected Location"} children={[]} spacing={1.3} hideBgColor={true} />
                <div style={{padding: 10}}>
                  <Box display="flex" width="100%">
                    <LocationSelectBox mode={"view"} handleChange={undefined} />
                  </Box>
                </div>
              </div>
              <div className="dashboard-masonry">
                <BreadcrumbHeader header={"Task Schedule"} children={[]} spacing={1.3} hideBgColor={true} />
                <TaskSchedulePane permissions={null} user={null} locationId={selectedLocation.Id} locationName={selectedLocation.Name} />
              </div>
              <div className="dashboard-masonry">
                <WorkStatusPane permissions={null} user={null} locationId={selectedLocation.Id} locationName={selectedLocation.Name} />
              </div>
              <div className="dashboard-masonry">
                <AlertSummaryPane permissions={null} user={null} locationId={selectedLocation.Id} locationName={selectedLocation.Name} />
              </div>
              <div className="dashboard-masonry">
                <AssetFiltersList permissions={null} user={null} locationId={selectedLocation.Id} locationName={selectedLocation.Name} />
              </div>
            </>
          }
        </Masonry>
      </Box>
    </>
  );
};

interface Props extends RouteProps {
  selectedLocation?: ILocation;
}

type Component = (props: Props) => JSX.Element;

const Dashboard: Component = ({...routeProps}) => {
  return <DashboardContent user={routeProps.user} permissions={routeProps.permissions} />;
};

export default Dashboard;
