import {Button, ClassNameMap} from "@mui/material";
import clsx from "clsx";

type CreateButtonStylingOptions = {
  color: "inherit" | "primary" | "secondary" | "default";
  variant: "text" | "outlined" | "contained";
};

interface Props {
  onClickHandler: Function;
  icon?: JSX.Element;
  startIcon?: JSX.Element;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  stylingOptions: CreateButtonStylingOptions;
  useStyles?: (props?: any) => ClassNameMap;
  className?: string;
}
type Component = (props: Props) => JSX.Element | null;

export const IconButton: Component = ({onClickHandler, icon, startIcon, children, style, stylingOptions: styling, className, useStyles = () => null}) => {
  const customStyling = {
    textTransform: "none" as any,
    fontWeight: "400",
  };

  return (
    <Button className={clsx(className)} style={{...customStyling, ...style}} onClick={() => onClickHandler()} startIcon={startIcon} variant={styling.variant}>
      {icon}
      {children}
    </Button>
  );
};
