import {AxiosRequestConfig} from "axios";
import axios from "../libs/axios/axios-user";
import {CreateFunction, CreateResult, DeleteFunction, QueryFunction, ReadFunction, UpdateFunction, UpdateResult} from "../types/API";
import {User, UserFilters} from "../types/documents/User";

const query: QueryFunction<User> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const path = "";
  const filters = query as UserFilters;
  const {id, desc} = sort[0] ?? {};
  const config: AxiosRequestConfig = {
    params: {...filters, page: page + 1, size: size, sort: id, order: desc ? "desc" : "asc"},
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };

  const {data} = await axios.get<{items: User[]; totalCount: number}>(path, config);
  const users = data.items.map((u) => ({...u, DisplayName: `${u.FirstName} ${u.LastName}`}));

  return {
    items: users,
    totalCount: data.totalCount,
  };
};

const create: CreateFunction<User> = async ({payload}, jwt?, cancelToken?) => {
  const path = ``;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const createPayload: User = {
    Id: "00000000-0000-0000-0000-000000000000",
    FirstName: payload.FirstName,
    LastName: payload.LastName,
    Email: payload.Email,
    Password: payload.Password,
    Role: payload.Role,
    ClientId: payload.UserClientId ? payload.UserClientId : payload.ClientId,
    Client: payload.Client,
  };

  console.log(createPayload);
  const {data} = await axios.post<CreateResult>(path, createPayload, config);
  return data;
};

const update: UpdateFunction<User> = async ({payload}, jwt?, cancelToken?) => {
  const updatePayload: User = {
    Id: payload.Id,
    FirstName: payload.FirstName,
    LastName: payload.LastName,
    Email: payload.Email,
    Password: "",
    Role: payload.Role,
    ClientId: payload.UserClientId ? payload.UserClientId : payload.ClientId,
    Client: payload.Client,
  };
  const {Id} = payload;
  const path = `/${Id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.put<UpdateResult>(path, updatePayload, config);
  return data;
};

const read: ReadFunction<User> = async (id, _childId, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get<User>(path, config);
  data.Role[0].toUpperCase();
  data.UserClientId = data.ClientId;
  return {
    item: {...data},
  };
};

const getUser: ReadFunction<User> = async (id, childId?, jwt?, cancelToken?) => {
  const path = `${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data;
};

const deleteUser: DeleteFunction<User> = async (id, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  await axios.delete(path, config);
};

const deleteUsersForClient: DeleteFunction<User> = async (id, jwt?, cancelToken?) => {
  const path = `/client/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  await axios.delete(path, config);
};

export const api = {
  query,
  getUser,
  read,
  create,
  update,
  deleteUser,
  deleteUsersForClient,
};
