import {useCallback} from "react";
import type {Mode} from "../../components/general/types/Modify";
import {useClientContext} from "../../context/ClientContext";
import {createDropdownOptions} from "../../helpers/dropdown-helpers";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {paths} from "../../navigation/paths";
import {taskStrings as strings} from "../../resources/strings/tasks";
import {api} from "../../services/tasks.service";
import type {RouteProps, ValidationConstraints} from "../../types";
import {Task} from "../../types/documents/Task";
import {ModifyContainer} from "../general/ModifyContainer";
import {TasksConfig} from "./TasksConfig";
interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;
type ListItems = {[key: string]: any}[];

export const TasksModify: Component = ({mode, ...routeProps}) => {
  const cancelToken = useCancelToken();
  const jwt = useToken();
  const {clientId} = useClientContext();

  const getTaskTypes = useCallback(async () => {
    const defaultTaskTypes: ListItems = [{Key: "1"}];
    if (!jwt) {
      return createDropdownOptions(defaultTaskTypes, "Key", "1");
    }
    const items = await api.getTaskTypes(jwt, cancelToken);
    return createDropdownOptions(items.items, "Id", "Name");
  }, [jwt]);

  const taskConstraints: ValidationConstraints<Task> = {
    Name: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.notes} is required`,
      },
    },
    DueDate: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.dueDate} is required`,
      },
    },
    TaskTypeId: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.taskType} is required`,
      },
    },
    LocationId: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.location} is required`,
      },
    },
    LocationDepth: {
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 4,
        lessThanOrEqualTo: 4,
        message: `^${strings.labels.location} must be a room`,
      },
    },
    AssetId: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.asset} is required`,
      },
    },
  };

  return (
    <div>
      <ModifyContainer<Task>
        {...routeProps}
        api={api}
        componentConfiguration={(props) => TasksConfig(props, jwt, getTaskTypes)}
        constraints={taskConstraints}
        initialData={{
          Id: "",
          Name: "",
          DueDate: "",
          TaskTypeName: "",
          LocationId: "",
          LocationName: "",
          AssignedToName: "",
          TaskTypeId: "",
          CompletedDate: "",
          ClientId: clientId ?? "",
          AssetId: "",
          TaskStatus: undefined,
        }}
        mode={mode}
        redirectPath={paths.tasks.list}
      />
    </div>
  );
};
