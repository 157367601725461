import axios from "axios";
import interceptors from "./interceptors";

export const createInstance = (
	baseURL: string,
	onRequest = interceptors.onRequest
) => {
	let adapter;
	if (process.env.NODE_ENV === "test") {
		adapter = require("axios/lib/adapters/http");
	}
	const instance = axios.create({
		baseURL,
		adapter,
	});
	instance.interceptors.request.use(onRequest, interceptors.onRequestError);
	instance.interceptors.response.use(
		interceptors.onResponse,
		interceptors.onResponseError
	);
	return instance;
};

export const createAnonymousInstance = (baseURL: string) =>
	createInstance(baseURL, (config) => Promise.resolve(config));
