export const locationStrings = {
  header: {
    locationList: "Locations",
    deleteItem: "Delete Location",
    clientList: "Clients",
    createClient: "Create Client",
    editClient: "Edit Client",
    clientDetails: "Client Details",
    deleteClient: "Delete Client",
    siteList: "Sites",
    createSite: "Create Site",
    editSite: "Edit Site",
    siteDetails: "Site Details",
    deleteSite: "Delete Site",
    buildingList: "Buildings",
    createBuilding: "Create Building",
    editBuilding: "Edit Building",
    buildingDetails: "Building Details",
    deleteBuilding: "Delete Building",
    zoneList: "Zones",
    createZone: "Create Zone",
    editZone: "Edit Zone",
    zoneDetails: "Zone Details",
    deleteZone: "Delete Zone",
    floorList: "Floors",
    createFloor: "Create Floor",
    editFloor: "Edit Floor",
    floorDetails: "Floor Details",
    deleteFloor: "Delete Floor",
    roomList: "Rooms",
    createRoom: "Create Room",
    editRoom: "Edit Room",
    roomDetails: "Room Details",
    deleteRoom: "Delete Room",
  },
  labels: {
    id: "ID",
    name: "Name",
    createFilterType: "Create Filter Type",
    editFilterType: "Edit Filter Type",
    reset: "Reset",
    search: "Search",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    confirm: "Confirm",
    newClient: "New Client",
    newSite: "New Site",
    newBuilding: "New Building",
    newZone: "New Zone",
    newFloor: "New Floor",
    newRoom: "New Room",
    client: "Client",
    site: "Site",
    building: "Building",
    zone: "Zone",
    floor: "Floor",
    room: "Room",
    clientName: "Client Name",
    siteName: "Site Name",
    buildingName: "Building Name",
    zoneName: "Zone Name",
    floorName: "Floor Name",
    roomName: "Room Name",
    create: "Create",
  },
  placeholders: {
    id: "ID",
    name: "Name",
  },
  text: {
    deleteConfirmation: "Are you sure you would like to delete this Location?",
  },
};
