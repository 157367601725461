import {Grid} from "@mui/material";
import type {FilterChangeHandler} from "../../types";
import {Link} from "../general/controls/Link";
import {ChangeHandler} from "../general/types/Modify";
import {SearchBox} from "./SearchBox";

interface SearchBarProps {
  filters: Filters;
  handleFilterChange: FilterChangeHandler;
  clearFilters: () => void;
  tags: {};
  resetLabel?: string;
  additionalFilterComponents?: JSX.Element;
}
type Component = (props: SearchBarProps) => JSX.Element;
type Filters = {Search?: string};

export const SearchBar: Component = ({filters: {Search}, handleFilterChange, clearFilters, resetLabel = "Reset", additionalFilterComponents}) => {
  const handleChange: ChangeHandler = (e) => {
    const {name, value} = e.target;
    if (!name) return;
    handleFilterChange(name, value);
  };

  return (
    <>
    <Grid container role="searchbox" >
      <Grid container spacing={2} wrap="nowrap" role="">
        <Grid item>
          <SearchBox filters={{Search}} handleFilterChange={handleChange} />
        </Grid>
        
        <Grid item>
          <Link
            config={{
              text: resetLabel,
              variant: "body2",
              onClick: clearFilters,
            }}
          />
        </Grid>
      </Grid>
      {additionalFilterComponents && (
        <Grid container spacing={2} paddingTop={1} role="">
          {additionalFilterComponents}
        </Grid>
      )}
    </Grid>
      
      
    </>
  );
};
