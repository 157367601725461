import {Link, SxProps, Theme, Typography} from "@mui/material";
import {format} from "date-fns";
import {useState} from "react";
import {logger} from "../../helpers/log-helpers";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import type {DownloadFunction} from "../../types/API";
import {Loader} from "./Loader";

interface Props {
  config: {
    label: string;
    downloadFunction: DownloadFunction;
    location: string;
    filenamePrefix: string;
    days?: number;
    start?: string;
    end?: string;
    types?: string;
  };
  permissions?: Permissions | null;
}

export const CSVExportButton = ({config: {label, downloadFunction, location, filenamePrefix, days, start, end, types}}: Props) => {
  const jwt = useToken();
  const cancelToken = useCancelToken();
  const [loading, setLoading] = useState(() => false);

  const rootStyles: SxProps<Theme> = {
    "&:hover": {
      cursor: "pointer",
    },
  };

  const download = async (token: string) => {
    try {
      setLoading(true);
      const csvBytes = await downloadFunction({locationId: location, format: "csv", days, start, end, types}, token, cancelToken);
      setLoading(false);
      return csvBytes;
    } catch (e) {
      setLoading(false);
      logger.error(e);
      if (cancelToken.reason) return;
    }
  };

  const onClick = async (e: any) => {
    e.preventDefault();
    const csvData = await download(jwt);

    // If CSV data was returned, generate a temporary download link for it
    if (csvData !== undefined) {
      var blobFile = new Blob([csvData.toString()], {type: "text/csv"});
      const url = window.URL.createObjectURL(blobFile);
      const link = document.createElement("a");
      const dateString = format(new Date(), "ddMMMyyyy-hhmm");
      link.href = url;
      link.setAttribute("download", `${filenamePrefix}_${dateString}.csv`);

      // Add this link to the page
      document.body.appendChild(link);

      // Initiate the download
      link.click();

      // Remove the link
      if (link.parentNode !== null && link.parentNode !== undefined) {
        link.parentNode.removeChild(link);
      }
    }
  };

  return (
    <>
      <Typography component={"span"} sx={[rootStyles]}>
        <Link color="primary" underline="always" href={undefined} onClick={onClick} variant="inherit" className="dashboard-csv-link">
          Download CSV
        </Link>
      </Typography>
      <Loader active={loading}></Loader>
    </>
  );
};
