export const workStatusStrings = {
  header: {
    workStatus: "Work Status",
  },
  labels: {
    task: "Task",
    overdue: "Overdue",
    dueNextNDays: "Due Next 7 Days",
    numberOfDays: "Number of Days",
  },
  placeholders: {
    task: "Task",
    overdue: "Overdue",
    dueNextNDays: "Due Next 7 Days",
    numberOfDays: "Number of Days",
  },
  text: {},
};
