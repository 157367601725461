import {Tooltip} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {importStrings as strings} from "../../resources/strings/imports";
import {api} from "../../services/validationErrors.service";
import {RouteProps} from "../../types";
import {ValidationError} from "../../types/documents/Import";
import {TableContainer} from "../general/TableContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const ValidationErrorList: Component = ({...routeProps}) => {
  const cancelToken = useCancelToken();
  const history = useNavigate();

  const {id} = useParams<{id?: string}>();

  return (
    <div>
      <TableContainer<ValidationError>
        {...routeProps}
        api={api}
        tableColumns={[
          {
            id: "location",
            Header: strings.labels.location,
            accessor: "RowNumber",
            maxWidth: 12,
          },
          {
            id: "errorDetails",
            Header: strings.labels.errorDetails,
            accessor: (x) => {
              return (
                <Tooltip title={x.ErrorMessages} arrow>
                  <p>{x.ErrorMessages}</p>
                </Tooltip>
              );
            },
          },
        ]}
        manualPagination={true}
        filters={{Id: id} as any}
        scrollMaxRowCount={5}
        scrollMaxBodyHeight={315}
      ></TableContainer>
    </div>
  );
};

export default ValidationErrorList;
