import type { SxProps, Theme } from "@mui/material";
import { Button as MuiButton, buttonClasses, ButtonProps } from "@mui/material";
import "../../fonts/Extratype-Eina04-Bold.otf";
import "../../styles/App.css";

interface Props extends ButtonProps {
  label: string | JSX.Element;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  fontFamily: "T-Safe-Bold",
  boxShadow: "none",
  fontWeight: "bolder",
  minWidth: theme.spacing(16),
  margin: theme.spacing(0, 1),
  borderRadius: "20px",
});

const outlinedStyles: SxProps<Theme> = (theme) => ({
  [`&.${buttonClasses.outlined}`]: {
    border: theme.spacing(0.3, "solid"),
    "&:hover": {
      border: theme.spacing(0.3, "solid"),
    },
  },
});

export const Button: Component = ({ label, sx = [], ...props }) => {
  return (
    <MuiButton
      color="primary"
      variant="contained"
      sx={[rootStyles, outlinedStyles, ...(Array.isArray(sx) ? sx : [sx])]}
      {...props}
    >
      {label}
    </MuiButton>
  );
};
