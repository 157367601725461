import {CheckCircleOutline, Clear as CloseIcon, ErrorOutline} from "@mui/icons-material";
import {Box, CircularProgress, Grid, Modal as MuiModal, styled, SxProps, Theme, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Button} from "../../Containers/general/Button";
import {IconAction} from "../../Containers/general/IconAction";
import ValidationErrorList from "../../Containers/imports/ValidationErrorList";
import "../../fonts/Extratype-Eina04-SemiBold.otf";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import paths from "../../navigation/paths";
import {importStrings as strings} from "../../resources/strings/imports";
import {api} from "../../services/imports.service";
import {Import} from "../../types/documents/Import";

interface Props {
  header: string;
  id: string | undefined;
  status: string;
  paddedContent?: boolean;
}

type Component = (props: Props) => JSX.Element;

interface HeaderProps {
  onClose: () => void;
  header: Props["header"];
}

const borderRadius = 0.5;

const spacerStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(2),
});

const headerStyles: SxProps<Theme> = (theme) => ({
  borderRadius: theme.spacing(borderRadius, borderRadius, 0, 0),
  backgroundColor: theme.palette.background.paper,
  color: "#666666",
  fontFamily: "T-Safe-SemiBold",
  fontWeight: "bolder",
});

const containerStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(borderRadius),
  display: "flex",
  flexDirection: "column",
  //alignItems: "center",
});

const headStyles: SxProps<Theme> = (theme) => ({
  fontSize: "x-large",
  fontFamily: "T-Safe-SemiBold",
  //fontWeight: "bolder",
});

const rejectStyles: SxProps<Theme> = (theme) => ({
  color: "#666666",
  backgroundColor: "#FFFFFF",
  fontFamily: "T-Safe-Bold",
});

const approveStyles: SxProps<Theme> = (theme) => ({
  color: "#2643D6",
  backgroundColor: "#FFFFFF",
  fontFamily: "T-Safe-Bold",
});

const ModalHeader = ({onClose, header}: HeaderProps) => {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={[spacerStyles, headerStyles]}>
      <Grid item>
        <Typography sx={[headStyles]}>{header}</Typography>
      </Grid>
      <Grid item>
        <IconAction tooltip="" onClick={onClose}>
          <CloseIcon sx={{color: "#666666"}} data-testid="close-icon" />
        </IconAction>
      </Grid>
    </Grid>
  );
};

export const ImportModal: Component = ({header, id, status, paddedContent = true}) => {
  const history = useNavigate();
  const cancelToken = useCancelToken();
  const jwt = useToken();
  const [currentImport, setCurrentImport] = useState<Import | undefined>(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    history(paths.import.list);
    setModalIsOpen(false);
  };

  useEffect(() => {
    async function getImport(id: string) {
      try {
        const result = await api.read(id, "", jwt);
        setCurrentImport(result.item);
      } catch (error) {
        console.log("Could not retrieve the specified Import");
      }
    }

    if (id !== undefined && id !== null) {
      getImport(id);
    }
  }, [jwt, id]);

  const rejectImport: any = async () => {
    console.log("Rejecting the import with id: " + id);
    await api.rejectImport(id ? id : "", jwt, cancelToken);
    closeModal();
    window.location.reload();
  };

  const approveImport: any = async () => {
    console.log("Approving the import with id: " + id);
    await api.approveImport(id ? id : "", jwt, cancelToken);
    closeModal();
    window.location.reload();
  };

  const uploadingDataBody = (
    <>
      <Typography align="center">
        <CircularProgress />
        <br></br>
        {strings.labels.uploading}
      </Typography>
      <br></br>
      <Typography align="center">{strings.text.uploadCSVMessage}</Typography>
    </>
  );

  const processingDataBody = (
    <>
      <Typography align="center">
        <CircularProgress />
        <br></br>
        {strings.labels.processing}
      </Typography>
      <br></br>
      <Typography align="center">{strings.text.processCSVMessage}</Typography>
    </>
  );

  const uploadFailedBody = (
    <>
      <Typography align="center">
        <ErrorOutline color="error" />
        <br></br>
        {strings.labels.uploadFailed}
      </Typography>
      <br></br>
      <Typography align="center">{strings.text.uploadFailedMessage}</Typography>
    </>
  );

  const processingFailedBody = (
    <>
      <Typography align="center">
        <ErrorOutline color="error" />
        <br></br>
        {strings.labels.processingFailed}
      </Typography>
      <br></br>
      <Typography align="center">{strings.text.processingFailedMessage}</Typography>
      {currentImport !== undefined && currentImport !== null && currentImport.ErrorMessage !== undefined && currentImport.ErrorMessage !== null ? (
        <Typography align="center" style={{fontWeight: "bold"}}>
          Error Details: {currentImport.ErrorMessage}
        </Typography>
      ) : (
        <></>
      )}
    </>
  );

  const pendingApprovalBody = (
    <>
      <Typography align="center">
        <CheckCircleOutline color="success" />
        <br></br>
        {strings.labels.validationComplete}
      </Typography>
      <br></br>
      <Typography align="center">{strings.text.validationCompleteMessage}</Typography>
      <Grid container justifyContent="flex-end" sx={[spacerStyles]}>
        <Grid item>
          <Button label={strings.labels.reject} onClick={rejectImport} sx={[rejectStyles]} />
        </Grid>
        <Grid item>
          <Button label={strings.labels.approve} onClick={approveImport} sx={[approveStyles]} />
        </Grid>
      </Grid>
    </>
  );

  const validationFailedBody = (
    <>
      <Typography align="center">
        <ErrorOutline color="error" />
        <br></br>
        {strings.labels.validationFailed}
      </Typography>
      <br></br>
      <Typography align="center">{strings.text.validationFailedMessage}</Typography>
      <ValidationErrorList permissions={null} user={null} />
    </>
  );

  let modalBody = <></>;

  switch (status) {
    case "uploadingData":
      modalBody = uploadingDataBody;
      break;
    case "processingData":
      modalBody = processingDataBody;
      break;
    case "processingFailed":
      modalBody = processingFailedBody;
      break;
    case "uploadFailed":
      modalBody = uploadFailedBody;
      break;
    case "pendingApproval":
      modalBody = pendingApprovalBody;
      break;
    case "validationFailed":
      modalBody = validationFailedBody;
      break;
    default:
      modalBody = <></>;
  }

  const Span = styled("span")({});

  return (
    <>
      <MuiModal open={modalIsOpen}>
        <Box display={"flex"} height="100vh" width="100vw" justifyContent="center" alignItems={"center"}>
          <Grid item xs={12} md={6} sx={containerStyles}>
            <ModalHeader onClose={closeModal} header={header} />
            <Span sx={[paddedContent && spacerStyles]}>{modalBody}</Span>
          </Grid>
        </Box>
      </MuiModal>
    </>
  );
};
