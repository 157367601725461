import {useParams} from "react-router-dom";
import type {Mode} from "../../../components/general/types/Modify";
import {SiteBreadcrumbHeader} from "../../../components/location/site/SiteBreadcrumbHeader";
import {useClientContext} from "../../../context/ClientContext";
import {paths} from "../../../navigation/paths";
import {locationStrings as strings} from "../../../resources/strings/locations";
import {api} from "../../../services/locations.service";
import type {RouteProps} from "../../../types";
import {Location} from "../../../types/documents/Location";
import {ModifyContainer} from "../../general/ModifyContainer";

interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const SitesModify: Component = ({mode, ...routeProps}) => {
  const {parentId} = useParams<{parentId?: string}>();
  const {clientId} = useClientContext();

  return (
    <div>
      <ModifyContainer<Location>
        {...routeProps}
        api={api}
        componentConfiguration={[
          {
            key: "header",
            content: [
              {
                controltype: "custom",
                Component: SiteBreadcrumbHeader,
                md: 12,
                control: false,
              },
            ],
          },
          {
            key: "fields",
            content: [
              {
                controltype: "header",
                text: strings.header.siteDetails,
              },
              {
                controltype: "input",
                name: "Name",
                label: strings.labels.siteName,
              },
            ],
          },
        ]}
        constraints={{
          Name: {
            presence: {
              allowEmpty: false,
              message: `^${strings.labels.siteName} is required`,
            },
          },
        }}
        initialData={{
          Name: "",
          ClientId: clientId ?? "",
          ParentId: mode === "create" ? parentId : "",
        }}
        mode={mode}
        redirectPath={paths.locations.list}
      />
    </div>
  );
};
