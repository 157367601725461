import {Delete, Download} from "@mui/icons-material";
import {useMemo, useState} from "react";
import {IconButton} from "../../components/common/IconButton";
import {SearchBar} from "../../components/common/SearchBar";
import {UploadButton} from "../../components/common/UploadButton";
import {DeleteModal} from "../../components/general/DeleteModal";
import {formatDateTime} from "../../helpers/date-helpers";
import {useRole} from "../../hooks/authentication/useRole";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import {strings} from "../../resources/strings/documents";
import {api} from "../../services/documents.service";
import type {FilterChangeHandler, RouteProps} from "../../types";
import {Document, DocumentFilters, DocumentSort} from "../../types/documents/Document";
import {TableContainer} from "../general/TableContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const DocumentsList: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<DocumentFilters>({}, "documentFilters");
  const handleFilterChange: FilterChangeHandler = (name, value) => setFilters((currentFilters) => ({...currentFilters, [name]: value}));
  const clearFilters = () => setFilters({});
  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleDelete = async () => {
    setShowModal(false);
    api.deleteItem(selectedId, jwt, cancelToken);
    window.location.reload();
  };

  const handleDownload = async (id: string, fileName: string) => {
    const docBytes = await api.download(id, jwt, cancelToken);
    if (docBytes !== undefined) {
      var blobFile = new Blob([docBytes.toString()], {type: "application/pdf"});
      const url = window.URL.createObjectURL(blobFile);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);

      // Add this link to the page
      document.body.appendChild(link);

      // Initiate the download
      link.click();

      // Remove the link
      if (link.parentNode !== null && link.parentNode !== undefined) {
        link.parentNode.removeChild(link);
      }
    }
  };

  const jwt = useToken();
  const cancelToken = useCancelToken();
  const role = useRole();
  const isSuperAdmin: boolean = role === "superadmin";

  const headerContent = isSuperAdmin ? (
    <>
      <UploadButton
        label={"Upload Document"}
        allowedFileTypes={["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]}
        allowedFileExtensions={["pdf", "docx"]}
        api={api}
      />
      <DeleteModal
        header={strings?.header?.deleteItem}
        confirmationMessage={strings?.text?.deleteConfirmation}
        handleDelete={handleDelete}
        isOpen={showModal}
        setIsOpen={setShowModal}
        setTrigger={false}
      />
    </>
  ) : (
    <></>
  );

  return (
    <div>
      <TableContainer<Document>
        api={api}
        header={strings.header.listItems}
        headerContent={() => headerContent}
        tableColumns={[
          {
            id: DocumentSort.FileName,
            Header: strings.labels.title,
            accessor: "FileName",
          },
          {
            id: DocumentSort.LastChanged,
            Header: strings.labels.dateAdded,
            accessor: ({CreatedDate}) => (CreatedDate ? formatDateTime(CreatedDate) : "-"),
          },
          {
            id: DocumentSort.DocumentType,
            Header: strings.labels.documentCategory,
            accessor: ({DocumentCategory}) => (DocumentCategory ? DocumentCategory.Name : "-"),
          },
          {
            id: DocumentSort.MobileVisibilityStatus,
            Header: strings.labels.mobileVisibilityStatus,
            accessor: (doc) => {
              return doc.IsVisibleOnMobile === "true" ? strings.labels.visible : strings.labels.invisible;
            },
          },
          {
            id: DocumentSort.Id,
            Header: strings.labels.actions,
            accessor: (x) => {
              return (
                <>
                  {x.FileName !== undefined && (
                    <IconButton
                      onClickHandler={() => handleDownload(x.Id, x.FileName as string)}
                      icon={<Download />}
                      stylingOptions={{color: "inherit", variant: "text"}}
                    ></IconButton>
                  )}
                  {isSuperAdmin && (
                    <IconButton
                      onClickHandler={() => {
                        setSelectedId(x.Id);
                        setShowModal(true);
                      }}
                      icon={<Delete />}
                      stylingOptions={{color: "inherit", variant: "text"}}
                    ></IconButton>
                  )}
                </>
              );
            },
          },
        ]}
        manualPagination={true}
        children={<SearchBar filters={filters} handleFilterChange={handleFilterChange} clearFilters={clearFilters} tags={{}} />}
        filters={tableFilters as any}
        defaultSort={[
          {
            id: DocumentSort.FileName,
            desc: true,
          },
        ]}
        sortStorageKey={"documentSort"}
        clickHandler={() => {}}
      />
    </div>
  );
};

export default DocumentsList;
