import { useNavigate } from "react-router-dom";
import { Mode } from "../../components/general/types/Modify";
import { logger } from "../../helpers/log-helpers";
import paths from "../../navigation/paths";
import { userStrings as strings } from "../../resources/strings/users";
import { api } from "../../services/users.service";
import { RouteProps } from "../../types";
import { User } from "../../types/documents/User";
import { ModifyContainer } from "../general/ModifyContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

interface Props extends RouteProps {
  mode: Mode;
}

const LogIn: Component = ({ mode, ...routeProps }) => {
  const history = useNavigate();

  const handleLogIn = () => {
    try {
      history(paths.dashboard);
    } catch (e) {
      logger.error(e);
    }
  };
  return (
    <div>
      <ModifyContainer<User>
        {...routeProps}
        api={api}
        componentConfiguration={[
          {
            key: "fields",
            content: [
              {
                controltype: "header",
                text: strings.header.logIn,
              },
              {
                controltype: "input",
                name: "Email",
                label: strings.labels.email,
              },
              {
                controltype: "button",
                name: "filler",
                label: "",
              },
              {
                controltype: "input",
                name: "Password",
                label: strings.labels.password,
              },
              {
                controltype: "button",
                name: "filler",
                label: "",
              },
              {
                controltype: "button",
                name: "login",
                label: strings.labels.logIn,
              },
            ],
          },
        ]}
        constraints={{}}
        initialData={{
          Id: "",
          FirstName: "",
          LastName: "",
          ClientId: "",
          Client: "",
          Email: "",
          Role: "",
          Password: "",
        }}
        mode={mode}
        redirectPath={paths.users.list}
      />
    </div>
  );
};

export default LogIn;
