import axios, { CancelToken } from "axios";
import { useEffect, useMemo } from "react";

export const useCancelToken = (): CancelToken => {
	const source = useMemo(() => axios.CancelToken.source(), []);
	useEffect(() => {
		return () => {
			source.cancel("Cancelling request, component unmounted");
		};
	}, [source]);
	return source.token;
};
