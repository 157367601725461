import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ClientContext,
  ClientContextProvider,
  useClientContext,
} from "../../../context/ClientContext";
import { useCancelToken } from "../../../hooks/general/useCancelToken";
import { api } from "../../../services/clients.service";
import type { Dropdown as TDropdown } from "../types/Modify";

export interface Props<D extends object> {
  config?: Omit<TDropdown<D>, "controltype">;
  jwt: string;
}

export interface IClient {
  Id?: string;
  Name?: string;
}

export const ClientSelector = <D extends object>({
  jwt,
}: Props<D>): JSX.Element => {
  const cancelToken = useCancelToken();
  const { clientId, setClientId } = useClientContext();

  const [clients, setClients] = useState<IClient[]>([{ Id: "", Name: "" }]);
  const [selectedClient, setSelectedClient] = useState<IClient>({
    Id: "",
    Name: "",
  });

  useEffect(() => {
    if (jwt) {
      // Get client list and current client from API upon Initialisation of the control
      getClientsFromApi();
      getCurrentClientFromApi();
    }
  }, [cancelToken, jwt, setClientId]);

  const handleClick = () => {
    getClientsFromApi();
    getCurrentClientFromApi();
  };

  const getClientsFromApi = async () => {
    api.query({ size: 100000 }, jwt).then((clientsList) => {
      setClients(
        clientsList.items.map(({ Id, Name, ...rest }) => {
          return { Id, Name };
        })
      );
    });
  };

  const getCurrentClientFromApi = async () => {
    api.getCurrentClient(jwt, cancelToken).then((client) => {
      const currentClient: IClient = { Id: client?.Id, Name: client?.Name };
      setSelectedClient(currentClient);
      if (client === null) {
        setClientId(null);
      } else {
        setClientId(currentClient.Id);
      }
    });
  };

  // Set Current Client updates the API and Database, and returns the new client ID to the Client Context
  const setCurrentClient = async (newClientId: string | undefined) => {
    api.setCurrentClient(newClientId, jwt).then(() => {
      setClientId(newClientId ? newClientId : null); //null means no client is selected
      console.log(
        `Setting new Client ID for user: ${newClientId ? newClientId : null}`
      );
    });
  };

  const clientContext: ClientContext = { clientId, setClientId };

  const clientSelectorStyle = {
    marginRight: "1rem",
    width: 250,
    "& .MuiInputBase-root": {
      //fontSize: 5,
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#222D54",
      color: "#222D54",
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
      borderColor: "#222D54",
      color: "#222D54",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#222D54",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#222D54",
    },
    "& .MuiOutlinedInput-input": {
      color: "#222D54",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#222D54",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#222D54",
    },
    "& .MuiInputLabel-outlined": {
      color: "#222D54",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#222D54",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#222D54",
    },
  };

  return (
    <ClientContextProvider value={clientContext}>
      <Autocomplete
        disablePortal
        defaultValue={selectedClient}
        value={selectedClient}
        options={clients}
        sx={clientSelectorStyle}
        getOptionLabel={(option) => option.Name || ""}
        isOptionEqualToValue={(option, value) => option.Id === value.Id}
        onChange={(_, value) => {
          setCurrentClient(value?.Id);
          setSelectedClient({ Id: value?.Id, Name: value?.Name });
        }}
        renderInput={(params) => (
          <TextField
            onClick={handleClick}
            sx={{
              input: {
                color: "#222D54",
              },
            }}
            {...params}
            size="small"
            label="Select Client"
          />
        )}
      />
    </ClientContextProvider>
  );
};
