import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {detailErrorResponse} from "../../helpers/control-helpers";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {notifications} from "../../libs/notifications";
import {paths} from "../../navigation/paths";
import {userStrings as strings} from "../../resources/strings/users";
import {api} from "../../services/users.service";
import type {User} from "../../types/documents/User";
import {BreadcrumbHeader} from "../general/BreadcrumbHeader";
import {CreateButtons} from "../general/CreateButtons";
import {Header} from "../general/Header";
import type {CustomProps} from "../general/types/Modify";
import {UpdateDeleteButtons} from "../general/UpdateDeleteButtons";

interface Props extends CustomProps<User> {}
type Component = (props: Props) => JSX.Element;

export const UserBreadcrumbHeader: Component = ({mode}) => {
  const {id} = useParams<{id?: string}>();
  const jwt = useToken();
  const cancelToken = useCancelToken();
  const history = useNavigate();
  const [toDelete, setToDelete] = useState(false);

  useEffect(() => {
    async function deleteItem(id: string) {
      try {
        await api.deleteUser(id, jwt, cancelToken); //Error handling
        setToDelete(false);
        handleBack();
        notifications.success("User successfully deleted");
      } catch (error) {
        notifications.error(detailErrorResponse(error));
      }
    }
    if (id && toDelete) {
      deleteItem(id);
    }
  }, [id, jwt, cancelToken, toDelete]);

  const handleBack = () => {
    history(paths.users.list);
  };

  const handleDelete = () => {
    setToDelete(true);
  };

  const createButtons = <CreateButtons strings={strings} handleBack={handleBack} />;

  const updateDeleteButtons = <UpdateDeleteButtons strings={strings} handleDelete={handleDelete} handleBack={handleBack} />;

  return (
    <BreadcrumbHeader
      header={mode === "create" ? <Header text={strings.header.createUser} /> : <Header text={strings.header.editUser} />}
      children={mode === "create" ? <>{createButtons}</> : <>{updateDeleteButtons}</>}
    />
  );
};
