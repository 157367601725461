import {SxProps, Theme} from "@mui/material";
import {useEffect, useState} from "react";
import {Button} from "../../../Containers/general/Button";
import LocationsList from "../../../Containers/locations/LocationsList";
import {useToken} from "../../../hooks/authentication/useToken";
import {useSessionStorage} from "../../../hooks/general/useSessionStorage";
import {locationStrings as strings} from "../../../resources/strings/locations";
import {api as locationsApi} from "../../../services/locations.service";
import {Modal} from "../Modal";
import type {ChangeHandler, LocationSelect} from "../types/Modify";
import {Mode} from "../types/Modify";
import {Input} from "./Input";

export interface Props<D extends object> {
  config?: Omit<LocationSelect<D>, "controltype">;
  mode: Mode;
  handleChange: ChangeHandler | any | undefined;
}

export const LocationSelectBox = <D extends object>({mode, config, handleChange}: Props<D>): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  let controlHasError = false;
  let controlErrorText = null;
  if (config !== undefined) {
    controlHasError = config.error ?? false;
    controlErrorText = config.helperText;
  }

  // Update session storage values only if no handle change function was set (i.e. from the dashboard)
  const storeInSessionStorage = handleChange === undefined;
  const initialLocationId = (config?.value as string) ?? "";
  const initialLocationName = "";
  const jwt = useToken();

  //Set up session storage, for when we call the component from the dashboard (as dashboard widgets will retrieve from here)
  const [storageLocationId, setStorageLocationId] = useSessionStorage<string>("", "locationId");
  const [storageLocationName, setStorageLocationName] = useSessionStorage<string>("", "locationName");

  //Initialise the component state
  const [confirmedLocationId, setConfirmedLocationId] = useState<string>(storeInSessionStorage ? storageLocationId : initialLocationId);
  const [confirmedLocationName, setConfirmedLocationName] = useState<string>(storeInSessionStorage ? storageLocationName : initialLocationName);
  const [confirmedLocationDepth, setConfirmedLocationDepth] = useState<number | null | undefined>(undefined);
  const [locationId, setLocationId] = useState<string>(confirmedLocationId);
  const [locationName, setLocationName] = useState<string>(confirmedLocationName);
  const [locationDepth, setLocationDepth] = useState<number | null | undefined>(confirmedLocationDepth);

  useEffect(() => {
    async function getLocation(id: string) {
      try {
        const result = await locationsApi.read(id, "", jwt);
        setConfirmedLocationName(result.item.FullLocationName ?? result.item.Name);
        setConfirmedLocationDepth(result.item.Depth);
      } catch (error) {
        console.log("Could not retrieve the location name or depth");
      }
    }

    if (initialLocationId && jwt && !storeInSessionStorage) {
      getLocation(initialLocationId);
    }
  }, [initialLocationId, jwt, storeInSessionStorage]);

  const setSelectedLocation = (id: string, name: string, depth: number) => {
    console.log("Setting the selected location as " + id + " " + name + " at depth " + depth);
    setLocationId(id);
    setLocationName(name);
    setLocationDepth(depth);
    if (storeInSessionStorage) {
      setStorageLocationId(id);
      setStorageLocationName(name);
    }
  };

  const handleConfirm = (e: any) => {
    console.log("Confirmed location selection as " + locationId + " " + locationName);
    setConfirmedLocationId(locationId);
    setConfirmedLocationName(locationName);
    setConfirmedLocationDepth(locationDepth);
    if (storeInSessionStorage) {
      window.location.reload(); //Currently needed to refresh the dashboard
    } else {
      handleChange(e, {selectedLocation: {LocationName: locationName, LocationId: locationId, LocationDepth: locationDepth}});
    }
  };

  const handleCancel = (e: any) => {
    //Return to the previously confirmed location id
    console.log("Cancelling the selected location");
    setLocationId(confirmedLocationId);
    setLocationName(confirmedLocationName);
    setLocationDepth(confirmedLocationDepth);
    if (storeInSessionStorage) {
      setStorageLocationId(confirmedLocationId);
      setStorageLocationName(confirmedLocationName);
      window.location.reload(); //Currently needed to refresh the dashboard
    } else {
      handleChange(e, {selectedLocation: {LocationName: confirmedLocationName, LocationId: confirmedLocationId, LocationDepth: confirmedLocationDepth}});
    }
  };

  const handleClear = () => {
    console.log("Clearing the selected location");
    setLocationId("");
    setLocationName("");
    setLocationDepth(null);
    setConfirmedLocationId("");
    setConfirmedLocationName("");
    setConfirmedLocationDepth(null);
    if (storeInSessionStorage) {
      setStorageLocationId("");
      setStorageLocationName("");
      window.location.reload(); //Currently needed to refresh the dashboard
    } else {
      handleChange({target: {value: undefined}}, {selectedLocation: {LocationName: "", LocationId: "", LocationDepth: null}});
      window.location.reload();
    }
  };

  const handleClick = () => {
    setModalOpen(true);
  };

  const modalActionsStyles: SxProps<Theme> = (theme) => ({
    color: "#2643D6",
    borderRadius: 4,
    backgroundColor: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#2643D6",
    },
  });

  const cancelButtonStyles: SxProps<Theme> = (theme) => ({
    color: "#CC3333",
    backgroundColor: "white",
    borderColor: "#CC3333",
    borderRadius: 4,
    "&:hover": {
      color: "white",
      backgroundColor: "#CC3333",
    },
  });

  return (
    <>
      <Input
        config={{
          name: "selectedLocation",
          placeholder: "Selected Location",
          value: confirmedLocationName,
          label: "Location",
          error: controlHasError ?? undefined,
          helperText: controlErrorText ?? undefined,
        }}
        clearable={true}
        multiline={true}
        handleChange={() => {}}
        handleClear={handleClear}
        handleClick={handleClick}
      />
      <Modal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        header={"Select Location"}
        locationSelect={true}
        actions={({onClose}) => [
          <Button
            label={strings.labels.cancel}
            onClick={(e) => {
              handleCancel(e);
              onClose();
            }}
            variant="outlined"
            sx={[cancelButtonStyles]}
          />,
          <Button
            label={strings.labels.confirm}
            onClick={(e) => {
              handleConfirm(e);
              onClose();
            }}
            variant="outlined"
            sx={[modalActionsStyles]}
          />,
        ]}
      >
        <LocationsList mode={mode} setSelectedLocation={setSelectedLocation} listPage={false}/>
      </Modal>
    </>
  );
};
