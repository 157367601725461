import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { BreadcrumbHeader } from "../../../components/general/BreadcrumbHeader";
import { CSVExportButton } from "../../../components/general/CSVExportButton";
import { useSessionStorage } from "../../../hooks/general/useSessionStorage";
import { assetFilterStrings as strings } from "../../../resources/strings/assetFilters";
import { api } from "../../../services/assetFilters.service";
import { api as assetsApi } from "../../../services/assets.service";
import { RouteProps } from "../../../types";
import { AssetFilter } from "../../../types/documents/AssetFilter";
import { TableContainer } from "../../general/TableContainer";

interface Props extends RouteProps {
  locationId: string;
  locationName: string;
}
type Component = (props: Props) => JSX.Element;

const AssetFiltersList: Component = ({ ...routeProps }) => {
  const [locationId, setLocationId] = useSessionStorage<string>(
    routeProps.locationId,
    "locationId"
  );
  const [locationName, setLocationName] = useSessionStorage<string>(
    routeProps.locationName,
    "locationName"
  );

  const [Id, setId] = useState(locationId);

  useEffect(() => {
    setId(locationId);
  }, [locationId, locationName]);

  return (
    <Box position="relative" height="100%">
      <>
        <div>
          <BreadcrumbHeader
            header={"Installed Filters"}
            children={[]}
            spacing={1.3}
            hideBgColor={true}
          />
          <TableContainer<AssetFilter>
            {...routeProps}
            api={api}
            tableColumns={[
              {
                id: "FilterTypeName",
                Header: strings.labels.filterType,
                accessor: "FilterTypeName",
              },
              {
                id: "Count",
                Header: strings.labels.filterCount,
                accessor: "Count",
              },
            ]}
            filters={Id === "NSL" ? ({} as any) : ({ Location: Id } as any)}
            scrollMaxBodyHeight={450}
            scrollMaxRowCount={7}
          ></TableContainer>
          <br></br>
        </div>

        <div style={{ float: "right", padding: "0px 15px 10px 0px" }}>
          <CSVExportButton
            config={{
              label: "Download CSV",
              downloadFunction: assetsApi.download,
              location: Id === "NSL" || Id === undefined ? "" : Id,
              filenamePrefix: "AssetData",
            }}
          />
        </div>
      </>
    </Box>
  );
};

export default AssetFiltersList;
