export const assetStrings = {
  header: {
    assetList: "Assets",
    assetInfo: "Asset Details",
    createAsset: "Create Asset",
    editAsset: "Edit Asset",
    deleteItem: "Delete Asset",
  },
  labels: {
    id: "ID",
    notes: "Notes",
    barcode: "Asset Barcode",
    type: "Type",
    status: "Status",
    filterInstalled: "Filter Installed",
    location: "Location",
    lastChanged: "Last Changed",
    locationId: "Location ID",
    assetType: "Asset Type",
    assetTypeId: "Asset Type ID",
    createdDate: "Created Date",
    createdBy: "Created By",
    updatedDate: "Updated Date",
    updatedBy: "Updated By",
    search: "Search",
    reset: "Reset",
    edit: "Edit",
    cancel: "Cancel",
    delete: "Delete",
    create: "Create",
    save: "Save",
    confirm: "Confirm",
  },
  text: {
    deleteConfirmation: "Are you sure you would like to delete this Asset?",
  },
};
