export type Asset = {
  AssetId: string;
  Id: string;
  AssetTypeName?: string;
  AssetBarcode?: string;
  AssetStatus?: string;
  FilterTypeModel?: string;
  FullLocationName?: string;
  AssetFilterInstalled?: string;
  Notes?: string;
  LocationId?: string;
  LocationDepth?: number | null;
  AssetTypeId?: string;
  FilterTypeName?: string;
};

export type AssetType = {
  Id: string;
  Name: string;
  Make: string;
  Model: string;
  CreatedDate: string;
  CreatedBy: string;
  UpdatedDate: string;
  UpdatedBy: string;
  Active: boolean;
};

export type AssetFilters = Partial<{
  LocationId: string;
  Search: string;
}>;

export enum AssetSort {
  AssetType = "Asset Type",
  AssetBarcode = "Asset Barcode",
  Status = "Status",
  FilterInstalled = "Filter Installed",
  Location = "Location",
  LastChanged = "Last Changed",
}
