import { date } from "faker";

export const strings = {
  header: {
    listItems: "Documents",
    itemDetails: "Document Details",
    createItem: "Create Document",
    editItem: "Edit Document",
    deleteItem: "Delete Document",
  },
  labels: {
    id: "ID",
    fileName: "File Name",
    type: "Type",
    status: "Status",
    lastChanged: "Last Changed",
    createdDate: "Created Date",
    createdBy: "Created By",
    updatedDate: "Updated Date",
    updatedBy: "Updated By",
    search: "Search",
    reset: "Reset",
    edit: "Edit",
    cancel: "Cancel",
    delete: "Delete",
    create: "Create",
    save: "Save",
    confirm: "Confirm",
    actions: "Actions",
    title: "Title",
    dateAdded: "Date Added",
    documentCategory: "Document Category",
    mobileVisibilityStatus: "Mobile Visibility Status",
    visible: "Visible",
    invisible: "Invisible",
  },
  text: {
    deleteConfirmation: "Are you sure you would like to delete this Document?",
  },
};
