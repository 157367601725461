export const taskStrings = {
  header: {
    taskList: "Tasks",
    createTask: "Create Task",
    editTask: "Edit Task",
    taskDetails: "Task Details",
    deleteTask: "Delete Task",
    deleteItem: "Delete Task",
  },
  labels: {
    id: "ID",
    name: "Name",
    createTask: "Create Task",
    create: "Create",
    editTask: "Edit Task",
    reset: "Reset",
    search: "Search",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    confirm: "Confirm",
    newTask: "New Task",
    taskName: "Task Name",
    taskNotes: "Notes",
    dueDate: "Due Date",
    completedDate: "Completed Date",
    status: "Status",
    taskType: "Task Type",
    taskLocation: "Task Location",
    assignedTo: "Assigned To",
    typeId: "Type ID",
    locationId: "Location ID",
    location: "Location",
    assetId: "Asset ID",
    asset: "Asset",
    bulkAssign: "Allocate to User",
    from: "From",
    to: "To",
    selectUser: "Select User",
    saveUserAllocation: "Save User Allocation",
    allocateTasks: "Allocate Tasks",
    unassigned: "Unassigned",
    notes: "Notes",
  },
  placeholders: {
    id: "ID",
    name: "Name",
    select: "-select-",
  },
  text: {
    deleteConfirmation: "Are you sure you want to delete this task? This cannot be undone.",
  },
  statusName: {
    CompletedOnTime: "Completed On Time",
    CompletedLate: "Completed Late",
    CompletedEarly: "Completed Early",
    Missed: "Missed",
    Future: "Future",
  },
};
