import type { SxProps, Theme } from "@mui/material";
import { Typography } from "@mui/material";
import "../../fonts/Extratype-Eina04-SemiBold.otf";
import "../../styles/App.css";

interface Props {
  text: string;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  fontFamily: "T-Safe-SemiBold",
  fontWeight: "bolder",
  color: "#666666",
});

export const Header: Component = ({ text }) => {
  return (
    <Typography variant="h6" sx={[rootStyles]}>
      {text}
    </Typography>
  );
};
