export const importStrings = {
  headers: {
    importList: "Imports",
    importCSV: "Import CSV",
  },
  labels: {
    id: "ID",
    name: "Name",
    fileName: "File Name",
    importDate: "Import Date",
    importType: "Import Type",
    format: "Format",
    status: "Status",
    review: "Review",
    approveReject: "Approve/Reject",
    uploadCSV: "Upload CSV",
    reset: "Reset",
    search: "Search",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    confirm: "Confirm",
    newFilterType: "New Filter Type",
    type: "Type",
    model: "Model",
    maxReplacementPeriod: "Max Replacement Period",
    compatibleAssetTypes: "Compatible Asset Types",
    importStatus: "Import Status",
    approvedStatus: "Approved Status",
    uploading: "Uploading",
    processing: "Processing",
    uploadFailed: "Upload Failed",
    processingFailed: "Processing Failed",
    validationComplete: "Validation Complete",
    validationFailed: "Validation Failed",
    approve: "Approve",
    reject: "Reject",
    location: "Location",
    errorDetails: "Error Details",
  },
  placeholders: {
    id: "ID",
    name: "Name",
    selectImportType: "Select Import Type",
  },
  text: {
    copyDepot: "Are you sure you would like to create a new Depot?",
    uploadCSVMessage: "Your CSV is currently uploading, please wait.",
    processCSVMessage: "Your CSV is currently processing, please wait.",
    uploadFailedMessage:
      "Your CSV failed to upload. Please close and try again.",
    processingFailedMessage:
      "Your CSV failed to upload. Please try your upload again.",
    validationCompleteMessage:
      "Validation has returned no errors. Please approve or reject this CSV.",
    validationFailedMessage:
      "Your CSV file contains errors. Please check and retry upload.",
  },
};
