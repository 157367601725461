export const strings = {
  labels: {
    id: "ID",
    fileName: "File Name",
    type: "Type",
    status: "Status",
    lastChanged: "Last Changed",
    createdDate: "Created Date",
    createdBy: "Created By",
    updatedDate: "Updated Date",
    updatedBy: "Updated By",
    search: "Search",
    reset: "Reset",
    edit: "Edit",
    cancel: "Cancel",
    delete: "Delete",
    create: "Create",
    save: "Save",
    confirm: "Confirm",
    actions: "Actions",
  },
};
