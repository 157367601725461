import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {CreateButton} from "../../components/common/CreateButton";
import {SearchBar} from "../../components/common/SearchBar";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import paths from "../../navigation/paths";
import {auditTrailStrings as strings} from "../../resources/strings/auditTrail";
import {api} from "../../services/auditTrail.service";
import {FilterChangeHandler, RouteProps} from "../../types";
import type {AuditTrail, AuditTrailFilters} from "../../types/documents/AuditTrail";
import {FilterType} from "../../types/documents/FilterType";
import {TableContainer} from "../general/TableContainer";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Grid } from "@mui/material";
import { DatePicker } from "../../components/general/controls/DatePicker";
import { ChangeHandler } from "../../components/general/types/Modify";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const AuditTrailList: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<AuditTrailFilters>({}, "auditTrailFilters");

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const cancelToken = useCancelToken();
  const history = useNavigate();

  const additionalFilterChangeHandler: ChangeHandler = (event) => {
    const target = event.target;
    console.log(target.name)
    if (target.name) {
      handleFilterChange(target.name, target.value);
    }
  };

  const renderAdditionalFilters = () => {
    return (
      <>
        <Grid item xs={2}>
          <BaseControl control={false}>
            <DatePicker
              config={{
                name: "StartDate",
                placeholder: strings.placeholders.select,
                label: strings.labels.startDate,
                value: filters.StartDate ?? "",
              }}
              handleChange={additionalFilterChangeHandler}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={2}>
          <BaseControl control={false}>
            <DatePicker
              config={{
                name: "EndDate",
                placeholder: strings.placeholders.select,
                label: strings.labels.endDate,
                value: filters.EndDate ?? "",
              }}
              handleChange={additionalFilterChangeHandler}
            />
          </BaseControl>
        </Grid>
      </>
    );
  };

  return (
    <div>
      <TableContainer<AuditTrail>
        {...routeProps}
        api={api}
        tableColumns={[
          {
            id: "entityType",
            Header: strings.labels.entityType,
            accessor: "EntityType",
          },
          {
            id: "operation",
            Header: strings.labels.operation,
            accessor: "Operation",
          },
          {
            id: "updatedBy",
            Header: strings.labels.updatedBy,
            accessor: "FullUserName",
          },
          {
            id: "updatedDate",
            Header: strings.labels.updatedDate,
            accessor: (auditEntry) => (auditEntry.UpdatedDate ? new Date(auditEntry.UpdatedDate).toLocaleString() : "-"),
          },
          {
            id: "entityId",
            Header: strings.labels.entityId,
            accessor: "EntityId",
          },
        ]}
        header={strings.header.auditTrailList}
        children={<SearchBar filters={filters} handleFilterChange={handleFilterChange} clearFilters={clearFilters} additionalFilterComponents={renderAdditionalFilters()} tags={{}} />}
        manualPagination={true}
        filters={tableFilters as any}
        clickHandler={() => {}}
      ></TableContainer>
    </div>
  );
};

export default AuditTrailList;
