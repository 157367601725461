import { AxiosRequestConfig } from "axios";
import axios from "../libs/axios/axios-asset";
import { QueryFunction } from "../types/API";
import {
  AssetFilter,
  AssetFilterFilters,
} from "../types/documents/AssetFilter";

const query: QueryFunction<AssetFilter> = async (
  { page = 0, size = 10, sort = [], query },
  jwt?,
  cancelToken?
) => {
  const filters = query as AssetFilterFilters;
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const { data } = await axios.get<{ items: AssetFilter[] }>(
    `/installed-filters/summary`,
    config
  );
  return {
    items: data.items,
    totalCount: data.items.length,
  };
};

export const api = {
  query,
};
