import {Box, SxProps, Theme} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {FileOutputFormat, FileReaderResult} from "../../helpers/file-parser";
import {Button} from "../general/Button";
import {FileUpload} from "../general/FileUpload";
import {Modal} from "../general/Modal";
import { api as documentsApi } from "../../services/documents.service";
import { useToken } from "../../hooks/authentication/useToken";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { detailErrorResponse } from "../../helpers/control-helpers";
import { notifications } from "../../libs/notifications";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";

interface UploadButtonProps {
  label: string;
  allowedFileTypes: string[];
  allowedFileExtensions: string[];
  api: any;
}

type Component = (props: UploadButtonProps) => JSX.Element;

export const UploadButton: Component = ({label, allowedFileTypes, allowedFileExtensions, api}) => {
  const [fileData, setFileData] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryDropdownOptions, setCategoryDropdownOptions] = useState([]);
  const handleFileSelect = useCallback(async ({data}: FileReaderResult) => {
    if (typeof data === "string") setFileData(data);
  }, []);

  const jwt = useToken();
  const cancelToken = useCancelToken();

  useEffect(() => {
    if (!jwt) return;
  
    const fetchCategories = async () => {
      try {
        const data = await documentsApi.getCategories({}, jwt, cancelToken);
        setCategories(data.items as any);
      } catch (error: any) {
        const errorInformation = detailErrorResponse(error);
        notifications.error(errorInformation);
      }
    };
  
    fetchCategories();
  }, [jwt, cancelToken]);

  useEffect(() => {
    if (categories.length > 0) {
      const options = createDropdownOptions(categories, "Id", "Name");
      setCategoryDropdownOptions(options as any);
    }
  }, [categories]); 

  const rootStyles: SxProps<Theme> = (theme) => ({
    boxShadow: "none",
    fontWeight: "bolder",
    minWidth: theme.spacing(16),
    margin: theme.spacing(0, 1),
    borderRadius: "20px",
    backgroundColor: "#2643D6",
  });

  return (
    <>
      <Modal trigger={<Button label={label} sx={[rootStyles]} />} header={label} actions={() => []}>
        <Box sx={[{my: 2}]}>
          <FileUpload
            allowedFileTypes={allowedFileTypes}
            allowedFileExtensions={allowedFileExtensions}
            handleUpload={handleFileSelect}
            maxSize={102400}
            outputFormat={FileOutputFormat.Text}
            api={api}
            entity={label === "Upload Document" ? "document" : ""}
            categoryDropdownOptions={categoryDropdownOptions}
          />
        </Box>
      </Modal>
    </>
  );
};
