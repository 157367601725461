import { Schedule as ClockIcon } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import { FormHelperText, InputAdornment, TextField } from "@mui/material";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTime } from "luxon";
import { toTimeFormat } from "../../../helpers/date-helpers";
import { usePalette } from "../../../hooks/general/usePalette";
import { appStrings as strings } from "../../../resources/strings/app";
import { ChangeHandler, TimePicker as TTimePicker } from "../types/Modify";

interface Props<D extends object> {
  config: Omit<TTimePicker<D>, "controltype">;
  handleChange: ChangeHandler;
}

const inputStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.light,
});

const borderStyles: SxProps<Theme> = {
  borderRadius: 0,
};

export const TimePicker = <D extends object>({
  config,
  handleChange,
}: Props<D>) => {
  const palette = usePalette();

  const {
    name,
    label,
    required,
    disabled,
    value,
    error,
    helperText,
    outputFormatter = toTimeFormat,
  } = config;
  const timeValue =
    typeof value === "string" && value ? DateTime.fromISO(value) : null;

  return (
    <>
      <MuiTimePicker
        renderInput={(props) => (
          <TextField
            {...props}
            variant="outlined"
            size="small"
            placeholder={strings.labels.defaultSelect}
            required={required}
            sx={[inputStyles]}
            InputLabelProps={{ htmlFor: name }}
          />
        )}
        value={timeValue}
        disabled={disabled}
        InputProps={{
          title: name,
          id: name,
          sx: [borderStyles],
          endAdornment: (
            <InputAdornment position="end">
              <ClockIcon sx={[palette.darkgrey]} />
            </InputAdornment>
          ),
        }}
        onChange={(time) => {
          if (!time) return;
          const timeString = outputFormatter(time);
          handleChange({
            target: {
              name,
              value: timeString,
            },
          });
        }}
        label={label}
      />
      <FormHelperText error={error} children={helperText} />
    </>
  );
};
