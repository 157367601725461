import * as Papa from "papaparse";
import { logger } from "./log-helpers";
import { deepClone } from "./object-helpers";

export type FileReaderResult = {
  data: string | ArrayBuffer | null;
  name: string;
  mimeType: string;
};

export type ColumnDefinition<D extends object> = {
  field: keyof D;
  transformer?: (value: unknown) => unknown;
};

export enum FileOutputFormat {
  DataURL,
  Text,
}

export const readFile = async (
  file: File,
  outputFormat: FileOutputFormat = FileOutputFormat.DataURL
) =>
  new Promise<FileReaderResult>((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (!e.target) throw new Error("No file data");
        const readResult = e.target.result;
        resolve({
          data: readResult,
          name: file.name,
          mimeType: file.type,
        });
      };

      switch (outputFormat) {
        case FileOutputFormat.DataURL:
          reader.readAsDataURL(file);
          break;
        case FileOutputFormat.Text:
          reader.readAsText(file);
          break;
        default:
          break;
      }
    } catch (e) {
      logger.error(e);
      reject(e);
    }
  });

export const escapeBase64 = (data: FileReaderResult["data"]) => {
  if (typeof data !== "string") return data;
  const base64 = "base64,";
  const [, escapedData] = data.split(base64);
  return escapedData;
};

export const parseCsvRows = async <D extends Record<string, unknown>>(
  columnDefinitions: ColumnDefinition<D>[],
  initialDataRow: D,
  csvText: string
): Promise<D[]> => {
  // headers will be in the first row, can ignore those
  const [, ...rows] = await parseCsv(csvText);
  const mappedRows: D[] = [];
  rows.forEach((rowValues) => {
    // take a clone of the initial data row to avoid mutation for any subsequent data rows
    let dataRow = deepClone(initialDataRow);
    let validValueCount = 0;
    rowValues.forEach((value, columnIndex) => {
      const definition = columnDefinitions[columnIndex];
      // if the column has no matching definition, cant do anything with the value
      if (!definition) return;
      const { field, transformer = (v) => v } = definition;
      // update the data row value with the current value if not falsy
      // note: this is used instead of `!value` to allow 0 and false
      if (value !== "" && value !== null && value !== undefined) {
        validValueCount++;
        dataRow = {
          ...dataRow,
          // apply transformer to value
          [field]: transformer(value),
        };
      }
    });
    // only save the data row if it has at least one valid value
    if (validValueCount) {
      mappedRows.push(dataRow);
    }
  });
  return mappedRows;
};

export const parseCsv = async (csvText: string) => {
  const result: Array<unknown[]> = [];
  Papa.parse(csvText, {
    header: false,
    complete: ({ data }) => {
      const parsedData: any[] = data;
      result.push(...parsedData);
    },
    skipEmptyLines: true,
    dynamicTyping: true,
  });
  return Promise.resolve(result);
};
