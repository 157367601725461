import { useEffect, useState } from "react";
import { logger } from "../../helpers/log-helpers";
import type { DropdownOptions } from "../../types";

export const useDropdownOptions = (
	input: DropdownOptions | (() => Promise<DropdownOptions>)
) => {
	const [options, setOptions] = useState<DropdownOptions>(() =>
		Array.isArray(input) ? input : []
	);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		let mounted = true;
		if (typeof input === "function") {
			if (mounted) setLoading(true);
			input()
				.then((result) => {
					if (mounted) {
						setOptions(result);
						setLoading(false);
					}
				})
				.catch((error) => {
					const message = logger.error(error);
					if (mounted) {
						setLoading(false);
					}
				});
		}
		return () => {
			mounted = false;
		};
	}, [input]);
	return [options, loading] as const;
};
