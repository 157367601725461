import {Box} from "@mui/material";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {CSVExportButton} from "../../../components/general/CSVExportButton";
import {TaskStatusColors} from "../../../components/task/taskConfig";
import {useClientContext} from "../../../context/ClientContext";
import {getOrCreateLegendList} from "../../../helpers/control-helpers";
import {getIntMonths, getMonthEndDate, getMonthLabels, getMonthStartDate, getYears} from "../../../helpers/date-helpers";
import {logger} from "../../../helpers/log-helpers";
import {useToken} from "../../../hooks/authentication/useToken";
import {useCancelToken} from "../../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../../hooks/general/useSessionStorage";
import {api} from "../../../services/tasks.service";
import {RouteProps} from "../../../types";
import {TaskSchedule} from "../../../types/documents/TaskSchedule";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    htmlLegend: {
      containerID: "legend-container",
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        stepSize: 1,
      },
    },
  },
};

interface Props extends RouteProps {
  locationId: string;
  locationName: string;
}
type Component = (props: Props) => JSX.Element;

const TaskSchedulePane: Component = ({...routeProps}) => {
  const jwt = useToken();
  const cancelToken = useCancelToken();
  const {clientId, setClientId} = useClientContext();
  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");

  const [locationId, setLocationId] = useSessionStorage<string>(routeProps.locationId, "locationId");
  const [locationName, setLocationName] = useSessionStorage<string>(routeProps.locationName, "locationName");

  const Id = locationId;
  const [completedOnTime, setCompletedOnTime] = useState<(number | undefined)[]>([]);
  const [completedLate, setCompletedLate] = useState<(number | undefined)[]>([]);
  const [completedEarly, setCompletedEarly] = useState<(number | undefined)[]>([]);
  const [missed, setMissed] = useState<(number | undefined)[]>([]);
  const [future, setFuture] = useState<(number | undefined)[]>([]);
  const [types, setTypes] = useState<Array<string>>(["install", "replace", "inspect", "remove", "clean"]);

  const startDate = getMonthStartDate(-2);
  const endDate = getMonthEndDate(2);
  const labels = getMonthLabels(startDate, 5);
  const months = getIntMonths(startDate, 5);

  const handleClick = () => {
    var installCheckBox: HTMLInputElement = document.getElementById("install") as HTMLInputElement;
    var replaceCheckBox = document.getElementById("replace") as HTMLInputElement;
    var inspectCheckBox = document.getElementById("inspect") as HTMLInputElement;
    var removeCheckBox = document.getElementById("remove") as HTMLInputElement;
    var cleanCheckBox = document.getElementById("clean") as HTMLInputElement;

    const checkboxSelections = [];
    if (installCheckBox.checked) checkboxSelections.push("install");
    if (replaceCheckBox.checked) checkboxSelections.push("replace");
    if (inspectCheckBox.checked) checkboxSelections.push("inspect");
    if (removeCheckBox.checked) checkboxSelections.push("remove");
    if (cleanCheckBox.checked) checkboxSelections.push("clean");
    setTypes(checkboxSelections);
  };

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart: any, args: any, options: any) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: any) => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.marginLeft = "10px";
        li.style.marginBottom = "8px";

        li.onclick = () => {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          chart.update();
        };

        // Colored box
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "20px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = "0";
        textContainer.style.padding = "0";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";
        textContainer.style.fontSize = "14px";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  const addEmptyMonthsToDataArray = (items: TaskSchedule[], startDate: string, numberOfMonths: number) => {
    const allMonths = getIntMonths(startDate, numberOfMonths);
    const allYears = getYears(startDate, numberOfMonths);

    const data: TaskSchedule[] = [];

    for (let i = 0; i < numberOfMonths; i++) {
      let monthExists = false;
      for (let j = 0; j < items.length; j++) {
        if (items[j].Month === allMonths[i] && items[j].Year === allYears[i]) {
          data.push(items[j]);

          monthExists = true;
        }
      }
      if (!monthExists) {
        data.push({
          CompletedOnTime: 0,
          CompletedEarly: 0,
          CompletedLate: 0,
          Missed: 0,
          Future: 0,
        });
      }
    }
    return data;
  };

  const getData = async () => {
    try {
      if (jwt !== null && jwt !== undefined && jwt !== "") {
        const data = await api
          .getTaskSchedule(
            {
              query: {
                location: Id === "NSL" ? "" : Id,
                start: startDate,
                end: endDate,
                types: types.join(","),
              },
            },
            jwt,
            cancelToken
          )
          .then((tasks) => {
            const fullData = addEmptyMonthsToDataArray(tasks.items, startDate, 6);

            setCompletedOnTime(fullData.map((x) => x.CompletedOnTime));
            setCompletedLate(fullData.map((x) => x.CompletedLate));
            setCompletedEarly(fullData.map((x) => x.CompletedEarly));
            setMissed(fullData.map((x) => x.Missed));
            setFuture(fullData.map((x) => x.Future));
          });
      }
    } catch (e) {
      if (cancelToken.reason) return;
      const error = logger.error(e);
    }
  };

  useEffect(() => {
    getData();
  }, [jwt, types, clientId]);

  const data = {
    labels,
    datasets: [
      {
        label: "Completed On Time",
        data: completedOnTime,
        backgroundColor: TaskStatusColors.CompletedOnTime,
      },
      {
        label: "Completed Late",
        data: completedLate,
        backgroundColor: TaskStatusColors.CompletedLate,
      },
      {
        label: "Completed Early",
        data: completedEarly,
        backgroundColor: TaskStatusColors.CompletedEarly,
      },
      {
        label: "Missed",
        data: missed,
        backgroundColor: TaskStatusColors.Missed,
      },
      {
        label: "Future",
        data: future,
        backgroundColor: TaskStatusColors.Future,
      },
    ],
  };

  return (
    <>
      <Box width="100%" display="inline-flex" style={{padding: "0px 0px 0px 12px"}}>
        <Box display="flex" width="66%" height="390px">
          <Bar options={options} data={data} plugins={[htmlLegendPlugin]} />
        </Box>
        <Box width="34%" height="370px" style={{padding: "30px 0px 0px 6px"}}>
          <Box width="100%">
            <div id="legend-container"></div>
          </Box>
          <Box width="100%" style={{padding: "0px 0px 0px 10px"}}>
            <div>
              <input type="checkbox" onClick={handleClick} defaultChecked={true} id={"install"} className="dashboard-checkbox" />
              <label className="dashboard-checkbox-label">Install</label>
              <br></br>
              <input type="checkbox" onClick={handleClick} defaultChecked={true} id={"replace"} className="dashboard-checkbox" />
              <label className="dashboard-checkbox-label">Replace</label>
              <br></br>
              <input type="checkbox" onClick={handleClick} defaultChecked={true} id={"inspect"} className="dashboard-checkbox" />
              <label className="dashboard-checkbox-label">Inspect</label>
              <br></br>
              <input type="checkbox" onClick={handleClick} defaultChecked={true} id={"remove"} className="dashboard-checkbox" />
              <label className="dashboard-checkbox-label">Remove</label>
              <br></br>
              <input type="checkbox" onClick={handleClick} defaultChecked={true} id={"clean"} className="dashboard-checkbox" />
              <label className="dashboard-checkbox-label">Clean</label>
            </div>
          </Box>
        </Box>
      </Box>
      <div style={{float: "right", padding: "10px 15px 8px 0px"}}>
        <CSVExportButton
          config={{
            label: "Download CSV",
            downloadFunction: api.downloadTaskSchedule,
            location: Id === "NSL" || Id === undefined ? "" : Id,
            filenamePrefix: "TaskScheduleData",
            start: startDate,
            end: endDate,
            types: types.join(","),
          }}
        />
      </div>
    </>
  );
};

export default TaskSchedulePane;
