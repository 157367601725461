import type {Validator} from "./Validator";

export type B2cPasswordOptions = {
  active: boolean;
};

const doesMeetLengthConstraint = (input: string) => {
  return input.length >= 8 && input.length <= 64;
};

const doesContainUpperCase = (input: string) => {
  return /[A-Z]/.test(input);
};

const doesContainLowerCase = (input: string) => {
  return /[a-z]/.test(input);
};

const doesContainNumber = (input: string) => {
  return /\d/.test(input);
};

const doesContainSymbol = (input: string) => {
  return /[-!$£%^&@#*()_+|~=`{}\[\]:";'<>?,.\/]/.test(input);
};

export const b2cpassword: Validator<B2cPasswordOptions> = (value, {active}, _key, _attributes) => {
  const errors: string[] = [];

  let lowerCaseValid = doesContainLowerCase(value);
  if (!lowerCaseValid) errors.push("lowercase letters");

  let upperCaseValid = doesContainUpperCase(value);
  if (!upperCaseValid) errors.push("uppercase letters");

  let numberValid = doesContainNumber(value);
  if (!numberValid) errors.push("numbers");

  let symbolValid = doesContainSymbol(value);
  if (!symbolValid) errors.push("symbols");

  if (errors.length > 1) {
    const groupCount = errors.length == 2 ? "group" : `${errors.length - 1} groups`;
    return `does not meet complexity constraints, and should contain characters from a further ${groupCount}: ${errors.join(", ")}`;
  }
  if (!doesMeetLengthConstraint(value)) {
    return `does not meet length constraints, must be between 8-64 characters`;
  }
  return undefined;
};
