const hasError = (object: object): object is { error: unknown } =>
  "error" in object;

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  if (error instanceof Object && hasError(error)) return String(error.error);
  return String(error);
};

const error = (error: unknown, logText = "ERROR") => {
  const message = getErrorMessage(error);
  console.log(logText, message);
  return message;
};

export const logger = {
  error,
};
