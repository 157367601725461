import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { styled, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { loginRequest } from "../authConfig";
import { useRole } from "../hooks/authentication/useRole";
import { useToken } from "../hooks/authentication/useToken";
import { userStrings as strings } from "../resources/strings/users";
import { ClientSelector } from "./general/controls/ClientSelector";
import { Link } from "./general/controls/Link";
import { menuItems } from "./layout/listItems";
import { MenuComponent } from "./layout/MenuComponent";

export interface Props {
  children: JSX.Element;
}
type Component = (props: Props) => JSX.Element;

const Span = styled("span")({});

export const NavigationBar: Component = ({ children }) => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((error) => console.log(error));
  };

  const linkStyles: SxProps<Theme> = (theme) => ({
    //textColor: "white",
  });

  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */

  interface Permissions {
    [key: string]: string[];
  }

  let permissions: Permissions = {};

  const role = useRole();
  if (role === "superadmin") {
    permissions = {
      Clients: ["ViewList"],
      Users: ["ViewList"],
    };
  } else if (role === "admin") {
    permissions = {
      Users: ["ViewList"],
    };
  }

  //const { permissions } = useUserPermissions();
  // dont want to trigger the fetch before the user is actually logged in

  const jwt = useToken();

  const additionalContent = (
    <>
      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          flexGrow: 1,
          textAlign: "right",
          marginRight: "1rem",
          color: "#222D54",
        }}
      >
        T-Safe Asset Management
      </Typography>
      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          marginRight: "1rem",
          color: "#222D54",
        }}
      >
        |
      </Typography>
      {role === "superadmin" && <ClientSelector jwt={jwt} />}
      {role === "superadmin" && (
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            marginRight: "1rem",
            color: "#222D54",
          }}
        >
          |
        </Typography>
      )}
      <AuthenticatedTemplate>
        <div className="ml-auto">
          <Span sx={[linkStyles]}>
            <Link
              config={{
                text: strings.labels.signout,
                variant: "body2",
                onClick: () =>
                  instance.logoutRedirect({ postLogoutRedirectUri: "/" }),
              }}
            />
          </Span>
        </div>
      </AuthenticatedTemplate>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MenuComponent
        menuItems={menuItems}
        permissions={permissions}
        additionalContent={additionalContent}
      >
        {children}
      </MenuComponent>
    </Box>
  );
};
// function useUserPermissions(): { permissions: any } {
//   return { permissions: "permission" };
// }
