import {Box} from "@mui/material";
import {useMemo, useState} from "react";
import {CSVExportButton} from "../../../components/general/CSVExportButton";
import {WorkStatusButtons} from "../../../components/workStatus/WorkStatusButtons";
import {useCancelToken} from "../../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../../hooks/general/useSessionStorage";
import {workStatusStrings} from "../../../resources/strings/workStatus";
import {api as tasksApi} from "../../../services/tasks.service";
import {api as workStatusAPI} from "../../../services/workStatus.service";
import {RouteProps} from "../../../types";
import {WorkStatus, WorkStatusFilters} from "../../../types/documents/WorkStatus";
import {TableContainer} from "../../general/TableContainer";

interface Props extends RouteProps {
  locationId: string;
  locationName: string;
}
type Component = (props: Props) => JSX.Element;

const WorkStatusPane: Component = ({...routeProps}) => {
  const defaultNumberOfDays = "7";
  const [numberOfDays, setNumberOfDays] = useState(defaultNumberOfDays);
  const [filters, setFilters] = useState<WorkStatusFilters>({
    days: numberOfDays,
    location: "",
  });

  const [locationId, setLocationId] = useSessionStorage<string>(routeProps.locationId, "locationId");
  const [locationName, setLocationName] = useSessionStorage<string>(routeProps.locationName, "locationName");

  const handleDaysChange = (value: number | undefined) => {
    if (value !== undefined) {
      const nonZeroValue = value === 0 ? "7" : value;
      setNumberOfDays(`${nonZeroValue}`);
      setFilters({days: `${nonZeroValue}`});
    }
  };

  const clearFilters = () => setFilters({days: "7"});
  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const cancelToken = useCancelToken();
  const Id = locationId;

  return (
    <Box position="relative" height="100%">
      <>
        <div>
          <TableContainer<WorkStatus>
            {...routeProps}
            api={workStatusAPI}
            tableColumns={[
              {
                id: "task",
                Header: workStatusStrings.labels.task,
                accessor: "TaskType",
              },
              {
                id: "overdue",
                Header: workStatusStrings.labels.overdue,
                accessor: "OverdueCount",
              },
              {
                id: "dueNextNDays",
                Header: `Due Next ${numberOfDays} Days`,
                accessor: "DueCount",
              },
            ]}
            header={workStatusStrings.header.workStatus}
            headerContent={({totalCount, page, size}) => <WorkStatusButtons numberOfDays={numberOfDays} handleNumberOfDaysChange={handleDaysChange} />}
            filters={Id === "NSL" ? ({days: numberOfDays} as any) : ({Location: Id, days: numberOfDays} as any)}
            hideHeaderColor={true}
            headerSpacing={1.3}
            scrollMaxBodyHeight={215}
          ></TableContainer>
          <br></br>
        </div>

        <div style={{float: "right", padding: "0px 15px 10px 0px"}}>
          <CSVExportButton
            config={{
              label: "Download CSV",
              downloadFunction: tasksApi.downloadWorkStatus,
              location: Id === "NSL" || Id === undefined ? "" : Id,
              filenamePrefix: `WorkStatusData_Next${numberOfDays}Days`,
              days: parseInt(numberOfDays),
            }}
          />
        </div>
      </>
    </Box>
  );
};

export default WorkStatusPane;
