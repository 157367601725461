export const userStrings = {
  header: {
    userList: "Users",
    createUser: "Create User",
    editUser: "Edit User",
    userDetails: "User Details",
    deleteUser: "Delete User",
    logIn: "Log In",
    passwordReset: "Password Reset",
    deleteItem: "Delete User",
  },
  labels: {
    id: "ID",
    create: "Create",
    firstName: "First Name",
    lastName: "Last Name",
    createUser: "Create User",
    editUser: "Edit User",
    reset: "Reset",
    search: "Search",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    password: "Password",
    confirm: "Confirm",
    newUser: "New User",
    client: "Client",
    email: "Email",
    role: "Role",
    logIn: "Log In",
    passwordReset: "Password Reset",
    newPassword: "New Password",
    repeatPassword: "Repeat Password",
    resetPassword: "Reset Password",
    signout: "Sign Out",
    signin: "Sign In",
    update: "Update",
    clientId: "Client ID",
  },
  placeholders: {
    id: "ID",
    name: "Name",
  },
  text: {
    copyUser: "Are you sure you would like to create a new User?",
    deleteUser: "Are you sure you want to delete this item?",
    deleteConfirmation:
      "Are you sure you want to delete this user? This cannot be undone.",
  },
};
