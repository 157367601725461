export type User = {
  Id: string;
  FirstName: string;
  LastName: string;
  DisplayName?: string;
  ClientId: string;
  Client: string;
  Email: string;
  Role: string;
  Password?: string;
  UserClientId?: string;
};

export type UserFilters = Partial<{
  Search: string;
}>;

export enum UserSort {
  FirstName = "Firstname",
  LastName = "Lastname",
  ClientId = "ClientId",
  Client = "Client",
  Email = "Email",
  Role = "Role",
}
