import {
  ArticleOutlined,
  BusinessOutlined,
  DownloadOutlined,
  FilterAltOutlined,
  HouseOutlined,
  LocationOnOutlined,
  PersonOutlined,
  ShowerOutlined,
  TaskOutlined,
  Hiking
} from "@mui/icons-material";
import type {SvgIconTypeMap} from "@mui/material";
import type {OverridableComponent} from "@mui/material/OverridableComponent";
import {paths} from "../../navigation/paths";
import {appStrings as strings} from "../../resources/strings/app";
import {UserAccess} from "../../types";

type IconType = OverridableComponent<SvgIconTypeMap<{}, "svg">> | (() => JSX.Element);

const {entities, permissions, menuLabels} = strings;

interface BaseMenuItem {
  key: string;
  label: string;
  Icon?: IconType;
  itemPermission?: UserAccess;
}

interface MenuItem extends BaseMenuItem {
  type: "standard";
  link: string;
}

interface MenuItemWithChildren extends BaseMenuItem {
  type: "parent";
  childItems: MenuItem[];
}

export type MenuItemType = MenuItem | MenuItemWithChildren;
export type MenuItemsType = Array<MenuItemType>;

export const menuItems: MenuItemsType = [
  {
    key: "home",
    type: "standard",
    label: menuLabels.home,
    Icon: HouseOutlined,
    link: paths.home.list,
  },
  {
    key: "clients",
    type: "standard",
    label: menuLabels.clients,
    Icon: BusinessOutlined,
    link: paths.client.list,
    itemPermission: {
      entity: "Clients",
      permission: ["ViewList"],
    },
  },
  {
    key: "users",
    type: "standard",
    label: menuLabels.users,
    Icon: PersonOutlined,
    link: paths.users.list,
    itemPermission: {
      entity: "Users",
      permission: ["ViewList"],
    },
  },
  {
    key: "locations",
    type: "standard",
    label: menuLabels.locations,
    Icon: LocationOnOutlined,
    link: paths.locations.list,
  },
  {
    key: "tasks",
    type: "standard",
    label: menuLabels.tasks,
    Icon: TaskOutlined,
    link: paths.tasks.list,
  },
  {
    key: "assets",
    type: "standard",
    label: menuLabels.assets,
    Icon: ShowerOutlined,
    link: paths.assets.list,
  },
  {
    key: "filterTypes",
    type: "standard",
    label: menuLabels.filterTypes,
    Icon: FilterAltOutlined,
    link: paths.filterTypes.list,
  },
  {
    key: "auditTrail",
    type: "standard",
    label: menuLabels.auditTrail,
    Icon: Hiking,
    link: paths.auditTrail.list,
  },
  {
    key: "import",
    type: "standard",
    label: menuLabels.import,
    Icon: DownloadOutlined,
    link: paths.import.list,
  },
  {
    key: "documents",
    type: "standard",
    label: menuLabels.documents,
    Icon: ArticleOutlined,
    link: paths.documents.list,
  },
];
