export const userAlertStrings = {
  header: {
    userAlerts: "User Alerts",
  },
  labels: {
    userAlert: "User Alert",
    count: "Count",
  },
  placeholders: {
    alert: "Alert",
    count: "Count",
  },
  text: {},
};
