import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {Column} from "react-table";
import {CreateButton} from "../../components/common/CreateButton";
import {SearchBar} from "../../components/common/SearchBar";
import {useRole} from "../../hooks/authentication/useRole";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import paths from "../../navigation/paths";
import {userStrings as strings} from "../../resources/strings/users";
import {api} from "../../services/users.service";
import {FilterChangeHandler, RouteProps} from "../../types";
import {User, UserFilters, UserSort} from "../../types/documents/User";
import {TableContainer} from "../general/TableContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const UsersList: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<UserFilters>({}, "userFilters");

  const userPermissions = routeProps.permissions;

  const history = useNavigate();

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const cancelToken = useCancelToken();

  const tableCols: Column<User>[] | ((props: {setTableData: React.Dispatch<React.SetStateAction<User[]>>}) => Column<User>[]) = [
    {
      id: UserSort.FirstName,
      Header: strings.labels.firstName,
      accessor: "FirstName",
    },
    {
      id: UserSort.LastName,
      Header: strings.labels.lastName,
      accessor: "LastName",
    },
    {
      id: UserSort.Email,
      Header: strings.labels.email,
      accessor: "Email",
    },
    {
      id: UserSort.Role,
      Header: strings.labels.role,
      accessor: "Role",
    },
    {
      id: UserSort.Client,
      Header: strings.labels.client,
      accessor: "Client",
    },
  ];

  const tableColsSA: Column<User>[] | ((props: {setTableData: React.Dispatch<React.SetStateAction<User[]>>}) => Column<User>[]) = [
    {
      id: UserSort.FirstName,
      Header: strings.labels.firstName,
      accessor: "FirstName",
    },
    {
      id: UserSort.LastName,
      Header: strings.labels.lastName,
      accessor: "LastName",
    },
    {
      id: UserSort.Email,
      Header: strings.labels.email,
      accessor: "Email",
    },
    {
      id: UserSort.Role,
      Header: strings.labels.role,
      accessor: "Role",
    },
  ];

  const role = useRole();
  const isSuperAdmin: boolean = role === "superadmin";

  return (
    <div>
      <TableContainer<User>
        {...routeProps}
        api={api}
        tableColumns={isSuperAdmin ? tableCols : tableColsSA}
        header={strings.header.userList}
        headerContent={() => <CreateButton createPath={paths.users.create} label={strings.labels.createUser} />}
        children={<SearchBar filters={filters} handleFilterChange={handleFilterChange} clearFilters={clearFilters} tags={{}} />}
        manualPagination={true}
        filters={tableFilters as any}
        clickHandler={(user) => history(paths.users.edit(user.Id.toString()))}
      ></TableContainer>
    </div>
  );
};

export default UsersList;
