import {EventNote as CalendarIcon} from "@mui/icons-material";
import type {SxProps, Theme} from "@mui/material";
import {FormHelperText, InputAdornment, TextField} from "@mui/material";
import {DatePicker as MuiDatePicker} from "@mui/x-date-pickers/DatePicker";
import {DateTime} from "luxon";
import {toDateFormat} from "../../../helpers/date-helpers";
import {usePalette} from "../../../hooks/general/usePalette";
import {appStrings as strings} from "../../../resources/strings/app";
import type {ChangeHandler, DatePicker as TDatePicker} from "../types/Modify";

interface Props<D extends object> {
  config: Omit<TDatePicker<D>, "controltype">;
  handleChange: ChangeHandler;
}

const inputStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: "#FFFFFF",
  color: theme.palette.primary.light,
  borderRadius: "5px",
});

const borderStyles: SxProps<Theme> = {
  borderRadius: "5px",
};

export const DatePicker = <D extends object>({config, handleChange}: Props<D>) => {
  const palette = usePalette();
  const {name, label, required, disabled, value, error, helperText} = config;
  const dateValue = typeof value === "string" && value ? DateTime.fromISO(value) : null;

  return (
    <>
      <MuiDatePicker
        renderInput={(props) => (
          <TextField
            {...props}
            variant="outlined"
            size="small"
            error={error}
            placeholder={strings.labels.defaultSelect}
            required={required}
            sx={[inputStyles]}
            InputLabelProps={{htmlFor: name}}
          />
        )}
        value={dateValue}
        disabled={disabled}
        InputProps={{
          title: name,
          id: name,
          sx: [borderStyles],
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon sx={[palette.darkgrey]} />
            </InputAdornment>
          ),
        }}
        onChange={(date) => {
          const dateString = toDateFormat(date);
          handleChange({
            target: {
              name,
              value: dateString,
            },
          });
        }}
        label={label}
      />
      <FormHelperText error={error} children={helperText} />
    </>
  );
};
