export type Client = {
  Id: string;
  Name: string;
  Address1: string;
  Address2: string;
  City: string;
  Region: string;
  Postcode: string;
  Country: string;
  Active: boolean;
};

export type ClientFilters = Partial<{
  Search: string;
}>;

export enum ClientSort {
  Name = "Name",
  Address1 = "Address1",
  Address2 = "Address2",
  City = "City",
  Region = "Region",
  Postcode = "Postcode",
  Country = "Country",
}
