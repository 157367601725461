import {SxProps, Theme, Typography} from "@mui/material";
import {strings as stringsCommon} from "../../resources/strings/common";
import {Button} from "./Button";
import {Modal} from "./Modal";

interface Props {
  header: string;
  confirmationMessage: string;
  handleDelete: any;
  isOpen?: boolean; //Use if no trigger is set, to directly control whether the modal is open or shut
  setIsOpen?: Function; //Use if no trigger is set, to track the state of the modal
  setTrigger?: boolean;
}
type Component = (props: Props) => JSX.Element;

const deleteActionsStyles: SxProps<Theme> = (theme) => ({
  color: "#CC3333",
  backgroundColor: "white",
  "&:hover": {
    color: "#CC3333",
    backgroundColor: "white",
    boxShadow: "none",
  },
  padding: 0,
  margin: 0,
  width: "100px",
  minWidth: "100px",
});

const cancelButtonStyles: SxProps<Theme> = (theme) => ({
  color: "#999999",
  backgroundColor: "white",
  "&:hover": {
    color: "#999999",
    backgroundColor: "white",
    boxShadow: "none",
  },
  padding: 0,
  margin: 0,
  width: "100px",
  minWidth: "100px",
});

const deleteButtonStyles: SxProps<Theme> = (theme) => ({
  color: "#CC3333",
  backgroundColor: "white",
});

export const DeleteModal: Component = ({header, confirmationMessage, handleDelete, isOpen = undefined, setIsOpen = undefined, setTrigger = true}) => {
  const trigger = !setTrigger ? <></> : <Button label={stringsCommon.labels.delete} variant="outlined" sx={[deleteButtonStyles]} />;

  return (
    <Modal
      trigger={trigger}
      header={header}
      actions={({onClose}) => [
        <Button label={stringsCommon.labels.cancel} onClick={onClose} sx={[cancelButtonStyles]} />,
        <Button label={stringsCommon.labels.delete} onClick={handleDelete} sx={[deleteActionsStyles]} />,
      ]}
      md={4}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Typography style={{padding: 0}}>{confirmationMessage}</Typography>
    </Modal>
  );
};
