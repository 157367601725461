import TreeItem, {TreeItemContentProps, TreeItemProps, useTreeItem} from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import paths from "../../navigation/paths";

export function LocationAddNode(props: TreeItemProps) {
  return <TreeItem ContentComponent={CustomContent} {...props} />;
}

export const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
  const history = useNavigate();
  const {classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon} = props;
  const {disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection} = useTreeItem(nodeId);
  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
    if (props.nodeId === "addSite") {
      history(paths.site.create);
    } else {
      history(paths.site.modify(props.nodeId));
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});
