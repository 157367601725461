import type { SxProps, Theme } from "@mui/material";
import { Grid } from "@mui/material";
import { Header } from "./Header";

interface Props {
  children: JSX.Element | JSX.Element[];
  header: string | JSX.Element;
  spacing?: number;
  hideBgColor?: boolean;
}
type Component = (props: Props) => JSX.Element;

export const BreadcrumbHeader: Component = ({
  header,
  children,
  spacing,
  hideBgColor,
}) => {
  const rootStyles: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(
      spacing == null || spacing == undefined ? 2.5 : spacing
    ),
    backgroundColor: hideBgColor ? null : "#FAFAFA",
    borderBottom: "1px solid #E0E0E0",
  });

  const renderHeader = () => {
    if (typeof header !== "string") return header;
    return <Header text={header} />;
  };

  return (
    <Grid
      sx={[rootStyles]}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-testid="breadcrumb"
    >
      <Grid item xs={12} md={6}>
        <Grid container justifyContent={"flex-start"}>
          {renderHeader()}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container justifyContent={"flex-end"}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
