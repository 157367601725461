import {Grid} from "@mui/material";
import {useForm} from "../../hooks/general/useForm";
import {BaseControl} from "../general/controls/BaseControl";
import {Input} from "../general/controls/Input";
import {ChangeHandler} from "../general/types/Modify";

interface SearchBoxProps {
  filters: Pick<Filters, "Search">;
  handleFilterChange: ChangeHandler;
  placeholderText?: string;
}
type SearchBoxComponent = (props: SearchBoxProps) => JSX.Element;
type Filters = {Search?: string};

export const SearchBox: SearchBoxComponent = ({filters, handleFilterChange, placeholderText = "Search"}) => {
  const [currentFilters, handleChange] = useForm<SearchBoxProps["filters"]>(filters);
  const onSubmit: React.FormEventHandler<HTMLFormElement> = () => {
    handleFilterChange({
      target: {
        name: "Search",
        value: currentFilters.Search,
      },
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <BaseControl control={false}>
            <Input
              config={{
                name: "Search",
                placeholder: placeholderText,
                value: currentFilters.Search ?? "",
              }}
              handleChange={handleChange}
              search={true}
            />
          </BaseControl>
        </Grid>
      </Grid>
    </form>
  );
};
