import {AxiosRequestConfig} from "axios";
import axios from "../libs/axios/axios-task";
import {QueryFunction} from "../types/API";
import {WorkStatus, WorkStatusFilters} from "../types/documents/WorkStatus";

const query: QueryFunction<WorkStatus> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const filters = query as WorkStatusFilters;
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const {data} = await axios.get<{item: WorkStatus[]}>("/work-status/summary", config);
  return {
    items: data.item,
    totalCount: data.item.length,
  };
};

export const api = {
  query,
};
