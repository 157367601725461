import {add, format} from "date-fns";
import type {DateTimeFormatOptions, LocaleOptions} from "luxon";
import {DateTime} from "luxon";

const dateOptions: LocaleOptions & DateTimeFormatOptions = {
  locale: "en-gb",
  dateStyle: "short",
};

const timeOptions: LocaleOptions & DateTimeFormatOptions = {
  timeStyle: "short",
};

export const formatDateTime = (dateString: string) => {
  const formattedString = DateTime.fromISO(dateString).toLocaleString({
    ...dateOptions,
    ...timeOptions,
  });
  return formattedString;
};

export const formatDate = (inputDate: string | DateTime) => {
  const date = typeof inputDate === "string" ? DateTime.fromISO(inputDate) : inputDate;
  const formattedString = date.toLocaleString({
    ...dateOptions,
  });
  return formattedString;
};

export const formatTime = (inputDate: string | DateTime) => {
  const date = typeof inputDate === "string" ? DateTime.fromISO(inputDate) : inputDate;
  const formattedString = date.toLocaleString({
    ...timeOptions,
  });
  return formattedString;
};

export const toDateFormat = (date: DateTime | null) =>
  date?.toISO({
    suppressMilliseconds: true,
  }) || "";

export const getDateOnlyStringFromDateTimeString = (dateString: string) => {
  const splitComponents = dateString.split("T");
  if (splitComponents.length > 0) {
    return splitComponents[0];
  } else {
    return "";
  }
};

export const toTimeFormat = (date: DateTime | null) => date?.toISOTime({suppressSeconds: true, includeOffset: false}) || "";

export const getTimestamp = (date: string) => DateTime.fromISO(date);

export const getMonthStartDate = (monthsFromToday: number) => {
  const current = new Date();
  const firstOfCurrentMonth = new Date(current.getUTCFullYear(), current.getUTCMonth(), 1);
  const monthStartDate = add(firstOfCurrentMonth, {months: monthsFromToday});
  return format(monthStartDate, "yyyy-MM-dd");
};

export const getMonthEndDate = (numberOfMonthsInFuture: number) => {
  const current = new Date();
  const monthAfterEndDate = add(current, {months: numberOfMonthsInFuture + 1});
  const endOfExpectedMonth = add(new Date(monthAfterEndDate.getUTCFullYear(), monthAfterEndDate.getUTCMonth(), 1), {days: -1});
  return format(endOfExpectedMonth, "yyyy-MM-dd");
};

export const getMonthLabels = (startDate: string, numberOfMonths: number) => {
  let labels = [];
  const monthNameFormatter = new Intl.DateTimeFormat("en-us", {
    month: "short",
  });
  for (let i = 0; i < numberOfMonths; i++) {
    labels.push(monthNameFormatter.format(add(new Date(startDate), {months: i})));
  }
  return labels;
};

//Gets integer array of months starting from startDate month and ending (numberOfMonths) months in the future
export const getIntMonths = (startDate: string, numberOfMonths: number) => {
  let months = [];
  for (let i = 1; i <= numberOfMonths; i++) {
    const month = add(+DateTime.fromISO(startDate), {
      months: i,
    }).getMonth();
    months.push(month === 0 ? 12 : month);
  }
  return months;
};

export const getYears = (startDate: string, numberOfMonths: number) => {
  const allYears = [];
  for (let i = 0; i < numberOfMonths; i++) {
    allYears.push(add(+DateTime.fromISO(startDate), {months: i}).getFullYear());
  }
  return allYears;
};
