export const clientStrings = {
  header: {
    itemList: "Clients",
    itemDetails: "Client Details",
    createItem: "Create Client",
    modifyItem: "Edit Client",
    deleteItem: "Delete Client",
  },
  labels: {
    id: "ID",
    name: "Name",
    address1: "Address 1",
    address2: "Address 2",
    city: "City",
    region: "Region",
    postcode: "Postcode",
    country: "Country",
    active: "Active",
    search: "Search",
    reset: "Reset",
    edit: "Edit",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    select: "Select",
    save: "Save",
    create: "Create",
  },
  placeholders: {
    searchTerm: "Search",
  },
  text: {
    deleteConfirmation: "Are you sure you want to delete this client? This cannot be undone.",
    deleteSuccess: "Client successfully deleted",
    customReplacementPeriodOutOfRange: "Custom replacement period cannot be greater than default replacement period, or less than or equal to zero.",
    customReplacementPeriodError: "An error occurred while setting the custom replacement period.",
    customReplacementPeriodSuccess: "Custom replacement period successfully set.",
  },
};
