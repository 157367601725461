import Add from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeView from "@mui/lab/TreeView";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {CancelToken} from "axios";
import {useEffect, useState} from "react";
import {Loader} from "../../components/general/Loader";
import {Mode} from "../../components/general/types/Modify";
import {LocationBreadcrumbHeader} from "../../components/location/LocationBreadcrumbHeader";
import {useClientContext} from "../../context/ClientContext";
import {useRole} from "../../hooks/authentication/useRole";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {api} from "../../services/locations.service";
import {FilterChangeHandler, RouteProps} from "../../types";
import {Location, LocationFilters} from "../../types/documents/Location";
import {LocationAddNode} from "./LocationAddNode";
import SiteSelect from "./sites/SiteSelect";
import { SearchBar } from "../../components/common/SearchBar";
import { useSessionStorage } from "../../hooks/general/useSessionStorage";
import { LocationSelectBox } from "../../components/general/controls/LocationSelectBox";
import { useNavigate } from "react-router";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

export default function LocationsList({mode, setSelectedLocation, listPage}: {mode: Mode; setSelectedLocation: Function | undefined; listPage: boolean}) {
  const jwt = useToken();
  const role = useRole();
  const cancelToken = useCancelToken();
  const {clientId} = useClientContext();
  const [sites, setSites] = useState<Location[]>([]);
  const [buildings, setBuildings] = useState<Location[]>([]);
  const [loading, setLoading] = useState(() => false);

  const [filters, setFilters] = useSessionStorage<LocationFilters>({}, "locationFilters");

  const [locationID, setLocationID] = useState<string | null>(null);

  const history = useNavigate();

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  type LocationDetails = {
    selectedLocation: {
      LocationId: string
    }
  }

  const locationFilterChangeHandler = (event: any, locationDetails: LocationDetails) => {
    console.log(locationDetails)
    setLocationID(locationDetails.selectedLocation.LocationId);
  }

  useEffect(() => {
    async function getSites(jwt: string, cancelToken: CancelToken) {
      setLoading(true);
      let siteList = [];
      let buildingsList = [];
      if (locationID !== null && locationID !== undefined && locationID !== "") {
        console.log("Getting locations for parent: " + locationID);
        siteList = await api.getLocationsForParent(locationID, jwt, cancelToken);
        let topDepth = siteList[0].Depth;
        buildingsList = siteList.filter((site: any) => site.Depth === topDepth + 1 && site.ParentId !== null);
        siteList = siteList.filter((site: any) => site.Depth === topDepth);
        
      } else {
        console.log("Getting all locations");
        siteList = await api.getLocations("", jwt, cancelToken);
      }
      console.log("BUILDINGS LIST: ", buildingsList);
      console.log("SITE LIST: ", siteList);
      setBuildings(buildingsList);
      setSites(siteList);
      setLoading(false);
    }

    //Refreshes the site list if the client is changed
    const currentUserHasClient = (role === "superadmin" && clientId !== undefined && clientId !== null) || role !== "superadmin";
    if (jwt && currentUserHasClient) {
      getSites(jwt, cancelToken);
    }
  }, [jwt, cancelToken, role, clientId, locationID]);



  const SelectClientMessage = (
    <Paper>
      <Typography
        sx={{
          textAlign: "center",
          marginTop: 10,
          border: 0,
        }}
      >
        Select a client to view the locations
      </Typography>
    </Paper>
  );

  const treeElementFormat = {
    height: mode === "view" ? "280px" : 1200,
    flexGrow: 1,
    maxWidth: 4000,
    overflowY: "auto",
  };

  return (
    <>
      {mode !== "view" && <LocationBreadcrumbHeader />}
      {listPage === true ? <LocationSelectBox config={{ name: "locationId", value: filters.Search }} 
              mode={"view"} 
              handleChange={locationFilterChangeHandler}  /> : <></>}
      {clientId === null ? (
        //null means no client is selected, but undefined means not yet loaded (so we don't display the message then)
        SelectClientMessage
      ) : (
        <TreeView
          aria-label="icon expansion"
          defaultCollapseIcon={<ExpandMoreIcon sx={{color: "blue"}} />}
          defaultExpandIcon={<ChevronRightIcon sx={{color: "blue"}} />}
          defaultEndIcon={<ChevronRightIcon sx={{color: "blue"}} />}
          sx={treeElementFormat}
        >
          {sites.map((site) => (
            <SiteSelect
              mode={mode}
              setSelectedLocation={setSelectedLocation}
              nodeId={(site.Id ? site.Id : "")}
              depth={site.Depth ? site.Depth + 1 : 1}
              label={site.Name}
              parentId={null}
              permissions={null}
              user={null}
              buildings={buildings}
            ></SiteSelect>
          ))}
          <LocationAddNode
            nodeId="addSite"
            label={<Typography sx={{textDecoration: "underline"}}>Add Site</Typography>}
            sx={{color: "blue"}}
            icon={<Add sx={{color: "blue"}} />}
          ></LocationAddNode>
        </TreeView>
      )}
      <Loader active={loading}></Loader>
    </>
  );
}
