import { SxProps, Theme } from "@mui/material";
import { Button } from "./Button";

interface Props {
  strings: any;
  handleBack: any;
}
type Component = (props: Props) => JSX.Element;

const cancelButtonStyles: SxProps<Theme> = (theme) => ({
  color: "#2643D6",
  backgroundColor: "white",
});

const updateButtonStyles: SxProps<Theme> = (theme) => ({
  color: "white",
  backgroundColor: "#2643D6",
});

export const CreateButtons: Component = ({ strings, handleBack }) => {
  return (
    <>
      <Button
        label={strings.labels.cancel}
        type="reset"
        variant="outlined"
        onClick={handleBack}
        sx={[cancelButtonStyles]}
      />
      <Button
        label={strings.labels.create}
        variant="contained"
        type="submit"
        sx={[updateButtonStyles]}
      />
    </>
  );
};
