import { createDropdownOptions } from "../../../helpers/dropdown-helpers";
import { api } from "../../../services/tags.service";
import type { DropdownOptions } from "../../../types";
import type { TagItem } from "../../../types/documents/Tag";
import { useReadQuery } from "../../general/useReadQuery";

const { read } = api;

const defaultDropdownOptions: DropdownOptions = [];
const defaultTagGroup = "";

export const useTagGroup = (
	defaultOptions: DropdownOptions = defaultDropdownOptions,
	tagGroup = defaultTagGroup
) => {
	const [item, ...queryResponse] = useReadQuery<TagItem>(
		{
			tagGroup: "",
			name: "",
			tags: [],
		},
		read,
		tagGroup
	);

	let options = defaultOptions;
	if (item.tags.length) {
		options = createDropdownOptions(item.tags, "id", "text");
	}

	return [options, ...queryResponse] as const;
};
