import {AxiosRequestConfig, CancelToken} from "axios";
import {detailErrorResponse} from "../helpers/control-helpers";
import axios from "../libs/axios/axios-location";
import {notifications} from "../libs/notifications";
import {CreateFunction, CreateResult, DeleteFunction, ReadFunction, UpdateFunction, UpdateResult} from "../types/API";
import {Location} from "../types/documents/Location";

const getLocations = async (parentId?: string, jwt?: string, cancelToken?: CancelToken) => {
  const path = "";
  const config: AxiosRequestConfig = {
    params: {
      parentId: parentId ? parentId : "",
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data.map((Name: string) => Name);
};

const getLocationsForParent = async (parentId: string, jwt?: string, cancelToken?: CancelToken) => {
  const path = "/forparent/" + parentId;
  const config: AxiosRequestConfig = {
    params: {
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data.map((Name: string) => Name);
};

const read: ReadFunction<Location> = async (id, _childId, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get<Location>(path, config);
  return {
    item: {
      ...data,
    },
  };
};

const create: CreateFunction<Location> = async ({payload}, jwt?, cancelToken?) => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  if (payload.ClientId == null || payload.ClientId === "") {
    payload.ClientId = "00000000-0000-0000-0000-000000000000";
  }

  const {data} = await axios.post<CreateResult>("", payload, config);
  return data;
};

const update: UpdateFunction<Location> = async ({payload}, jwt?, cancelToken?) => {
  const {Id} = payload;
  const path = `/${Id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {Name, ClientId, ParentId} = payload;
  const {data} = await axios.put<UpdateResult>(path, {Name, ClientId, ParentId}, config);
  return data;
};

//Note - delete can't be used as a variable declaration name, so we use deleteLocation
const deleteLocation: DeleteFunction<Location> = async (id, jwt?, cancelToken?) => {
  try {
    const path = `/${id}`;
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: jwt ? jwt : "",
      },
      cancelToken,
    };
    await axios.delete(path, config);
    notifications.success("Location successfully deleted");
  } catch (error) {
    notifications.error(detailErrorResponse(error));
  }
};

export const api = {
  read,
  getLocations,
  create,
  update,
  deleteLocation,
  getLocationsForParent,
};
