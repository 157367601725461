const API_URL = process.env.REACT_APP_API_URL;
const ASSET_API_URL = process.env.REACT_APP_ASSET_API_URL;
const CLIENT_API_URL = process.env.REACT_APP_CLIENT_API_URL;
const DOCUMENT_API_URL = process.env.REACT_APP_DOCUMENT_API_URL;
const FILTER_API_URL = process.env.REACT_APP_FILTER_API_URL;
const IMPORT_API_URL = process.env.REACT_APP_IMPORT_API_URL;
const LOCATION_API_URL = process.env.REACT_APP_LOCATION_API_URL;
const TASK_API_URL = process.env.REACT_APP_TASK_API_URL;
const USER_API_URL = process.env.REACT_APP_USER_API_URL;
const AUDIT_TRAIL_API_URL = process.env.REACT_APP_AUDIT_TRAIL_API_URL;

export const API_ENDPOINT = `${API_URL}`;
export const ASSET_ENDPOINT = `${ASSET_API_URL}/assets`;
export const CLIENT_ENDPOINT = `${CLIENT_API_URL}`;
export const DOCUMENT_ENDPOINT = `${DOCUMENT_API_URL}/documents`;
export const FILTERTYPE_ENDPOINT = `${FILTER_API_URL}/filters`;
export const IMPORT_ENDPOINT = `${IMPORT_API_URL}/import`;
export const LOCATION_ENDPOINT = `${LOCATION_API_URL}/locations`;
export const SITE_ENDPOINT = `${LOCATION_API_URL}/sites`;
export const BUILDING_ENDPOINT = `${LOCATION_API_URL}/buildings`;
export const FLOOR_ENDPOINT = `${LOCATION_API_URL}/floors`;
export const ZONE_ENDPOINT = `${LOCATION_API_URL}/zones`;
export const ROOM_ENDPOINT = `${LOCATION_API_URL}/rooms`;
export const TASK_ENDPOINT = `${TASK_API_URL}/tasks`;
export const USER_ENDPOINT = `${USER_API_URL}/users`;
export const AUDIT_TRAIL_ENDPOINT = `${AUDIT_TRAIL_API_URL}/audit-trail`;

export const AZURE_ENV = `${process.env.REACT_APP_ENVIRONMENT}`;
export const CLIENT_ID = `${process.env.REACT_APP_CLIENT_ID}`;
export const POLICIES_SIGNUPSIGNIN = `${process.env.REACT_APP_B2C_POLICIES_SIGNUPSIGNIN}`;
export const POLICIES_FORGOTPASSWORD = `${process.env.REACT_APP_B2C_POLICIES_FORGOTPASSWORD}`;
export const POLICIES_EDITPROFILE = `${process.env.REACT_APP_B2C_POLICIES_EDITPROFILE}`;
