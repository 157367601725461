import type {Mode} from "../../components/general/types/Modify";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {paths} from "../../navigation/paths";
import {api} from "../../services/filterTypes.service";
import type {RouteProps} from "../../types";
import {FilterTypeModify} from "../../types/documents/FilterType";
import {ModifyContainer} from "../general/ModifyContainer";
import {FilterTypesConfig, constraints} from "./FilterTypesConfig";

interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const FilterTypesModify: Component = ({mode, ...routeProps}) => {
  const cancelToken = useCancelToken();
  const jwt = useToken();

  return (
    <div>
      <ModifyContainer<FilterTypeModify>
        {...routeProps}
        api={api}
        componentConfiguration={(props) => FilterTypesConfig(props, jwt)}
        constraints={constraints}
        initialData={{
          Name: "",
          CompatibleAssetTypeId: "",
          CompatibleAssetTypeName: "",
          Model: "",
          GTIN: "",
          Ref: "",
          ReplacementPeriod: 0,
        }}
        mode={mode}
        redirectPath={paths.filterTypes.list}
      />
    </div>
  );
};
