export const scheduleAlertStrings = {
  header: {
    scheduleAlerts: "Schedule Alerts",
  },
  labels: {
    scheduleAlert: "Schedule Alert",
    count: "Count",
  },
  placeholders: {
    alert: "Alert",
    count: "Count",
  },
  text: {},
};
