import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { protectedResources } from "../../authConfig";

export const useToken = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [token, setToken] = useState("");

  useEffect(() => {
    if (account && inProgress === "none" && !token) {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.apiTSafe.scopes,
          account: account,
        })
        .then((response) => {
          setToken("Bearer " + response.accessToken);
        })
        .catch((error) => {
          //in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError) {
            if (account && inProgress === "none") {
              instance
                .acquireTokenPopup({
                  scopes: protectedResources.apiTSafe.scopes,
                })
                .then((response) => {
                  setToken("Bearer " + response.accessToken);
                })
                .catch((error) => console.log(error));
            }
          }
        });
    }
  }, [account, inProgress, instance]);

  return token;
};
