import {AssetBreadcrumbHeader} from "../../components/asset/assetBreadcrumbHeader";
import type {Config, ConfigurationProps, ContentConfig} from "../../components/general/types/Modify";
import {assetStrings as strings} from "../../resources/strings/assets";
import {Asset} from "../../types/documents/Asset";

const headerConfig: Config<Asset> = [
  {
    key: "header",
    content: [
      {
        controltype: "custom",
        Component: AssetBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
];

const createContent: ContentConfig<Asset>[] = [];
const updateContent: ContentConfig<Asset>[] = [];

const assetContent = (mode: string, getAssetTypes: any, getAssets: any): ContentConfig<Asset>[] => {
  const config: ContentConfig<Asset>[] = [
    {
      controltype: "header",
      text: strings.header.assetInfo,
    },
    {
      controltype: "input",
      name: "AssetBarcode",
      label: strings.labels.barcode,
    },
    {
      controltype: "dropdown",
      name: "AssetTypeId",
      label: strings.labels.assetType,
      required: true,
      options: getAssetTypes,
    },
    {
      controltype: "locationselector",
      name: "LocationId",
      label: strings.labels.location,
    },
    {
      controltype: "input",
      name: "Notes",
      label: strings.labels.notes,
    },
  ];
  const endContent = mode === "create" ? createContent : updateContent;
  return [...config, ...endContent];
};

const assetInfoConfig = (mode: string, assetData: Asset, getAssetTypes: any, getAssets: any): Config<Asset> => [
  {
    key: "fields",
    content: assetContent(mode, getAssetTypes, getAssets),
  },
];

export const AssetsConfig = (props: ConfigurationProps<Asset>, getAssetTypes: any, getAssets: any): Config<Asset> => {
  const {data, mode} = props;
  return [...headerConfig, ...assetInfoConfig(mode, data, getAssetTypes, getAssets)];
};
