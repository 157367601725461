import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {detailErrorResponse} from "../../helpers/control-helpers";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {notifications} from "../../libs/notifications";
import {paths} from "../../navigation/paths";
import {clientStrings as strings} from "../../resources/strings/client";
import {api as usersapi} from "../../services/users.service";
import type {Client} from "../../types/documents/Client";
import {BreadcrumbHeader} from "../general/BreadcrumbHeader";
import {CreateButtons} from "../general/CreateButtons";
import {Header} from "../general/Header";
import {UpdateDeleteButtons} from "../general/UpdateDeleteButtons";
import type {CustomProps} from "../general/types/Modify";

interface Props extends CustomProps<Client> {}
type Component = (props: Props) => JSX.Element;

export const ClientBreadcrumbHeader: Component = ({mode}) => {
  const {id} = useParams<{id?: string}>();
  const jwt = useToken();
  const cancelToken = useCancelToken();
  const history = useNavigate();
  const [toDelete, setToDelete] = useState(false);

  useEffect(() => {
    async function deleteItem(id: string) {
      try {
        await usersapi.deleteUsersForClient(id, jwt, cancelToken);
        setToDelete(false);
        handleBack();
        notifications.success(strings.text.deleteSuccess);
      } catch (error) {
        notifications.error(detailErrorResponse(error));
      }
    }
    if (id && toDelete) {
      deleteItem(id);
    }
  }, [id, jwt, cancelToken, toDelete]);

  const handleBack = () => history(paths.client.list);
  const handleDelete = () => setToDelete(true);

  const createButtons = <CreateButtons strings={strings} handleBack={handleBack} />;
  const updateDeleteButtons = <UpdateDeleteButtons strings={strings} handleDelete={handleDelete} handleBack={handleBack} />;

  return (
    <BreadcrumbHeader
      header={mode === "create" ? <Header text={strings.header.createItem} /> : <Header text={strings.header.modifyItem} />}
      children={mode === "create" ? <>{createButtons}</> : <>{updateDeleteButtons}</>}
    />
  );
};
