import {Grid} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Loader} from "../../components/general/Loader";
import {ModifyComponent} from "../../components/general/ModifyComponent";
import type {ChangeHandler, ComponentConfiguration, Mode} from "../../components/general/types/Modify";
import {useClientContext} from "../../context/ClientContext";
import {ModifyContextProvider} from "../../context/ModifyContext";
import {detailErrorResponse} from "../../helpers/control-helpers";
import {logger} from "../../helpers/log-helpers";
import {validate} from "../../helpers/validation-helpers";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {notifications} from "../../libs/notifications";
import {appStrings as strings} from "../../resources/strings/app";
import type {CreateFunction, ReadFunction, UpdateFunction} from "../../types/API";

export interface Props<D extends object> {
  children?: JSX.Element;
  api: {
    read: ReadFunction<D>;
    update: UpdateFunction<D>;
    create: CreateFunction<D>;
  };
  initialData: D;
  componentConfiguration: ComponentConfiguration<D>;
  mode: Mode;
  constraints: object;
  redirectPath: string | ((id: string) => string);
  loading?: boolean;
}

export const ModifyContainer = <D extends {[key: string]: unknown}>({
  children,
  api,
  initialData: _initialData,
  componentConfiguration,
  mode: inputMode,
  constraints,
  redirectPath,
  loading: propsLoading = false,
}: Props<D>) => {
  const history = useNavigate();
  const {id, childId} = useParams<{id?: string; childId?: string}>();
  const jwt = useToken();
  const cancelToken = useCancelToken();
  const [mode, setMode] = useState<Mode>(() => inputMode);
  const {clientId, setClientId} = useClientContext();
  const [initialData, setInitialData] = useState(() => _initialData);
  const [formData, setFormData] = useState<D>(initialData);
  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");

  const [validationResults, setValidationResults] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [success, setSuccess] = useState(() => false);
  const [redirectId, setRedirectId] = useState("");

  useEffect(() => {
    const query = async (inputId: string, token: string) => {
      setLoading(true);
      try {
        const {item} = await api.read(inputId, childId, token, cancelToken);
        setFormData(item);
        setInitialData(item);
        setLoading(false);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = logger.error(e);
        setError(error);
        setLoading(false);
      }
    };

    // pass the ID into the query function to avoid type issues when using
    // `id` inside query since technically it can be undefined
    // (should only be undefined in create mode when this query isnt required)
    if (id && jwt && inputMode !== "create") query(id, jwt);
  }, [id, childId, inputMode, jwt, cancelToken, clientId, api]);

  const handleModeSwitch = useCallback(() => {
    switch (mode) {
      case "update":
        break;
      case "view":
        setMode("update");
        break;
      case "create":
        let path = "";
        if (typeof redirectPath === "string") {
          path = redirectPath;
        } else {
          path = redirectPath(redirectId);
        }

        if (success && path) history(path);
        break;
      default:
        break;
    }
  }, [mode, history, redirectPath, redirectId, success]);

  const handleReset = useCallback(() => {
    setFormData(initialData);
    handleModeSwitch();
    setValidationResults(null);
  }, [initialData, handleModeSwitch]);

  useEffect(() => {
    if (success) {
      handleModeSwitch();
      setSuccess(false);
    }
  }, [success, handleModeSwitch]);

  const handleChange: ChangeHandler = async (e, data: any = null) => {
    if (formData.hasOwnProperty("LocationId") && data?.hasOwnProperty("selectedLocation")) {
      const {selectedLocation} = data;
      const {LocationName, LocationId, LocationDepth} = selectedLocation;
      const newFormData = {...formData, LocationName, LocationId, LocationDepth};
      await setFormData(newFormData);
    } else {
      const {target} = e;
      const {name, value, checked} = target;
      if (!name) return;
      const newValue = target.hasOwnProperty("checked") ? checked : value;
      const newFormData = {...formData, [name]: newValue};
      setFormData(newFormData);
    }
  };

  const submitData = async () => {
    let dataToSubmit = {...formData};
    if (clientId != null) {
      dataToSubmit = {...formData, ClientId: clientId};
    }
    const body = {
      eventType: mode,
      payload: dataToSubmit,
    };
    const apiFunction = mode === "create" ? api.create : api.update;

    setLoading(true);
    setValidationResults(null);
    try {
      const {id} = await apiFunction(body, jwt, cancelToken);
      setRedirectId(id);
      setSuccess(true);
      setLoading(false);
      notifications.success(strings.notifications.messages.submitted);
    } catch (e) {
      const error = logger.error(e);
      setError(error);
      setLoading(false);
      notifications.error(detailErrorResponse(e));
    }
  };

  const validateForm = () => {
    validate<D>(formData, constraints, submitData, setValidationResults);
  };

  const renderChildren = () => {
    if (!children) return null;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const isLoading = [loading, propsLoading].some((l) => l);

  return (
    <ModifyContextProvider value={{handleModeSwitch}}>
      <Loader active={isLoading}>
        <div>
          <ModifyComponent<D>
            mode={mode}
            handleModeSwitch={handleModeSwitch}
            validateForm={validateForm}
            data={formData}
            handleChange={handleChange}
            handleReset={handleReset}
            componentConfiguration={componentConfiguration}
            loading={isLoading}
            setFormData={setFormData}
            validationResults={validationResults}
            permissions={null}
            user={null}
            jwt={jwt}
          />
          {renderChildren()}
        </div>
      </Loader>
    </ModifyContextProvider>
  );
};
