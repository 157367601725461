import {AxiosRequestConfig} from "axios";
import axios from "../libs/axios/axios-filterType";
import {QueryFunction} from "../types/API";
import { BespokeFilterType, BespokeFilterTypeDto } from "../types/documents/BespokeFilterType";
import { FilterTypeDto } from "../types/documents/FilterType";

const query: QueryFunction<BespokeFilterType> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const path = "/filtertypes/search";
  const {id, desc} = sort[0] ?? {};
  const config: AxiosRequestConfig = {
    params: {
      page: page + 1,
      size: size,
      sort: id,
      order: desc ? "desc" : "asc",
    },
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };

   // First API call to get filter types
   const filterTypeResponse = await axios.get<{ filtertypes: FilterTypeDto[]; count: number }>(path, config);
   const filterTypeData = filterTypeResponse.data.filtertypes;
   const count = filterTypeResponse.data.count;

   const bespokePath = `filtertypes/bespoke`;
   
   // Second API call to get bespoke filter types
   const bespokeFilterTypeResponse = await axios.get<BespokeFilterTypeDto[]>(bespokePath, config);
   const bespokeFilterTypeData = bespokeFilterTypeResponse.data;

   // Map the filterTypeData with bespokeFilterTypeData
   const result = filterTypeData.map((filterType) => {
     const bespokeFilterType = bespokeFilterTypeData.find((bespokeFilterType) => bespokeFilterType.FilterTypeId === filterType.Id);
     const customReplacement = bespokeFilterType ? bespokeFilterType.OverrideReplacementPeriod : null;
     return { ...filterType, CustomReplacementPeriod: customReplacement } as BespokeFilterType;
   });

   return {
     items: result,
     totalCount: count,
   };
};

const setCustomReplacementPeriod = async (id: string, customReplacementPeriod: number, jwt: string) => {
    const path = `/filtertypes/bespoke`;

    const replacement = Number(customReplacementPeriod);
    const config: AxiosRequestConfig = {
      headers: {Authorization: jwt},
    };
    const response = await axios.put(path, {FilterTypeId: id,OverrideReplacementPeriod: replacement}, config);
    return response.data;
};


export const api = {
  query,
  setCustomReplacementPeriod,
};
