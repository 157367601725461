import { useEffect, useState } from "react";
import { logger } from "../../helpers/log-helpers";
import type { ReadFunction } from "../../types/API";
import { useToken } from "../authentication/useToken";
import { HooksLogger } from "../hooks-logger";
import { useCancelToken } from "./useCancelToken";

const hooksLogger = new HooksLogger("useReadQuery");

export const useReadQuery = <D extends object>(
  defaultValue: D,
  readFunction: ReadFunction<D>,
  inputId?: string,
  childId?: string
) => {
  const [item, setItem] = useState<D>(() => defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const cancelToken = useCancelToken();
  const jwt = useToken();

  useEffect(() => {
    const query = async (id: string) => {
      hooksLogger.request("Getting item data");

      setLoading(true);
      try {
        const { item } = await readFunction(id, childId, jwt, cancelToken);

        setItem(item);
        setLoading(false);

        hooksLogger.success(item);
      } catch (e) {
        if (cancelToken.reason) return;

        const message = logger.error(e);
        setLoading(false);
        setError(message);
        hooksLogger.error(message);
      }
    };

    if (inputId) query(inputId);
  }, [readFunction, inputId, childId, cancelToken]);

  return [item, loading, error] as const;
};
