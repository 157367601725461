export const paths = {
  root: `/`,
  dashboard: `/Dashboard`,
  home: {
    list: `/dashboard`,
  },
  client: {
    list: `/clients`,
    create: `/clients/create`,
    modify: (id: string) => `/clients/edit/${id}`,
  },
  site: {
    create: `/sites/create`,
    list: `/sites`,
    modify: (id: string) => `/sites/edit/${id}`,
  },
  building: {
    create: (parentId: string) => `/buildings/create/${parentId}`,
    list: `/buildings`,
    modify: (id: string) => `/buildings/edit/${id}`,
  },
  zone: {
    create: (parentId: string) => `/zones/create/${parentId}`,
    list: `/zones`,
    modify: (id: string) => `/zones/edit/${id}`,
  },
  floor: {
    create: (parentId: string) => `/floors/create/${parentId}`,
    list: `/floors`,
    modify: (id: string) => `/floors/edit/${id}`,
  },
  room: {
    create: (parentId: string) => `/rooms/create/${parentId}`,
    list: `/rooms`,
    modify: (id: string) => `/rooms/edit/${id}`,
  },
  users: {
    list: `/users`,
    create: `/users/create`,
    edit: (id: string) => `/users/edit/${id}`,
  },
  locations: {
    list: `/locations`,
  },
  tasks: {
    list: `/tasks`,
    create: `/tasks/create`,
    modify: (id: string) => `/tasks/edit/${id}`,
  },
  assets: {
    list: `/assets`,
    create: `/assets/create`,
    modify: (id: string) => `/assets/edit/${id}`,
  },
  auditTrail: {
    list: `/audittrail`,
  },
  filterTypes: {
    list: `/filtertypes`,
    create: `/filtertypes/create`,
    edit: (id: string) => `/filtertypes/edit/${id}`,
  },
  import: {
    list: `/import`,
    uploadingData: (id: string | undefined) => `/import/${id}/uploadingData`,
    processingData: (id: string | undefined) => `/import/${id}/processingData`,
    processingFailed: (id: string | undefined) => `/import/${id}/processingFailed`,
    uploadFailed: (id: string | undefined) => `/import/${id}/uploadFailed`,
    pendingApproval: (id: string | undefined) => `/import/${id}/pendingApproval`,
    validationFailed: (id: string | undefined) => `/import/${id}/validationFailed`,
  },
  documents: {
    list: `/documents`,
  },
  login: {
    login: `/login`,
  },
  passwordReset: {
    passwordReset: `/passwordReset`,
  },
};

export default paths;
