import {Box} from "@mui/material";
import {useMemo} from "react";
import {BreadcrumbHeader} from "../../../components/general/BreadcrumbHeader";
import {CSVExportButton} from "../../../components/general/CSVExportButton";
import {useCancelToken} from "../../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../../hooks/general/useSessionStorage";
import {scheduleAlertStrings} from "../../../resources/strings/scheduleAlerts";
import {userAlertStrings} from "../../../resources/strings/userAlerts";
import {api as scheduleAlertAPI} from "../../../services/scheduleAlerts.service";
import {api as tasksApi} from "../../../services/tasks.service";
import {api as userAlertAPI} from "../../../services/userAlerts.service";
import {FilterChangeHandler, RouteProps} from "../../../types";
import type {FilterTypeFilters} from "../../../types/documents/FilterType";
import {ScheduleAlert} from "../../../types/documents/ScheduleAlert";
import {UserAlert} from "../../../types/documents/UserAlert";
import {TableContainer} from "../../general/TableContainer";

interface Props extends RouteProps {
  locationId: string;
  locationName: string;
}
type Component = (props: Props) => JSX.Element;

const AlertSummaryPane: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<FilterTypeFilters>({}, "filterTypeFilters");

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const [locationId, setLocationId] = useSessionStorage<string>(routeProps.locationId, "locationId");
  const [locationName, setLocationName] = useSessionStorage<string>(routeProps.locationName, "locationName");

  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const cancelToken = useCancelToken();
  const Id = locationId;

  return (
    <Box position="relative" height="100%">
      <>
        <div>
          <BreadcrumbHeader header={"Alert Summary"} children={[]} spacing={1.3} hideBgColor={true} />
          <TableContainer<UserAlert>
            {...routeProps}
            api={userAlertAPI}
            tableColumns={[
              {
                id: "userAlert",
                Header: userAlertStrings.labels.userAlert,
                accessor: "AlertType",
              },
              {
                id: "count",
                Header: userAlertStrings.labels.count,
                accessor: "Count",
              },
            ]}
            filters={Id === "NSL" ? ({} as any) : ({Location: Id} as any)}
          ></TableContainer>
          <TableContainer<ScheduleAlert>
            {...routeProps}
            api={scheduleAlertAPI}
            tableColumns={[
              {
                id: "scheduleAlert",
                Header: scheduleAlertStrings.labels.scheduleAlert,
                accessor: "AlertType",
              },
              {
                id: "count",
                Header: scheduleAlertStrings.labels.count,
                accessor: "Count",
              },
            ]}
            filters={Id === "NSL" ? ({} as any) : ({Location: Id} as any)}
          ></TableContainer>
          <br></br>
        </div>

        <div style={{float: "right", padding: "0px 15px 10px 0px"}}>
          <CSVExportButton
            config={{
              label: "Download CSV",
              downloadFunction: tasksApi.downloadAlertStatus,
              location: Id === "NSL" || Id === undefined ? "" : Id,
              filenamePrefix: "AlertSummaryData",
            }}
          />
        </div>
      </>
    </Box>
  );
};

export default AlertSummaryPane;
