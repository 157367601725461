import Add from "@mui/icons-material/Add";
import TreeItem, {TreeItemContentProps, TreeItemProps, useTreeItem} from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import {CancelToken} from "axios";
import clsx from "clsx";
import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Mode} from "../../../components/general/types/Modify";
import {useToken} from "../../../hooks/authentication/useToken";
import {useCancelToken} from "../../../hooks/general/useCancelToken";
import paths from "../../../navigation/paths";
import {api} from "../../../services/locations.service";
import {RouteProps} from "../../../types";
import {Location} from "../../../types/documents/Location";
import RoomSelect from "../rooms/RoomSelect";

interface Props extends RouteProps {
  parentId: string;
  nodeId: string;
  label: string;
  mode: Mode;
  depth?: number;
  setSelectedLocation: Function | undefined;
}
type Component = (props: Props) => JSX.Element;

const ZoneTreeItem: Component = ({mode, setSelectedLocation, depth, ...routeProps}) => {
  const jwt = useToken();
  const cancelToken = useCancelToken();

  const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const history = useNavigate();
    const {classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon} = props;
    const {disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection} = useTreeItem(nodeId);
    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleSelection(event);
      if (props.nodeId === "addRoom") {
        history(paths.room.create(routeProps.nodeId));
      } else if (mode === "view" && setSelectedLocation !== undefined) {
        const locationId = routeProps.nodeId;
        const locationName = routeProps.label;
        const locationDepth = 3;
        setSelectedLocation(locationId, locationName, locationDepth);
      } else if (mode !== "view" && depth === 4) {
        history(paths.zone.modify(props.nodeId));
      } else if (depth === 5) {
        history(paths.room.modify(props.nodeId));
      } else {
        console.log("SetSelectedLocation function is required in view mode");
        throw new Error("SetSelectedLocation function is required in view mode");
      }
    };

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
          {label}
        </Typography>
      </div>
    );
  });

  function CustomTreeItem(props: TreeItemProps) {
    return <TreeItem ContentComponent={CustomContent} {...props} />;
  }

  const [rooms, setRooms] = useState<Location[]>([]);

  useEffect(() => {
    async function getRooms(jwt: string, cancelToken: CancelToken) {
      const roomList = await api.getLocations(routeProps.nodeId, jwt, cancelToken);
      setRooms(roomList);
    }

    if (jwt) {
      getRooms(jwt, cancelToken);
    }
  }, [jwt, cancelToken]);

  return (
    <>
      <CustomTreeItem nodeId={routeProps.nodeId} label={routeProps.label}>
        {rooms.map((room) => (
          <RoomSelect
            setSelectedLocation={setSelectedLocation}
            mode={mode}
            nodeId={room.Id ? room.Id : ""}
            parentId={room.Id ? room.Id : ""}
            label={room.Name}
            permissions={null}
            user={null}
          ></RoomSelect>
        ))}
        <CustomTreeItem
          nodeId="addRoom"
          label={<Typography sx={{textDecoration: "underline"}}>Add Room</Typography>}
          sx={{color: "blue"}}
          icon={<Add sx={{color: "blue"}} />}
        ></CustomTreeItem>
      </CustomTreeItem>
    </>
  );
};

export default ZoneTreeItem;
