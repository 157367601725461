import {SxProps, Theme} from "@mui/material";
import {Button} from "./Button";
import {DeleteModal} from "./DeleteModal";

interface Props {
  strings: any;
  handleDelete: any;
  handleBack: any;
}
type Component = (props: Props) => JSX.Element;

const cancelButtonStyles: SxProps<Theme> = (theme) => ({
  color: "#999999",
  backgroundColor: "white",
  "&:hover": {
    color: "#999999",
    backgroundColor: "white",
    boxShadow: "none",
  },
  padding: 0,
  margin: 0,
  width: "100px",
  minWidth: "100px",
});

const updateButtonStyles: SxProps<Theme> = (theme) => ({
  color: "white",
  backgroundColor: "#2643D6",
});

export const UpdateDeleteButtons: Component = ({strings, handleDelete, handleBack}) => {
  return (
    <>
      <DeleteModal header={strings.header.deleteItem} confirmationMessage={strings.text.deleteConfirmation} handleDelete={handleDelete} />
      <Button label={strings.labels.cancel} type="reset" variant="outlined" onClick={handleBack} sx={[cancelButtonStyles]} />
      <Button
        label="Update" //{strings.labels.save}
        variant="contained"
        type="submit"
        sx={[updateButtonStyles]}
      />
    </>
  );
};
