import {Paper, Typography, Link as UILink} from "@mui/material";
import {useMemo} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";
import {SearchBar} from "../../components/common/SearchBar";
import {UploadButton} from "../../components/common/UploadButton";
import {Link} from "../../components/general/controls/Link";
import {ImportModal} from "../../components/import/ImportModal";
import {useClientContext} from "../../context/ClientContext";
import "../../fonts/Extratype-Eina04-Bold.otf";
import {formatDateTime} from "../../helpers/date-helpers";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import paths from "../../navigation/paths";
import {importStrings as strings} from "../../resources/strings/imports";
import {api} from "../../services/imports.service";
import {FilterChangeHandler, RouteProps} from "../../types";
import {Import, ImportFilters} from "../../types/documents/Import";
import {TableContainer} from "../general/TableContainer";

interface Props extends RouteProps {
  status: string;
}
type Component = (props: Props) => JSX.Element;

const ImportsList: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<ImportFilters>({}, "importFilters");
  const {clientId, setClientId} = useClientContext();
  const {id} = useParams<{id?: string}>();

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const clearFilters = () => setFilters({});

  const getPath = (importStatus: string, id: string | undefined) => {
    let path = "";
    switch (importStatus) {
      case "Uploading Data":
        path = paths.import.uploadingData(id);
        break;
      case "Processing Data":
        path = paths.import.processingData(id);
        break;
      case "Data Processing Failed":
        path = paths.import.processingFailed(id);
        break;
      case "Upload Failed":
        path = paths.import.uploadFailed(id);
        break;
      case "Pending Approval":
        path = paths.import.pendingApproval(id);
        break;
      case "Data Validation Failed":
        path = paths.import.validationFailed(id);
        break;
      default:
        path = paths.import.list;
    }
    return path;
  };

  const SelectClientMessage = (
    <Paper>
      <Typography
        sx={{
          textAlign: "center",
          marginTop: 10,
          border: 0,
        }}
      >
        Select a client to view the imports
      </Typography>
    </Paper>
  );

  return (
    <div>
      {clientId === null ? ( //null means no client is selected, but undefined means not yet loaded (so we don't display the message then)
        SelectClientMessage
      ) : (
        <TableContainer<Import>
          {...routeProps}
          api={api}
          header={strings.headers.importList}
          tableColumns={[
            {
              id: "fileName",
              Header: strings.labels.fileName,
              accessor: (x) => {
                return <p>{x.FileName}</p>;
              },
            },
            {
              id: "uploadDate",
              Header: strings.labels.importDate,
              accessor: ({UploadDate}) => (UploadDate ? formatDateTime(UploadDate) : "-"),
            },
            {
              id: "status",
              Header: strings.labels.status,
              accessor: (importAccessor) => {
                const {ImportStatus, Id} = importAccessor;
                const path = getPath(ImportStatus, Id);
                return ImportStatus === "Approved" || ImportStatus === "Rejected" ? (
                  <UILink component={RouterLink} color={"#000000"} underline="none" sx={{color: "#000000", fontSize: 15}} to={path}>
                    {ImportStatus}
                  </UILink>
                ) : (
                  <Link
                    config={{
                      text: ImportStatus,
                      to: {pathname: path},
                      variant: "body2",
                    }}
                  />
                );
              },
            },
          ]}
          headerContent={() => (
            <UploadButton
              label={strings.labels.uploadCSV}
              allowedFileTypes={["text/csv", "application/vnd.ms-excel"]} //A plain CSV file is being identified as ms-excel
              allowedFileExtensions={["csv"]}
              api={api}
            />
          )}
          manualPagination={true}
          filters={tableFilters as Import | undefined}
          children={
            routeProps.status === "" ? (
              <SearchBar filters={filters} handleFilterChange={handleFilterChange} clearFilters={clearFilters} tags={{}} />
            ) : (
              <ImportModal header={strings.headers.importCSV} id={id} status={routeProps.status} />
            )
          }
        ></TableContainer>
      )}
    </div>
  );
};

export default ImportsList;
