import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const onRequest = async (
	initialConfig: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
	const config = Object.assign({}, initialConfig);

	return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
	return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
	return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
	return Promise.reject(error);
};

const interceptors = {
	onRequest,
	onRequestError,
	onResponse,
	onResponseError,
};

export default interceptors;
