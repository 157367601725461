import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToken } from "../../../hooks/authentication/useToken";
import { useCancelToken } from "../../../hooks/general/useCancelToken";
import { paths } from "../../../navigation/paths";
import { locationStrings as strings } from "../../../resources/strings/locations";
import { api } from "../../../services/locations.service";
import type { Location } from "../../../types/documents/Location";
import { BreadcrumbHeader } from "../../general/BreadcrumbHeader";
import { CreateButtons } from "../../general/CreateButtons";
import { Header } from "../../general/Header";
import type { CustomProps } from "../../general/types/Modify";
import { UpdateDeleteButtons } from "../../general/UpdateDeleteButtons";

interface Props extends CustomProps<Location> {}
type Component = (props: Props) => JSX.Element;

export const ZoneBreadcrumbHeader: Component = ({ mode }) => {
  const { id } = useParams<{ id?: string }>();
  const jwt = useToken();
  const cancelToken = useCancelToken();
  const history = useNavigate();
  const [toDelete, setToDelete] = useState(false);

  useEffect(() => {
    async function deleteItem(id: string) {
      await api.deleteLocation(id, jwt, cancelToken);
      setToDelete(false);
      handleBack();
    }
    if (id && toDelete) {
      deleteItem(id);
    }
  }, [toDelete]);

  const handleBack = () => {
    history(paths.locations.list);
  };

  const handleDelete = () => {
    setToDelete(true);
  };

  const createButtons = (
    <CreateButtons strings={strings} handleBack={handleBack} />
  );

  const updateDeleteButtons = (
    <UpdateDeleteButtons
      strings={strings}
      handleDelete={handleDelete}
      handleBack={handleBack}
    />
  );

  return (
    <BreadcrumbHeader
      header={
        mode === "create" ? (
          <Header text={strings.header.createZone} />
        ) : (
          <Header text={strings.header.editZone} />
        )
      }
      children={
        mode === "create" ? <>{createButtons}</> : <>{updateDeleteButtons}</>
      }
    />
  );
};
