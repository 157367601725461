import {useCallback, useState} from "react";
import {FilterTypeBreadcrumbHeader} from "../../components/filtertype/FilterTypeBreadcrumbHeader";
import type {Config, ConfigurationProps, ContentConfig, Mode} from "../../components/general/types/Modify";
import {createDropdownOptions} from "../../helpers/dropdown-helpers";
import {filterTypeStrings as strings} from "../../resources/strings/filterTypes";
import {api} from "../../services/assets.service";
import {DropdownOptions, ValidationConstraints} from "../../types";
import {FilterTypeModify} from "../../types/documents/FilterType";

const headerConfig: Config<FilterTypeModify> = [
  {
    key: "fields",
    content: [
      {
        controltype: "custom",
        Component: FilterTypeBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
];

const filterTypeContent = (mode: Mode, filterTypeData: FilterTypeModify, getAssetTypes: any, assetOptions: DropdownOptions | undefined): ContentConfig<FilterTypeModify>[] => {
  const commonConfig: ContentConfig<FilterTypeModify>[] = [
    {
      controltype: "header",
      text: strings.header.filterTypeDetails,
    },
    {
      controltype: "input",
      name: "Name",
      label: strings.labels.filterTypeName,
    },
    {
      controltype: "dropdown",
      name: "CompatibleAssetTypeId",
      label: strings.labels.compatibleAssetType,
      options: getAssetTypes,
      disabled: assetOptions?.length === 0,
    },
    {
      controltype: "input",
      name: "Model",
      label: strings.labels.model,
    },
    {
      controltype: "input",
      name: "GTIN",
      label: strings.labels.gtin,
    },
    {
      controltype: "input",
      name: "Ref",
      label: strings.labels.ref,
    },
    {
      controltype: "input",
      name: "ReplacementPeriod",
      label: strings.labels.maxReplacementPeriod,
    },
  ];

  const createConfig: ContentConfig<FilterTypeModify>[] = [];
  const updateConfig: ContentConfig<FilterTypeModify>[] = [];
  const config: ContentConfig<FilterTypeModify>[] = mode === "create" ? [...commonConfig, ...createConfig] : [...commonConfig, ...updateConfig];
  return config;
};

export const FilterTypesConfig = (props: ConfigurationProps<FilterTypeModify>, jwt: string): Config<FilterTypeModify> => {
  const {data, mode} = props;
  const [currentAssetOptions, setCurrentAssetOptions] = useState<DropdownOptions>();

  type ListItems = {[key: string]: any}[];
  const getAssetTypes = useCallback(async () => {
    const assetTypes: ListItems = [{Key: "1"}];
    if (!jwt) {
      return createDropdownOptions(assetTypes, "Key", "1");
    }
    const items = await api.getAssetTypes({size: 100000}, jwt);
    return createDropdownOptions(items.items, "Id", "Name");
  }, [jwt]);

  const filterTypeInfoConfig = [
    {
      key: "fields",
      content: filterTypeContent(mode, data, getAssetTypes, currentAssetOptions),
    },
  ];

  return [...headerConfig, ...filterTypeInfoConfig];
};

export const constraints: ValidationConstraints<FilterTypeModify> = {
  Name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.name} is required`,
    },
  },
  Model: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.model} is required`,
    },
  },
  GTIN: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.gtin} is required`,
    },
  },
  Ref: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.ref} is required`,
    },
  },
  ReplacementPeriod: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.maxReplacementPeriod} is required`,
    },
  },
};
