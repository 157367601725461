import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Box,
  CardMedia,
  Container,
  Divider,
  Drawer,
  drawerClasses,
  IconButton,
  styled,
  SxProps,
  Theme,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import logo from "../../assets/T-safe.svg";
import type { MenuContext } from "../../context/MenuContext";
import { MenuContextProvider } from "../../context/MenuContext";
import "../../fonts/Extratype-Eina04-Regular.otf";
import "../../fonts/Extratype-Eina04-SemiBold.otf";
import "../../styles/App.css";
import { Permissions } from "../../types";
import type { MenuItemsType } from "./listItems";
import { MenuItems } from "./MenuItemsComponent";

export interface Props {
  children: JSX.Element;
  menuItems: MenuItemsType;
  permissions: Permissions | null;
  additionalContent?: JSX.Element;
}
type Component = (props: Props) => JSX.Element;

const drawerWidth = 240;
const smallDrawerWidth = 0;

const Div = styled("div")({});
// as of writing, there seems to be a bug with the toolbar mixin when used with sx
// apply it using the styled API instead
const ToolbarDiv = styled("div")(({ theme }) => theme.mixins.toolbar);
const Main = styled("main")({});

const rootStyles: SxProps<Theme> = {
  display: "flex",
};

const appBarStyles: SxProps<Theme> = (theme) => ({
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //backgroundColor: "#222D54",
  backgroundColor: "white",
  borderBottom: "1px solid #E0E0E0",
  borderLeft: "1px solid #E0E0E0",
});

const appBarOpenStyles: SxProps<Theme> = (theme) => ({
  marginLeft: drawerWidth,
  width: `calc(100.05% - ${drawerWidth}px)`, //.05% added on to get rid of white line that appears if not
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const toolbarStyles: SxProps<Theme> = {
  paddingRight: "1.5rem", // keep right padding when drawer closed
};

const menuIconStyles: SxProps<Theme> = {
  marginRight: "2.25rem",
  color: "#2643D6",
};

const drawerStyles: SxProps<Theme> = (theme) => ({
  [`& .${drawerClasses.paper}`]: {
    position: "relative",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("xs")]: {
      width: drawerWidth,
    },
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  overflowX: "hidden",
});

const drawerClosedStyles: SxProps<Theme> = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("xs")]: {
    width: smallDrawerWidth,
  },
  [theme.breakpoints.up("md")]: {
    width: theme.spacing(7),
  },
  [`& .${drawerClasses.paper}`]: {
    overflowX: "hidden",
    [theme.breakpoints.up("xs")]: {
      width: smallDrawerWidth,
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(7),
    },
  },
  overflowX: "hidden",
});

const drawerContainerStyles: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "65px",
  backgroundColor: "white",
  width: "240px",

  //padding: "0 0.5rem",
};

const mediaStyles: SxProps<Theme> = (_theme) => ({
  height: "40px",
  width: "91.48px",

  margin: "4rem",
  backgroundColor: "white",
  color: "#222D54",
});

const mediaClosedStyles: SxProps<Theme> = {
  display: "none",
};

const mainStyles: SxProps<Theme> = {
  flexGrow: 1,
  height: "100vh",
  overflow: "auto",
};

export const MenuComponent: Component = ({
  children,
  menuItems,
  permissions,
  additionalContent,
}) => {
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState<string[]>([]);

  const handleSelect = (newSelected: string, parent?: string) =>
    setSelected(() => {
      if (parent) {
        return [parent, newSelected];
      }
      return [newSelected];
    });

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const menuContext: MenuContext = {
    permissions,
    open,
    selected,
    handleSelect,
  };

  return (
    <MenuContextProvider value={menuContext}>
      <Div sx={[rootStyles]} role="menu">
        <AppBar
          position="absolute"
          color="primary"
          sx={[appBarStyles, open && appBarOpenStyles]}
        >
          <Toolbar sx={[toolbarStyles]}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggle}
              sx={[menuIconStyles]}
              data-testid="toggle-button"
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Box color="inherit" flexGrow={1}></Box>
            {additionalContent ? additionalContent : null}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={[drawerStyles, !open && drawerClosedStyles]}
          open={open}
        >
          <ToolbarDiv sx={[drawerContainerStyles]}>
            <CardMedia
              sx={[mediaStyles, !open && mediaClosedStyles]}
              image={logo}
            />
          </ToolbarDiv>
          <Divider hidden />
          <MenuItems menuItems={menuItems} />
        </Drawer>
        <Main sx={[mainStyles]}>
          <ToolbarDiv />
          <Container maxWidth={false} disableGutters>
            {children}
          </Container>
        </Main>
      </Div>
    </MenuContextProvider>
  );
};
