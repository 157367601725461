import {useEffect} from "react";
import type {Config, ConfigurationProps, ContentConfig} from "../../components/general/types/Modify";
import {UserBreadcrumbHeader} from "../../components/user/UserBreadcrumbHeader";
import {createDropdownOptions} from "../../helpers/dropdown-helpers";
import {userStrings as strings} from "../../resources/strings/users";
import {User} from "../../types/documents/User";

const getRolesCreate = (userRole: string) => {
  let items = [
    {label: "Admin", value: "admin"},
    {label: "Supervisor", value: "supervisor"},
    {label: "Engineer", value: "engineer"},
  ];
  if (userRole === "superadmin") {
    items.unshift({label: "Superadmin", value: "superadmin"});
  }
  return createDropdownOptions(items, "value", "label");
};

const getRolesEdit = (currentRole: string) => {
  let items = [
    {label: "Admin", value: "admin"},
    {label: "Supervisor", value: "supervisor"},
    {label: "Engineer", value: "engineer"},
  ];
  if (currentRole === "superadmin") {
    items.unshift({label: "Superadmin", value: "superadmin"});
  }
  return createDropdownOptions(items, "value", "label");
};

const headerConfig: Config<User> = [
  {
    key: "header",
    content: [
      {
        controltype: "custom",
        Component: UserBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
];

const editContent = (user: User, getClients: any): ContentConfig<User>[] => {
  return [
    {
      controltype: "header",
      text: strings.header.userDetails,
    },
    {
      controltype: "input",
      name: "FirstName",
      label: strings.labels.firstName,
    },
    {
      controltype: "input",
      name: "LastName",
      label: strings.labels.lastName,
    },
    {
      controltype: "input",
      name: "Email",
      label: strings.labels.email,
    },
    {
      controltype: "dropdown",
      name: "Role",
      label: strings.labels.role,
      options: getRolesEdit(user.Role),
      disabled: user.Role === "superadmin",
    },
  ];
};

const editContentSuperadmin = (user: User, getClients: any): ContentConfig<User>[] => {
  return [
    {
      controltype: "header",
      text: strings.header.userDetails,
    },
    {
      controltype: "input",
      name: "FirstName",
      label: strings.labels.firstName,
    },
    {
      controltype: "input",
      name: "LastName",
      label: strings.labels.lastName,
    },
    {
      controltype: "input",
      name: "Email",
      label: strings.labels.email,
    },
    {
      controltype: "dropdown",
      name: "Role",
      label: strings.labels.role,
      options: getRolesEdit(user.Role),
      disabled: user.Role === "superadmin",
    },
    {
      controltype: "dropdown",
      name: "UserClientId",
      label: strings.labels.client,
      options: getClients,
      hidden: user.Role === "superadmin",
    },
  ];
};

const createContent = (role: string, getClients: any): ContentConfig<User>[] => {
  return [
    {
      controltype: "header",
      text: strings.header.userDetails,
    },
    {
      controltype: "input",
      name: "FirstName",
      label: strings.labels.firstName,
    },
    {
      controltype: "input",
      name: "LastName",
      label: strings.labels.lastName,
    },
    {
      controltype: "input",
      name: "Email",
      label: strings.labels.email,
    },
    {
      controltype: "input",
      name: "Password",
      label: strings.labels.password,
      type: "password",
    },
    {
      controltype: "dropdown",
      name: "Role",
      label: strings.labels.role,
      options: getRolesCreate(role),
    },
  ];
};

const createContentSuperadmin = (user: User, role: string, getClients: any): ContentConfig<User>[] => {
  return [
    {
      controltype: "header",
      text: strings.header.userDetails,
    },
    {
      controltype: "input",
      name: "FirstName",
      label: strings.labels.firstName,
    },
    {
      controltype: "input",
      name: "LastName",
      label: strings.labels.lastName,
    },
    {
      controltype: "input",
      name: "Email",
      label: strings.labels.email,
    },
    {
      controltype: "input",
      name: "Password",
      label: strings.labels.password,
      type: "password",
    },
    {
      controltype: "dropdown",
      name: "Role",
      label: strings.labels.role,
      options: getRolesCreate(role),
    },
    {
      controltype: "dropdown",
      name: "UserClientId",
      label: strings.labels.client,
      options: getClients,
      hidden: user.Role === "superadmin",
    },
  ];
};

const userInfoConfig = (mode: string, userRole: string, userData: User, getClients: any): Config<User> => [
  {
    key: "userInfo",
    content:
      mode === "create"
        ? userRole === "superadmin"
          ? createContentSuperadmin(userData, userRole, getClients)
          : createContent(userRole, getClients)
        : userRole === "superadmin"
        ? editContentSuperadmin(userData, getClients)
        : editContent(userData, getClients),
  },
];

export const UsersConfig = (props: ConfigurationProps<User>, userRole: string, getClients: any, onRequireClientIdChange: (required: boolean) => void): Config<User> => {
  const {data, mode} = props;
  useEffect(() => {
    onRequireClientIdChange(data.Role !== "superadmin");
  }, [data]);
  return [...headerConfig, ...userInfoConfig(mode, userRole, data, getClients)];
};
