import { AxiosRequestConfig } from "axios";
import axios from "../libs/axios/axios-import";
import { QueryFunction } from "../types/API";
import {
  ValidationError,
  ValidationErrorFilters,
} from "../types/documents/Import";

const query: QueryFunction<ValidationError> = async (
  { page = 0, size = 10, sort = [], query },
  jwt?,
  cancelToken?
) => {
  const filters = query as ValidationErrorFilters;
  const path = `/${filters.Id}/`;
  const config: AxiosRequestConfig = {
    params: {
      page: page + 1,
      size: size,
      listErrors: true,
      //sort: id,
      //order: desc ? "desc" : "asc",
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const { data } = await axios.get<{
    errorItems: ValidationError[];
    totalErrorCount: number;
  }>(path, config);
  return {
    items: data.errorItems,
    totalCount: data.totalErrorCount,
  };
};

export const api = {
  query,
};
