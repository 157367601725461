import {AxiosRequestConfig} from "axios";
import axios from "../libs/axios/axios-filterType";
import {CreateFunction, CreateResult, DeleteFunction, QueryFunction, ReadFunction, UpdateFunction, UpdateResult} from "../types/API";
import {FilterType, FilterTypeDto, FilterTypeFilters, FilterTypeModify} from "../types/documents/FilterType";

const query: QueryFunction<FilterType> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const path = "/filtertypes/search";
  const filters = query as FilterTypeFilters;
  const {id, desc} = sort[0] ?? {};
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      page: page + 1,
      size: size,
      sort: id,
      order: desc ? "desc" : "asc",
    },
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };

  const {data} = await axios.get<{filtertypes: FilterTypeDto[]; count: number}>(path, config);
  const result = data.filtertypes.map((x) => {
    const compatibleAssetType: any = Array.isArray(x.CompatibleAssetTypes) && x.CompatibleAssetTypes.length > 0 ? x.CompatibleAssetTypes[0] : null;
    const assetTypeData = compatibleAssetType ? {CompatibleAssetTypeId: compatibleAssetType.Id ?? "", CompatibleAssetTypeName: compatibleAssetType.Name ?? ""} : {};
    return {...x, ...assetTypeData} as FilterType;
  });

  return {
    items: result,
    totalCount: data.count,
  };
};

const create: CreateFunction<FilterTypeModify> = async ({payload}, jwt?, cancelToken?) => {
  const config: AxiosRequestConfig = {
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };
  const {data} = await axios.post<CreateResult>("/filtertypes", payload, config);
  return data;
};

const update: UpdateFunction<FilterTypeModify> = async ({payload}, jwt?, cancelToken?) => {
  const {Id} = payload;
  const path = `/filtertypes/${Id}`;
  const config: AxiosRequestConfig = {
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };
  const {data} = await axios.put<UpdateResult>(path, payload, config);
  return data;
};

const read: ReadFunction<FilterType> = async (id, _childId, jwt?, cancelToken?) => {
  const path = `/filtertype/${id}`;
  const config: AxiosRequestConfig = {
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };
  const {data} = await axios.get<FilterTypeDto>(path, config);
  const compatibleAssetType: any = Array.isArray(data.CompatibleAssetTypes) && data.CompatibleAssetTypes.length > 0 ? data.CompatibleAssetTypes[0] : null;
  const assetTypeData = compatibleAssetType ? {CompatibleAssetTypeId: compatibleAssetType.Id ?? "", CompatibleAssetTypeName: compatibleAssetType.Name ?? ""} : {};
  return {
    item: {...data, ...assetTypeData} as FilterType,
  };
};

const deleteFilterType: DeleteFunction<FilterType> = async (id, jwt?, cancelToken?) => {
  const path = `/filtertypes/${id}`;
  const config: AxiosRequestConfig = {
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };
  await axios.delete(path, config);
};

const getFilterType = async (id: string) => {
  const path = `${id}`;
  const {data} = await axios.get(path, {});
  return data;
};

export const api = {
  query,
  getFilterType,
  deleteFilterType,
  read,
  update,
  create,
};
