import {useCallback, useState} from "react";
import type {Mode} from "../../components/general/types/Modify";
import {createDropdownOptions} from "../../helpers/dropdown-helpers";
import {useRole} from "../../hooks/authentication/useRole";
import {useToken} from "../../hooks/authentication/useToken";
import {paths} from "../../navigation/paths";
import {userStrings as strings} from "../../resources/strings/users";
import {api as clientsApi} from "../../services/clients.service";
import {api} from "../../services/users.service";
import type {RouteProps, ValidationConstraints} from "../../types";
import {User} from "../../types/documents/User";
import {ModifyContainer} from "../general/ModifyContainer";
import {UsersConfig} from "./UsersConfig";

interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;

export const UsersModify: Component = ({mode, ...routeProps}) => {
  const jwt = useToken();
  const userRole = useRole();

  const [clientIdRequired, setClientIdRequired] = useState<boolean>(true);
  const onClientIdRequireChange = (required: boolean) => {
    setClientIdRequired(required);
  };

  const getClients = useCallback(async () => {
    const clients: {[key: string]: any}[] = [{["Key"]: "1"}];
    if (!jwt) {
      return createDropdownOptions(clients, "Key", "1");
    }
    const items = await clientsApi.query(
      {
        size: 100000,
      },
      jwt
    );
    return createDropdownOptions(items.items, "Id", "Name");
  }, [jwt]);

  const constraintsCreate: ValidationConstraints<User> = {
    FirstName: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.firstName} is required`,
      },
    },
    LastName: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.lastName} is required`,
      },
    },
    Email: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.email} is required`,
      },
      email: {
        message: "'%{value}' is not a valid email.",
      },
    },
    Password: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.password} is required`,
      },
      b2cpassword: {
        active: true,
      },
    },
    Role: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.role} is required`,
      },
    },
    UserClientId: {
      presence: {
        allowEmpty: !clientIdRequired,
        message: `^${strings.labels.client} is required`,
      },
    },
  };

  const constraintsEdit: ValidationConstraints<User> = {
    FirstName: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.firstName} is required`,
      },
    },
    LastName: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.lastName} is required`,
      },
    },
    Email: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.email} is required`,
      },
      email: {
        message: "'%{value}' is not a valid email.",
      },
    },
    Role: {
      presence: {
        allowEmpty: false,
        message: `^${strings.labels.role} is required`,
      },
    },
    UserClientId: {
      presence: {
        allowEmpty: !clientIdRequired,
        message: `^${strings.labels.client} is required`,
      },
    },
  };

  return userRole !== "" ? (
    <div>
      <ModifyContainer<User>
        {...routeProps}
        api={api}
        mode={mode}
        initialData={{
          Id: "",
          FirstName: "",
          LastName: "",
          ClientId: "",
          Client: "",
          Email: "",
          Role: "",
          UserClientId: "",
          Password: "",
        }}
        componentConfiguration={(props) => UsersConfig(props, userRole, getClients, onClientIdRequireChange)}
        constraints={mode === "create" ? constraintsCreate : constraintsEdit}
        redirectPath={paths.users.list}
      />
    </div>
  ) : (
    <></>
  );
};
