import { AxiosRequestConfig } from "axios";
import axios from "../libs/axios/axios-task";
import {
  CreateFunction,
  CreateResult,
  QueryFunction,
  UpdateFunction,
} from "../types/API";
import { UserAlert, UserAlertFilters } from "../types/documents/UserAlert";

const query: QueryFunction<UserAlert> = async (
  { page = 0, size = 10, sort = [], query },
  jwt?,
  cancelToken?
) => {
  const filters = query as UserAlertFilters;
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const { data } = await axios.get<{ items: UserAlert[] }>(
    "/alerts/summary",
    config
  );
  return {
    items: data.items.filter((item) => item.AlertCategory === "User"),
    totalCount: data.items.length,
  };
};

const create: CreateFunction<UserAlert> = async (
  { payload },
  jwt?,
  cancelToken?
) => {
  //const id = payload.id;
  const path = `/create`;

  const { data } = await axios.post<CreateResult>(path, payload, {
    cancelToken,
  });
  return data;
};

const update: UpdateFunction<UserAlert> = async () => {
  return Promise.resolve({ id: "" });
};

const getUser = async (id: string) => {
  const path = `${id}`;
  const { data } = await axios.get(path, {});
  return data;
};

export const api = {
  query,
  getUser,

  create,
  update,
};
