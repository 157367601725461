import validate from "validate.js";
import {b2cpassword} from "./validators/b2cpassword";
import {regex} from "./validators/regex";

validate.validators = {
  ...validate.validators,
  regex,
  b2cpassword,
};

export default validate;
