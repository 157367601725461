import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {FormHelperText, IconButton, InputAdornment, SxProps, TextField, Theme} from "@mui/material";
import "../../../fonts/Extratype-Eina04-Regular.otf";
import "../../../fonts/Extratype-Eina04-SemiBold.otf";
import "../../../styles/App.css";
import type {ChangeHandler, FormInput} from "../types/Modify";

interface Props<D extends object> {
  config: Omit<FormInput<D>, "controltype">;
  children?: JSX.Element | JSX.Element[];
  search?: boolean;
  clearable?: boolean;
  multiline?: boolean;
  handleChange: ChangeHandler;
  handleClear?: () => void;
  handleClick?: () => void;
}

const inputStyles: SxProps<Theme> = {
  borderRadius: "5px",
  fontFamily: "T-Safe-Regular",
};

const searchInputStyles: SxProps<Theme> = {
  borderRadius: "5px",
  fontFamily: "T-Safe-Regular",
  width: "750px",
};

const backgroundStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
});

const selectStyles: SxProps<Theme> = {
  textAlign: "left",
};

const rootStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.light,
});

export const Input = <D extends object>({search, config: input, clearable, multiline, handleChange, handleClear, handleClick}: Props<D>): JSX.Element => {
  const {error, helperText, background = "lightgrey", ...config} = input;
  const value = config.value ?? "";
  const lightgreyBackground = background === "lightgrey";
  const computedInputStyles = [search ? searchInputStyles : inputStyles, lightgreyBackground && backgroundStyles];

  const handleCancelButtonClick = (event: any) => {
    event.stopPropagation();
    if (handleClear) handleClear();
  };

  const getAdornment = () => {
    if (search) {
      return (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      );
    } else if (clearable) {
      return (
        <InputAdornment position="end">
          <IconButton onClick={(e) => handleCancelButtonClick(e)}>
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      );
    }
    return <></>;
  };

  return (
    <>
      <TextField
        multiline={multiline === true}
        {...config}
        error={error}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        label={config.label}
        InputLabelProps={{
          htmlFor: config.name,
          shrink: config.label !== null && config.label !== undefined && config.label !== "",
        }}
        InputProps={{
          ...config.InputProps,
          id: config.name,
          title: config.name,
          sx: [...computedInputStyles],
          endAdornment: getAdornment(),
        }}
        SelectProps={{
          ...config.SelectProps,
          sx: [selectStyles, ...computedInputStyles],
        }}
        sx={[rootStyles]}
        variant="outlined"
        size="small"
      />
      <FormHelperText error={error} children={helperText} />
    </>
  );
};
