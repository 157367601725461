import {ClientBreadcrumbHeader} from "../../components/client/clientBreadcrumbHeader";
import {Mode} from "../../components/general/types/Modify";
import {createDropdownOptions} from "../../helpers/dropdown-helpers";
import {paths} from "../../navigation/paths";
import {countryList} from "../../resources/countryList";
import {clientStrings as strings} from "../../resources/strings/client";
import {api} from "../../services/clients.service";
import type {RouteProps, ValidationConstraints} from "../../types";
import { BespokeFilterType } from "../../types/documents/BespokeFilterType";
import {Client, ClientSort} from "../../types/documents/Client";
import {ModifyContainer} from "../general/ModifyContainer";
import { TableContainer } from "../general/TableContainer";
import {api as filterTypeAPI} from "../../services/bespokeFilterTypes.service";
import { Input } from "../../components/general/controls/Input";
import { Button, TextField } from "@mui/material";
import CustomReplacementPeriodCell from "./CustomReplacementPeriodCell";
import BespokeFilterTypeList from "./BespokeFilterTypeList";

export interface Props extends RouteProps {
  mode: Mode;
}

type Component = (props: Props) => JSX.Element;

const constraints: ValidationConstraints<Client> = {
  Name: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.name} is required`,
    },
  },
  Address1: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.address1} is required`,
    },
  },
  City: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.city} is required`,
    },
  },
  Postcode: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.postcode} is required`,
    },
  },
  Country: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.country} is required`,
    },
  },
};

const countries = createDropdownOptions(countryList, "name", "name");

export const ClientModify: Component = ({mode, ...routeProps}) => {
  return (
    <div>
    <ModifyContainer<Client>
      {...routeProps}
      api={api}
      mode={mode}
      initialData={{
        Id: "",
        Name: "",
        Address1: "",
        Address2: "",
        City: "",
        Region: "",
        Postcode: "",
        Country: "",
        Active: true,
      }}
      componentConfiguration={[
        {
          key: "header",
          content: [
            {
              controltype: "custom",
              Component: ClientBreadcrumbHeader,
              md: 12,
              control: false,
            },
          ],
        },
        {
          key: "clientDetails",
          content: [
            {
              controltype: "header",
              text: strings.header.itemDetails,
            },
            {
              controltype: "input",
              name: "Name",
              label: strings.labels.name,
            },
            {
              controltype: "input",
              name: "Address1",
              label: strings.labels.address1,
            },
            {
              controltype: "input",
              name: "Address2",
              label: strings.labels.address2,
            },
            {
              controltype: "input",
              name: "City",
              label: strings.labels.city,
            },
            {
              controltype: "input",
              name: "Region",
              label: strings.labels.region,
            },
            {
              controltype: "dropdown",
              name: "Country",
              label: strings.labels.country,
              options: countries,
            },
            {
              controltype: "input",
              name: "Postcode",
              label: strings.labels.postcode,
            },
          ],
        },
      ]}
      constraints={constraints}
      redirectPath={paths.client.list}
    />
    <BespokeFilterTypeList />
    </div>
  );
};

export default ClientModify;
