import { AxiosRequestConfig } from "axios";
import axios from "../libs/axios/axios-audit-trail";
import { QueryFunction } from "../types/API";
import {
  AuditTrail,
  AuditTrailFilters,
} from "../types/documents/AuditTrail";

const query: QueryFunction<AuditTrail> = async (
  { page = 0, size = 10, sort = [], query },
  jwt?,
  cancelToken?
) => {
  
  const filters = query as AuditTrailFilters;
  //Remove appended time zone information from dates to get in correct format
  if (filters.StartDate) {
    filters.StartDate = filters.StartDate.split("+")[0] + "Z";
  }
  if (filters.EndDate) {
    filters.EndDate = filters.EndDate.split("+")[0] + "Z";
  }
  const config: AxiosRequestConfig = {
    params: {
      page: page + 1,
      size,
      ...filters,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const { data } = await axios.get<{ Results: AuditTrail[], Count: number }>(
    ``,
    config
  );

  return {
    items: data.Results,
    totalCount: data.Count,
  };
};


export const api = {
  query,
};
