import { Circle } from "@mui/icons-material";
import {CircularProgress, Grid, SxProps, Theme} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CreateButton } from "../../components/common/CreateButton";
import { SearchBar } from "../../components/common/SearchBar";
import { TaskStatusColors } from "../../components/task/taskConfig";
import { formatDateTime } from "../../helpers/date-helpers";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { usePalette } from "../../hooks/general/usePalette";
import { useSessionStorage } from "../../hooks/general/useSessionStorage";
import paths from "../../navigation/paths";
import {taskStrings as strings} from "../../resources/strings/tasks";
import {api} from "../../services/tasks.service";
import {DropOption, FilterChangeHandler, RouteProps} from "../../types";
import {type Task, type TaskFilters} from "../../types/documents/Task";
import {TableContainer} from "../general/TableContainer";
import { Dropdown } from "../../components/general/controls/Dropdown";
import { Button } from "../../components/general/Button";
import { QueryBody } from "../../types/API";
import { User } from "../../types/documents/User";
import { api as usersApi } from "../../services/users.service";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { ChangeHandler } from "../../components/general/types/Modify";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { DatePicker } from "../../components/general/controls/DatePicker";
import { LocationSelectBox } from "../../components/general/controls/LocationSelectBox";
import Checkbox from "@mui/material/Checkbox";
import { useToken } from "../../hooks/authentication/useToken";
import { useClientContext } from "../../context/ClientContext";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

type LocationDetails = {
  selectedLocation: {
    LocationId: string
  }
}


interface HeaderContentProps {
  displayDropdown: boolean;
  setDisplayDropdown: (value: boolean) => void;
  handleAllocate: () => void;
  selectedUser: string;
  setSelectedUser: (value: string) => void;
  userOptions: DropOption[];
  loading: boolean;
}

const rootStyles: SxProps<Theme> = (theme) => ({
  boxShadow: "none",
  fontWeight: "bolder",
  minWidth: theme.spacing(16),
  margin: theme.spacing(0, 1),
  borderRadius: "20px",
  backgroundColor: "#2643D6",
});

const cancelButtonStyles: SxProps<Theme> = (theme) => ({
  color: "grey",
  backgroundColor: "white",
});



const HeaderContent = ({ displayDropdown, setDisplayDropdown, handleAllocate, selectedUser, setSelectedUser, userOptions, loading }: HeaderContentProps) => {
  return (
    <>
      {displayDropdown
        ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: "1px solid #ccc", borderRadius: "7px", padding: "7px", marginBottom: "7px" }}>
            <Dropdown
              config={{ name: "", label: strings.labels.selectUser, value: selectedUser, options: userOptions, defaultValue: "none", style: { width: "200px"} }}
              handleChange={(event) => { setSelectedUser(event.target.value as string) }}
            />
            <Button label={strings.labels.cancel} onClick={() => { setDisplayDropdown(false) }} variant="outlined" sx={[cancelButtonStyles]}></Button>
            <Button label={loading ? <CircularProgress  size={25}/> : strings.labels.saveUserAllocation} onClick={handleAllocate} disabled={loading} sx={[rootStyles]} ></Button>
          </div>
        : 
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: "7px", padding: "7px", marginBottom: "7px" }}>
          <Button label={strings.labels.allocateTasks} onClick={() => { setDisplayDropdown(true) }} sx={[rootStyles]}></Button>
        </div>}
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: "7px", padding: "7px", marginBottom: "7px" }}>
      <CreateButton createPath={paths.tasks.create} label={strings.labels.createTask} />
    </div>
    </>
  );
};

const TasksList: Component = ({ ...routeProps }) => {
  const [filters, setFilters] = useSessionStorage<TaskFilters>({}, "taskFilters");
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [selectedUser, setSelectedUser] = useState("none");
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [userOptions, setUserOptions] = useState<DropOption[]>([]);
  const [loading, setLoading] = useState(false);
  const {clientId} = useClientContext();
  const tasksRef = useRef<Task[]>([]); // Ref to store tasks

  const cancelToken = useCancelToken();
  const jwt = useToken();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!jwt) return;
        const body: QueryBody<User> = {page: 0, size: 1000}; //Hardcoded size to allow for all users being fetched
        const response = await usersApi.query(body, jwt, cancelToken);
        const options = createDropdownOptions(response.items, "Id", "DisplayName");
        options.unshift({ label: "Unassigned", value: "null" });
        setUserOptions(options);
      } catch (error) {
        console.error(error);
      }
    };
    setDisplayDropdown(false);
    fetchUsers();
  }, [jwt, cancelToken, clientId]);
  useEffect(() => {
  }, [loading]);

  useEffect(() => {
    setSelectedTasks(() => {
      if (selectedUser === "none") {
        return [];
      } else {
        const userTasks = tasksRef.current.filter(task => task.AssignedToId === selectedUser).map(task => task.Id);
        return userTasks;
      }
    });
  }, [selectedUser]);

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const handleAllocate = async () => {
    setLoading(true);
    try {
      const deallocatedTasks = tasksRef.current.filter(task => !selectedTasks.includes(task.Id)).map(task => task.Id);
      await api.allocateTasks({ userId: selectedUser, allocatedTaskIds: selectedTasks, deallocatedTaskIds:  deallocatedTasks}, jwt, cancelToken);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setSelectedTasks([]);
      setDisplayDropdown(false);
      //refresh page so that the user can see the changes
      window.location.reload();
      
    }
  };

  const clearFilters = () => setFilters({});

  const handleCheckboxChange = (id: string, event: any) => {
    event.stopPropagation();
    setSelectedTasks((prevSelectedTasks) => {
      if (event.target.checked) {
        return [...prevSelectedTasks, id];
      } else {
        return prevSelectedTasks.filter((taskId) => taskId !== id);
      }
    });
  };

  const tableFilters = useMemo(() => ({ ...filters }), [filters]);
  const history = useNavigate();

  const additionalFilterChangeHandler: ChangeHandler = (event) => {
    const target = event.target;
    console.log(target.name)
    if (target.name) {
      handleFilterChange(target.name, target.value);
    }
  };

  const locationFilterChangeHandler = (event: any, locationDetails: LocationDetails) => {
    console.log(locationDetails)
    handleFilterChange("locationId", locationDetails.selectedLocation.LocationId);
  }

  const renderAdditionalFilters = () => {
    return (
      <>
        <Grid item xs={2}>
          <BaseControl control={false}>
            <DatePicker
              config={{
                name: "From",
                placeholder: strings.placeholders.select,
                label: strings.labels.from,
                value: filters.From ?? "",
              }}
              handleChange={additionalFilterChangeHandler}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={2}>
          <BaseControl control={false}>
            <DatePicker
              config={{
                name: "To",
                placeholder: strings.placeholders.select,
                label: strings.labels.to,
                value: filters.To ?? "",
              }}
              handleChange={additionalFilterChangeHandler}
            />
          </BaseControl>
        </Grid>
        <Grid item xs={4}>
          <BaseControl control={false}>
            <LocationSelectBox 
              config={{ name: "locationId", value: filters.locationId }} 
              mode={"view"} 
              handleChange={locationFilterChangeHandler} 
            />
          </BaseControl>  
        </Grid>
      </>
    );
  };

  return (
    <div>
      <TableContainer<Task>
        {...routeProps}
        api={api}
        tableColumns={[
          ...(displayDropdown ? [{
            id: "BulkAssign",
            Header: strings.labels.bulkAssign,
            accessor: (task: Task) => {
              tasksRef.current = [...tasksRef.current, task]; 
              return <Checkbox id={task.Id} checked={selectedTasks.includes(task.Id)} onChange={(event) => handleCheckboxChange(task.Id, event)} onClick={(event) => handleCheckboxChange(task.Id, event)}/>
            },
          }] : []),
          {
            id: "DueDate",
            Header: strings.labels.dueDate,
            accessor: ({ DueDate }) => {
              return <p>{formatDateTime(DueDate)}</p>;
            },
          },
          {
            id: "status",
            Header: strings.labels.status,
            accessor: ({ TaskStatus }) => {
              if (TaskStatus === undefined) {
                return <></>;
              }
              return (
                <Tooltip title={strings.statusName[TaskStatus]} arrow>
                  <Circle htmlColor={TaskStatusColors[TaskStatus]} />
                </Tooltip>
              );
            },
          },
          {
            id: "TaskType",
            Header: strings.labels.taskType,
            accessor: "TaskTypeName",
          },
          {
            id: "TaskLocation",
            Header: strings.labels.taskLocation,
            accessor: "LocationName",
          },
          {
            id: "TaskName",
            Header: strings.labels.taskNotes,
            accessor: "Name",
          },
          {
            id: "AssignedTo",
            Header: strings.labels.assignedTo,
            accessor: "AssignedToName",
          },
        ]}
        header={strings.header.taskList}
        headerContent={() => <HeaderContent displayDropdown={displayDropdown} setDisplayDropdown={setDisplayDropdown} handleAllocate={handleAllocate} selectedUser={selectedUser} setSelectedUser={setSelectedUser} userOptions={userOptions} loading={loading}/>}
        children={
          <SearchBar filters={filters} handleFilterChange={handleFilterChange} clearFilters={clearFilters} tags={{}} additionalFilterComponents={renderAdditionalFilters()} />
        }
        manualPagination={true}
        filters={tableFilters as any}
        clickHandler={(task) => history(paths.tasks.modify(task.Id?.toString()))}
      ></TableContainer>
    </div>
  );
};

export default TasksList;