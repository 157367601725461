export const appStrings = {
  entities: {
    home: "Home",
    clients: "Clients",
    users: "Users",
    locations: "Locations",
    tasks: "Tasks",
    assets: "Assets",
    filterTypes: "Filter Types",
    import: "Import",
  },
  permissions: {
    read: "View",
    create: "Create",
    update: "Update",
    access: "Access",
    delete: "Delete",
    viewList: "ViewList",
    createAdhocJob: "CreateAdhocJob",
    createForAnyUser: "CreateForAnyUser",
    godMode: "GodMode",
    copy: "Copy",
    publish: "Publish",
    export: "Export",
    import: "Import",
  },
  menuLabels: {
    home: "Home",
    clients: "Clients",
    users: "Users",
    locations: "Locations",
    tasks: "Tasks",
    assets: "Assets",
    filterTypes: "Filters Register",
    auditTrail: "Audit Trail",
    import: "Import",
    documents: "Documents",
  },
  labels: {
    signOut: "Sign Out",
    signIn: "Login",
    loading: "Loading...",
    defaultSelect: "- select -",
    export: "Export",
    exportTooLarge: "Your search is too large for export, please refine your search further",
    organisationId: "Client",
    siteId: "Site",
    contactId: "Contact",
    noOptionsFound: "No options found",
    uploadFile: "Drop files here to upload (or click)",
    close: "Close",
    selectCategory: "Select Category",
    selectVisibleOnMobile: "Visible on Mobile",
  },
  errors: {
    fileTooLarge: (fileName: string) => `"${fileName}" is too large for upload.`,
    invalidFileType: (fileName: string) => `"${fileName}" file type is not permitted for upload.`,
  },
  notifications: {
    type: {success: "Success", error: "Error", info: "Info"},
    messages: {
      invalidFields: "Form contains missing or invalid content",
      anErrorOccurred: "An error occurred while submitting the data.",
      submitted: "Data submitted successfully.",
      deleted: "Data successfully deleted.",
    },
  },
};
