import {AxiosRequestConfig, CancelToken} from "axios";
import {getDateOnlyStringFromDateTimeString} from "../helpers/date-helpers";
import axios from "../libs/axios/axios-task";
import {CreateFunction, CreateResult, DeleteFunction, DownloadFunction, QueryFunction, ReadFunction, UpdateFunction, UpdateResult} from "../types/API";
import {Task, TaskFilters} from "../types/documents/Task";
import {TaskSchedule, TaskScheduleFilters} from "../types/documents/TaskSchedule";
import { de } from "date-fns/locale";

const query: QueryFunction<Task> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const filters = query as TaskFilters;
  const {id, desc} = sort[0] ?? {};

  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      dateFrom: filters.From ? getDateOnlyStringFromDateTimeString(filters.From!) : "",
      dateTo: filters.To ? getDateOnlyStringFromDateTimeString(filters.To!) : "",
      active: "true",
      page: page + 1,
      size,
      sort: id,
      order: desc ? "desc" : "asc",
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const {data} = await axios.get<{items: Task[]; totalCount: number}>("", config);
  return {
    items: data.items,
    totalCount: data.totalCount,
  };
};

const read: ReadFunction<Task> = async (id, _childId, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get<{item: Task}>(path, config);
  return {
    item: {
      ...data.item,
    },
  };
};

const create: CreateFunction<Task> = async ({payload}, jwt?, cancelToken?) => {
  const path = ``;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const createPayload = {
    Name: payload.Name,
    CompletedDate: payload.CompletedDate,
    ClientId: payload.ClientId,
    DueDate: payload.DueDate,
    TaskTypeId: payload.TaskTypeId,
    LocationId: payload.LocationId,
    AssetId: payload.AssetId,
  };

  const {data} = await axios.post<CreateResult>(path, createPayload, config);
  return data;
};

const update: UpdateFunction<Task> = async ({payload}, jwt?, cancelToken?) => {
  console.log("Update Task at API");
  let {Id, Name, DueDate, TaskTypeId, LocationId, AssignedToId, ClientId, AssetId, CompletedDate} = payload;
  const path = `/${Id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  console.log("Update Task call API");
  if (AssignedToId === "null") {
    AssignedToId = null;
  }
  const {data} = await axios.put<UpdateResult>(
    path,
    {
      Name,
      DueDate,
      TaskTypeId,
      AssetId,
      LocationId,
      AssignedToId,
      ClientId,
      Active: "true",
      CompletedDate,
    },
    config
  );
  return data;
};

const deleteTask: DeleteFunction<Task> = async (id, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  await axios.delete(path, config);
};

const getTaskTypes = async (jwt: string, cancelToken: CancelToken) => {
  const path = `/tasktypes`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data;
};

const getTaskSchedule: QueryFunction<TaskSchedule> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const path = `/schedule/summary`;
  const filters = query as TaskScheduleFilters;
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const {data} = await axios.get<{
    items: TaskSchedule[];
    totalCount: number;
  }>(path, config);
  return {
    items: data.items,
    totalCount: data.items.length,
  };
};

const getWorkStatus = async (jwt: string, cancelToken: CancelToken, payload: {days?: number}) => {
  const path = `/status/summary`;
  const config: AxiosRequestConfig = {
    params: {
      days: payload.days,
    },

    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data;
};

const downloadAlertStatus: DownloadFunction = async ({locationId = "", format = ""}, jwt?, cancelToken?) => {
  const config: AxiosRequestConfig = {
    params: {
      format: format,
      location: locationId,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const path = "/alerts/export";
  const {data} = await axios.get(path, config);
  return data;
};

const downloadWorkStatus: DownloadFunction = async ({locationId = "", format = "", days}, jwt?, cancelToken?) => {
  const config: AxiosRequestConfig = {
    params: {
      format: format,
      location: locationId,
      days: days,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const path = "/work-status/export";
  const {data} = await axios.get(path, config);
  return data;
};

const downloadTaskSchedule: DownloadFunction = async ({locationId = "", format = "", start, end, types}, jwt?, cancelToken?) => {
  const config: AxiosRequestConfig = {
    params: {
      format: format,
      location: locationId,
      start: start,
      end: end,
      types: types,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const path = "/schedule/export";
  const {data} = await axios.get(path, config);
  return data;
};

const getScheduleSummary = async (jwt: string, cancelToken: CancelToken, payload: {start?: string; end?: string; types?: string; location?: string}) => {
  const path = `/schedule/summary`;
  const config: AxiosRequestConfig = {
    params: {
      start: payload.start,
      end: payload.end,
      types: payload.types,
      location: payload.location,
    },

    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data;
};

const allocateTasks = async (payload: {userId: string, allocatedTaskIds: string[], deallocatedTaskIds: string[]}, jwt: string, cancelToken: CancelToken) => {
  const path = `/bulk-allocate`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt,
    },
    cancelToken,
  };
  const allocatedToId = payload.userId === "null" ? null : payload.userId;
  const allocationPayload = {
    userId: allocatedToId,
    //remove duplicates from allocatedTaskIds
    allocatedTaskIds: Array.from(new Set(payload.allocatedTaskIds)),

    //remove duplicates from deallocatedTaskIds
    deallocatedTaskIds: Array.from(new Set(payload.deallocatedTaskIds)),
  }
  const {data} = await axios.put(path, allocationPayload, config);
  return data;
};

export const api = {
  query,
  read,
  update,
  create,
  deleteTask,
  getTaskTypes,
  getTaskSchedule,
  getWorkStatus,
  downloadAlertStatus,
  downloadWorkStatus,
  downloadTaskSchedule,
  getScheduleSummary,
  allocateTasks,
};
