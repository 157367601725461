import TreeItem, {TreeItemContentProps, TreeItemProps, useTreeItem} from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {Mode} from "../../../components/general/types/Modify";
import paths from "../../../navigation/paths";
import {RouteProps} from "../../../types";

interface Props extends RouteProps {
  parentId: string;
  nodeId: string;
  label: string;
  mode: Mode;
  setSelectedLocation: Function | undefined;
}
type Component = (props: Props) => JSX.Element;

const RoomTreeItem: Component = ({mode, setSelectedLocation, ...routeProps}) => {
  const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const history = useNavigate();
    const {classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon} = props;
    const {disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection} = useTreeItem(nodeId);
    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleSelection(event);
      if (props.nodeId === "addRoom") {
        history(paths.room.create(routeProps.nodeId));
      } else if (mode === "view" && setSelectedLocation !== undefined) {
        const locationId = routeProps.nodeId;
        const locationName = routeProps.label;
        const locationDepth = 4;
        setSelectedLocation(locationId, locationName, locationDepth);
      } else if (mode !== "view") {
        history(paths.room.modify(props.nodeId));
      } else {
        console.log("SetSelectedLocation function is required in view mode");
        throw new Error("SetSelectedLocation function is required in view mode");
      }
    };

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
          {label}
        </Typography>
      </div>
    );
  });

  function CustomTreeItem(props: TreeItemProps) {
    return <TreeItem ContentComponent={CustomContent} {...props} />;
  }

  return (
    <>
      <CustomTreeItem nodeId={routeProps.nodeId} label={routeProps.label}></CustomTreeItem>
    </>
  );
};

export default RoomTreeItem;
