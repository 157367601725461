import React, { useRef, useState } from 'react';
import { Input } from '../../components/general/controls/Input';
import {api} from '../../services/bespokeFilterTypes.service';
import { useToken } from '../../hooks/authentication/useToken';
import { notifications } from '../../libs/notifications';
import { clientStrings as strings } from '../../resources/strings/client';

const CustomReplacementPeriodCell = (value: any) => {
  const [customVal, setCustomVal] = useState(value.CustomReplacementPeriod);
  const jwt = useToken();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlur = async (e: any) => {
    try {

      if (e.target.value > value.defaultReplacementPeriod  || e.target.value <= 0) {
        notifications.error(strings.text.customReplacementPeriodOutOfRange);
        return;
      }
      setCustomVal(e.target.value);
      if (inputRef.current) {
        inputRef.current.blur(); 
      }
      const response = await api.setCustomReplacementPeriod(value.id, customVal, jwt);

      if (response && response !== null) {
        notifications.success(strings.text.customReplacementPeriodSuccess);
      } else {
        notifications.error(strings.text.customReplacementPeriodError);
      }

    } catch (error) {
      notifications.error(strings.text.customReplacementPeriodError);
    }
  };

  return (
    <div onBlur={handleBlur} onKeyDown={(e) => e.key === "Enter" && handleBlur(e)}>
      <Input
        config={{
          name: "CustomReplacementPeriod",
          placeholder: value.value,
          value: customVal ?? value.value,
        }}
        handleChange={(e) => setCustomVal(e.target.value)}
        
        search={false}
      />
    </div>
  );
};

export default CustomReplacementPeriodCell;