import {AxiosRequestConfig, CancelToken} from "axios";
import axios from "../libs/axios/axios-asset";
import {CreateFunction, CreateResult, DeleteFunction, DownloadFunction, QueryFunction, ReadFunction, UpdateFunction, UpdateResult} from "../types/API";
import {Asset, AssetFilters, AssetType} from "../types/documents/Asset";

const query: QueryFunction<Asset> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const filters = query as AssetFilters;
  const path = "";
  const {id, desc} = sort[0] ?? {};
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      active: "true",
      page: page + 1,
      size,
      sort: id,
      order: desc ? "desc" : "asc",
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  type AssetListResult = {
    assets: Asset[];
    count: number;
  };
  const {data} = await axios.get<AssetListResult>(path, config);
  return {
    items: data.assets,
    totalCount: data.count,
  };
};

const read: ReadFunction<Asset> = async (id, _childId, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get<Asset>(path, config);
  return {
    item: {...data},
  };
};

const create: CreateFunction<Asset> = async ({payload}, jwt?, cancelToken?) => {
  const path = "";
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {Notes, AssetBarcode, LocationId, AssetTypeId} = payload;
  const {data} = await axios.post<CreateResult>(
    path,
    {
      Notes,
      AssetBarcode,
      LocationId,
      AssetTypeId,
      Active: "true",
    },
    config
  );
  return data;
};

const update: UpdateFunction<Asset> = async ({payload}, jwt?, cancelToken?) => {
  const {Id} = payload;
  const path = `/${Id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.put<UpdateResult>(path, payload, config);
  return data;
};

const deleteAsset: DeleteFunction<Asset> = async (id, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  await axios.delete(path, config);
};

const download: DownloadFunction = async ({locationId = "", format = ""}, jwt?, cancelToken?) => {
  const path = "/installed-filters/export";
  const config: AxiosRequestConfig = {
    params: {
      format: format,
      location: locationId,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data;
};

const getAssetTypes: QueryFunction<AssetType> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const filters = query as AssetFilters;
  const path = "/assettypes";
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      active: "true",
      page: page + 1,
      size,
    },
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };

  type AssetTypeResult = {
    assettypes: AssetType[];
    count: number;
  };
  const {data} = await axios.get<AssetTypeResult>(path, config);
  return {
    items: data.assettypes,
    totalCount: data.count,
  };
};

const getInstalledFiltersSummary = async (jwt: string, cancelToken: CancelToken, payload: {location?: string}) => {
  const path = `/filters/installed/summary`;
  const config: AxiosRequestConfig = {
    params: {
      location: payload.location,
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data;
};

const getAssetsForLocation = async (jwt: string, Id: string, cancelToken?: CancelToken) => {
  const path = `/location/${Id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  const {data} = await axios.get(path, config);
  return {
    items: data,
  };
};

export const api = {
  query,
  read,
  update,
  create,
  deleteAsset,
  download,
  getAssetTypes,
  getInstalledFiltersSummary,
  getAssetsForLocation,
};
