import {Clear as CloseIcon} from "@mui/icons-material";
import type {SxProps, Theme} from "@mui/material";
import {Box, Grid, Modal as MuiModal, styled, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Button} from "../../Containers/general/Button";
import {IconAction} from "../../Containers/general/IconAction";
import "../../fonts/Extratype-Eina04-SemiBold.otf";
import {appStrings as strings} from "../../resources/strings/app";

type TActions = (props: ActionProps) => JSX.Element[];

interface Props {
  header: string;
  trigger?: JSX.Element;
  isOpen?: boolean; //Use if no trigger is set, to directly control whether the modal is open or shut
  setIsOpen?: Function; //Use if no trigger is set, to track the state of the modal
  paddedContent?: boolean;
  children: JSX.Element;
  locationSelect?: boolean;
  actions?: TActions;
  md?: number;
}
type Component = (props: Props) => JSX.Element;

interface HeaderProps {
  onClose: () => void;
  header: Props["header"];
}

interface BodyProps {
  children: Props["children"];
  paddedContent: Props["paddedContent"];
}

interface ActionProps {
  onClose: () => void;
}

interface ModalActionProps extends ActionProps {
  actions: TActions;
}

const spacerStyles: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(2),
});

const headerStyles: SxProps<Theme> = (theme) => ({
  borderRadius: 2,
  backgroundColor: theme.palette.background.paper,
  color: "#666666",
});

const headStyles: SxProps<Theme> = (theme) => ({
  fontSize: "x-large",
  fontFamily: "T-Safe-SemiBold",
});

const containerStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
});

const containerStylesLocation: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  height: "460px",
  width: "700px",
});

const ModalHeader = ({onClose, header}: HeaderProps) => {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={[spacerStyles, headerStyles]}>
      <Grid item>
        <Typography sx={[headStyles]}>{header}</Typography>
      </Grid>
      <Grid item>
        <IconAction tooltip="" onClick={onClose}>
          <CloseIcon sx={{color: "#666666"}} data-testid="close-icon" />
        </IconAction>
      </Grid>
    </Grid>
  );
};

const Span = styled("span")({});

const ModalBody = ({children, paddedContent = false}: BodyProps) => {
  return <Span sx={[paddedContent && spacerStyles]}>{children}</Span>;
};

const ModalActions = ({onClose, actions}: ModalActionProps) => {
  const actionsToRender = actions({onClose});
  return (
    <Grid container justifyContent="flex-end" sx={[spacerStyles]}>
      {actionsToRender.map((action, index) => (
        <Grid item key={index}>
          {action}
        </Grid>
      ))}
    </Grid>
  );
};

const DefaultActions = ({onClose}: ActionProps) => [<Button label={strings.labels.close} onClick={onClose} />];

export const Modal: Component = ({isOpen, setIsOpen, header, trigger, paddedContent = true, children, locationSelect, actions = DefaultActions, md = 6}) => {
  const [open, setOpen] = useState(false);
  const onOpen = () => {
    setOpen(true);
    if (setIsOpen !== undefined) setIsOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    if (setIsOpen !== undefined) setIsOpen(false);
  };
  const BodyContent = children;

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);
    }
  }, [setOpen, isOpen]);

  return (
    <>
      <MuiModal open={open}>
        <Box display={"flex"} height="100vh" width="100vw" justifyContent="center" alignItems={"center"}>
          <Grid item xs={12} md={md} sx={[locationSelect ? containerStylesLocation : containerStyles]}>
            <ModalHeader onClose={onClose} header={header} />
            <ModalBody children={BodyContent} paddedContent={paddedContent} />
            <ModalActions onClose={onClose} actions={actions} />
          </Grid>
        </Box>
      </MuiModal>
      {trigger !== undefined && <span onClick={onOpen}>{trigger}</span>}
    </>
  );
};
