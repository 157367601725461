import type { SxProps, Theme } from "@mui/material";
import { Link as UILink, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import type { Link as TLink } from "../types/Modify";

interface Props {
  config: Omit<TLink, "controltype">;
  linkColor?: string;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = {
  "&:hover": {
    cursor: "pointer",
  },
};

export const Link: Component = ({ config, linkColor }) => {
  const { text = "", to = "", href } = config;

  const renderLink = () => {
    if (to) {
      return (
        <UILink
          component={RouterLink}
          to={to}
          color={"#000000"}
          underline="always"
          sx={{ color: "#000000", fontSize: 15 }}
        >
          {text}
        </UILink>
      );
    }
    return (
      <UILink
        color="secondary"
        underline="always"
        href={href}
        sx={{ color: "#000000", fontSize: 15 }}
      >
        {text}
      </UILink>
    );
  };

  return (
    <Typography component={"span"} {...config} sx={[rootStyles]}>
      {renderLink()}
    </Typography>
  );
};
