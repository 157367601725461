import type {Validator} from "./Validator";

export type RegexOptions = {
  patterns: string[];
  messages: string[];
};

export const regex: Validator<RegexOptions> = (value, {patterns, messages}, _key, _attributes) => {
  const outputMessages: string[] = [];

  for (const index in patterns) {
    const pattern = patterns[index];
    const message = messages[index];
    if (!value) continue;
    const regExp = new RegExp(pattern);
    const result = regExp.test(value);
    if (result) continue;
    outputMessages.push(message);
  }
  if (outputMessages.length) {
    return outputMessages.join(", ");
  }
  return;
};
