import {useCallback, useState} from "react";
import type {Config, ConfigurationProps, ContentConfig, Mode} from "../../components/general/types/Modify";
import {TaskBreadcrumbHeader} from "../../components/task/TaskBreadcrumbHeader";
import {createDropdownOptions} from "../../helpers/dropdown-helpers";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {taskStrings as strings} from "../../resources/strings/tasks";
import {api as assetsApi} from "../../services/assets.service";
import {api as usersApi} from "../../services/users.service";
import {DropdownOptions} from "../../types";
import {QueryBody} from "../../types/API";
import {Task} from "../../types/documents/Task";
import {User} from "../../types/documents/User";

const headerConfig: Config<Task> = [
  {
    key: "header",
    content: [
      {
        controltype: "custom",
        Component: TaskBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
];

const taskContent = (mode: Mode, taskData: Task, getTaskTypes: any, getAssets: any, getUsers: any, assetOptions: DropdownOptions | undefined): ContentConfig<Task>[] => {
  const commonConfig: ContentConfig<Task>[] = [
    {
      controltype: "header",
      text: strings.header.taskDetails,
    },
    {
      controltype: "dropdown",
      name: "TaskTypeId",
      label: strings.labels.taskType,
      options: getTaskTypes,
    },
    {
      controltype: "datepicker",
      name: "DueDate",
      label: strings.labels.dueDate,
    },
    {
      controltype: "locationselector",
      name: "LocationId",
      label: strings.labels.location,
    },
    {
      controltype: "dropdown",
      name: "AssetId",
      label: strings.labels.asset,
      options: getAssets,
      disabled: taskData.LocationId === "" || assetOptions?.length === 0,
    },
    {
      controltype: "dropdown",
      name: "AssignedToId",
      label: strings.labels.assignedTo,
      options: getUsers,
    },
    {
      controltype: "input",
      name: "Name",
      label: strings.labels.taskNotes,
    },
  ];

  const createConfig: ContentConfig<Task>[] = [];
  const updateConfig: ContentConfig<Task>[] = [
    {
      controltype: "datepicker",
      name: "CompletedDate",
      label: strings.labels.completedDate,
    },
  ];
  const config = mode === "create" ? [...commonConfig, ...createConfig] : [...commonConfig, ...updateConfig];
  return config;
};

export const TasksConfig = (props: ConfigurationProps<Task>, jwt: string, getTaskTypes: any): Config<Task> => {
  const {data, mode} = props;
  const [currentAssetOptions, setCurrentAssetOptions] = useState<DropdownOptions>();
  type ListItems = {[key: string]: any}[];
  const cancelToken = useCancelToken();

  const getAssets = useCallback(async () => {
    const assets: ListItems = [{Key: "1"}];
    if (!jwt) {
      return createDropdownOptions(assets, "Key", "1");
    }
    const items = await assetsApi.getAssetsForLocation(jwt, data.LocationId, cancelToken);
    const assetOptions = createDropdownOptions(items.items, "Id", "AssetBarcode");
    setCurrentAssetOptions(assetOptions);
    return assetOptions;
  }, [jwt, data.LocationId]);

  const getUsers = useCallback(async () => {
    const users: ListItems = [{Key: "1"}];
    if (!jwt) {
      return createDropdownOptions(users, "Key", "1");
    }
    const body: QueryBody<User> = {page: 0, size: 1000};
    const items = await usersApi.query(body, jwt, cancelToken);
    const userOptions = createDropdownOptions(items.items, "Id", "DisplayName");
    userOptions.unshift({value: "null", label: strings.labels.unassigned});
    setCurrentAssetOptions(userOptions);
    return userOptions;
  }, [jwt, data.LocationId]);

  const taskInfoConfig = [
    {
      key: "fields",
      content: taskContent(mode, data, getTaskTypes, getAssets, getUsers, currentAssetOptions),
    },
  ];

  return [...headerConfig, ...taskInfoConfig];
};
