import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {CreateButton} from "../../components/common/CreateButton";
import {SearchBar} from "../../components/common/SearchBar";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import paths from "../../navigation/paths";
import {filterTypeStrings as strings} from "../../resources/strings/filterTypes";
import {api} from "../../services/filterTypes.service";
import {FilterChangeHandler, RouteProps} from "../../types";
import type {FilterTypeFilters} from "../../types/documents/FilterType";
import {FilterType} from "../../types/documents/FilterType";
import {TableContainer} from "../general/TableContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const FilterTypesList: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<FilterTypeFilters>({}, "filterTypeFilters");

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const cancelToken = useCancelToken();
  const history = useNavigate();

  return (
    <div>
      <TableContainer<FilterType>
        {...routeProps}
        api={api}
        tableColumns={[
          {
            id: "name",
            Header: strings.labels.type,
            accessor: "Name",
          },
          {
            id: "model",
            Header: strings.labels.model,
            accessor: "Model",
          },
          {
            id: "compatibleAssetTypeName",
            Header: strings.labels.compatibleAssetType,
            accessor: "CompatibleAssetTypeName",
          },
          {
            id: "replacementperiod",
            Header: strings.labels.maxReplacementPeriod,
            accessor: "ReplacementPeriod",
          },
        ]}
        header={strings.header.filterTypeList}
        headerContent={() => <CreateButton createPath={paths.filterTypes.create} label={strings.labels.createFilterType} />}
        children={<SearchBar filters={filters} handleFilterChange={handleFilterChange} clearFilters={clearFilters} tags={{}} />}
        manualPagination={true}
        filters={tableFilters as any}
        clickHandler={(filtertype) => history(paths.filterTypes.edit(filtertype.Id.toString()))}
      ></TableContainer>
    </div>
  );
};

export default FilterTypesList;
