import {EventType, InteractionType, IPublicClientApplication} from "@azure/msal-browser";
import {MsalProvider, useMsal} from "@azure/msal-react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {PropsWithChildren, useEffect, useState} from "react";
import {NotificationContainer} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {b2cPolicies} from "./authConfig";
import {PageLayout} from "./components/PageLayout";
import {ClientContextProvider} from "./context/ClientContext";
import {AppRoutes} from "./navigation/routes";
import "./styles/App.css";

declare type MsalProviderProps = PropsWithChildren<{
  instance: IPublicClientApplication;
}>;

const Pages = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const {instance} = useMsal();

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect({
              scopes: [b2cPolicies.authorities.forgotPassword.authority, "openid", "profile"],
            });
          } else if (event.interactionType === InteractionType.Popup) {
            // instance.loginPopup(b2cPolicies.authorities.forgotPassword)
            //   .catch(e => {
            //     return;
            //   });
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
            window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
            return instance.logout();
          } else if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.editProfile) {
            window.alert("Profile has been edited successfully. \nPlease sign-in again.");
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <Routes>
      <Route path="/dashboard" element={<App instance={instance} />} />
    </Routes>
  );
};

const App = ({instance}: MsalProviderProps) => {
  const [clientId, setClientId] = useState<string | null | undefined>(undefined);
  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
        <MsalProvider instance={instance}>
          <ClientContextProvider value={{clientId: clientId, setClientId: setClientId}}>
            <PageLayout>
              <NotificationContainer />
              <AppRoutes />
            </PageLayout>
          </ClientContextProvider>
        </MsalProvider>
      </LocalizationProvider>
    </Router>
  );
};

export default App;
