export const getValidationProps = (field: string, validationResults: Record<string, string[]> | null = {}): Partial<{error: boolean; helperText: string}> => {
  if (validationResults === null || !validationResults[field]) return {};
  return {
    error: true,
    helperText: validationResults[field].join(", "),
  };
};

export const getOrCreateLegendList = (chart: any, id: any) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "inline";
    listContainer.style.flexDirection = "row";
    listContainer.style.margin = "0";
    listContainer.style.padding = "0";
    legendContainer?.appendChild(listContainer);
  }
  return listContainer;
};

export const detailErrorResponse = (errorResponse: any) => {
  const response = errorResponse.response;
  const data = response ? response.data : null;
  const status = response ? response.statusText : null;
  let prefix = status ? status : "Error";

  let explanation = "An issue occurred";
  if (data !== null && data !== undefined && data.error !== null && data.error !== undefined) {
    explanation = data.error;
  }

  return `${prefix}: ${explanation}`;
};
