import Add from "@mui/icons-material/Add";
import TreeItem, {TreeItemContentProps, TreeItemProps, useTreeItem} from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import {CancelToken} from "axios";
import clsx from "clsx";
import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Mode} from "../../../components/general/types/Modify";
import {useToken} from "../../../hooks/authentication/useToken";
import {useCancelToken} from "../../../hooks/general/useCancelToken";
import paths from "../../../navigation/paths";
import {api} from "../../../services/locations.service";
import {RouteProps} from "../../../types";
import {Location} from "../../../types/documents/Location";
import FloorSelect from "../floors/FloorSelect";

interface Props extends RouteProps {
  nparentId: string;
  pnodeId: string;
  label: string;
  mode: Mode;
  depth?: number;
  setSelectedLocation: Function | undefined;
}
type Component = (props: Props) => JSX.Element;

const BuildingTreeItem: Component = ({mode, setSelectedLocation, depth, ...routeProps}) => {
  const jwt = useToken();
  const cancelToken = useCancelToken();

  const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const history = useNavigate();
    const {classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon} = props;
    const {disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection} = useTreeItem(nodeId);
    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleSelection(event);
      if (nodeId === "addFloor") {
        history(paths.floor.create(routeProps.pnodeId));
      } else if (mode === "view" && setSelectedLocation !== undefined) {
        const locationId = routeProps.pnodeId;
        const locationName = routeProps.label;
        const locationDepth = 1;
        setSelectedLocation(locationId, locationName, locationDepth);
      } else if (mode !== "view" && depth === 2) {
        history(paths.building.modify(nodeId));
      } else if (depth === 3) {
        history(paths.floor.modify(props.nodeId));
      } else if (depth === 4) {
        history(paths.room.modify(props.nodeId));
      } else if (depth === 5) {
        history(paths.room.modify(props.nodeId));
      } else {
        console.log("SetSelectedLocation function is required in view mode");
        throw new Error("SetSelectedLocation function is required in view mode");
      }
    };

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
          {label}
        </Typography>
      </div>
    );
  });

  function CustomTreeItem(props: TreeItemProps) {
    return <TreeItem ContentComponent={CustomContent} {...props} />;
  }

  const [floors, setFloors] = useState<Location[]>([]);

  useEffect(() => {
    async function getFloors(jwt: string, cancelToken: CancelToken) {
      const floorList = await api.getLocations(routeProps.pnodeId, jwt, cancelToken);
      setFloors(floorList);
    }

    if (jwt) {
      getFloors(jwt, cancelToken);
    }
  }, [jwt, cancelToken]);

  return (
    <>
      <CustomTreeItem nodeId={routeProps.pnodeId} label={routeProps.label}>
        {floors.map((floor) => (
          <FloorSelect
            mode={mode}
            setSelectedLocation={setSelectedLocation}
            nodeId={floor.Id ? floor.Id : ""}
            parentId={routeProps.pnodeId ? routeProps.pnodeId : ""}
            depth={depth ? depth + 1 : 1 }
            label={floor.Name}
            permissions={null}
            user={null}
          ></FloorSelect>
        ))}
        <CustomTreeItem
          nodeId="addFloor"
          label={<Typography sx={{textDecoration: "underline"}}>Add Floor</Typography>}
          sx={{color: "blue"}}
          icon={<Add sx={{color: "blue"}} />}
        ></CustomTreeItem>
      </CustomTreeItem>
    </>
  );
};

export default BuildingTreeItem;
