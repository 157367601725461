import {InteractionType} from "@azure/msal-browser";
import {AuthenticatedTemplate, MsalAuthenticationTemplate} from "@azure/msal-react";
import Box from "@mui/material/Box";
import {NavigationBar} from "./NavigationBar";

function ErrorComponent({error}: any) {
  if (error.errorCode === "access_denied") {
    return <p>Access Denied</p>;
  } else {
    return <p>An Error Occurred</p>;
  }
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

export const PageLayout = (props: any) => {
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{scopes: ["openid", "profile"]}}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <NavigationBar>
          <AuthenticatedTemplate>
            {props.children}
            <br></br>
            <Box display="flex" justifyContent="center">
              <footer></footer>
            </Box>
          </AuthenticatedTemplate>
        </NavigationBar>
      </MsalAuthenticationTemplate>
    </>
  );
};
