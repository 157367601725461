import { useNavigate } from "react-router-dom";
import "../../styles/App.css";
import type { Permissions, UserAccess } from "../../types";
import { Button } from "./Button";

interface Props {
  config: {
    label: string;
    userAccess: UserAccess;
    path: string;
  };
  permissions: Permissions | null;
}
type Component = (props: Props) => JSX.Element;

export const CreateEntityButton: Component = ({ config: { path, label } }) => {
  const history = useNavigate();

  const onClick = () => history(path);

  return (
    <>
      <Button
        className="bold"
        color="primary"
        variant="contained"
        label={label}
        onClick={onClick}
      />
    </>
  );
};
