import { TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import paths from "../../navigation/paths";
import { workStatusStrings as strings } from "../../resources/strings/workStatus";

interface Props {
  numberOfDays: string;
  handleNumberOfDaysChange: (value: number | undefined) => void;
}
type Component = (props: Props) => JSX.Element;

export const WorkStatusButtons: Component = ({
  numberOfDays,
  handleNumberOfDaysChange,
}) => {
  const history = useNavigate();

  // Update Job Button
  const handleCreate = () => {
    history(paths.client.create);
  };

  const [textFieldDays, setTextFieldDays] = useState<number | undefined>(7);

  const onTextFieldChange = (value: string) => {
    if (value !== "") {
      const parsedInt = parseInt(value);
      if (isNaN(parsedInt) || parsedInt <= 0) {
        setTextFieldDays(7);
        return 7;
      }
      if (parsedInt > 0 && parsedInt < 1000) {
          setTextFieldDays(parsedInt);
        return parsedInt;
        }
    } else {
      setTextFieldDays(undefined);
      return undefined;
      }
  };

  return (
    <>
      <TextField
        id="outlined-number"
        label={strings.labels.numberOfDays}
        defaultValue={textFieldDays}
        value={textFieldDays}
        style={{ width: "135px" }}
        size="small"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          const cleanedValue = onTextFieldChange(e.target.value);
          if (cleanedValue !== undefined) {
            handleNumberOfDaysChange(cleanedValue);
          }
        }}
      />
    </>
  );
};
