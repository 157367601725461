import {SxProps, Theme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {Button} from "../general/Button";

interface Props {
  createPath: string;
  label: string;
}
type Component = (props: Props) => JSX.Element;

export const CreateButton: Component = ({createPath = "", label = "Create"}) => {
  const history = useNavigate();

  // Update Job Button
  const handleCreate = () => {
    history(createPath);
  };

  const rootStyles: SxProps<Theme> = (theme) => ({
    boxShadow: "none",
    fontWeight: "bolder",
    minWidth: theme.spacing(16),
    margin: theme.spacing(0, 1),
    borderRadius: "20px",
    backgroundColor: "#2643D6",
  });

  return (
    <>
      <Button label={label} onClick={handleCreate} sx={[rootStyles]} />
    </>
  );
};
