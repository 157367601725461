export const auditTrailStrings = {
    header: {
        auditTrailList: "Audit Trail",
    },
    labels: {
        entityType: "Entity Type",
        entityId: "Entity ID",
        operation: "Operation",
        updatedBy: "Updated By",
        updatedDate: "Updated Date",
        startDate: "Start Date",
        endDate: "End Date",
    },
    placeholders: {
        select: "- select -",
    },
};