import {clientStrings as strings} from "../../resources/strings/client";
import { BespokeFilterType } from "../../types/documents/BespokeFilterType";
import {ClientSort} from "../../types/documents/Client";
import { TableContainer } from "../general/TableContainer";
import {api as filterTypeAPI} from "../../services/bespokeFilterTypes.service";
import CustomReplacementPeriodCell from "./CustomReplacementPeriodCell";

const BespokeFilterTypeList = ({}) => {
    return (
        <TableContainer<BespokeFilterType>
        api={filterTypeAPI}
        header={"Bespoke Filter Exchange Frequencies"}
        headerContent={() => <></>}
        tableColumns={[
          {
            id: ClientSort.Name,
            Header: strings.labels.name,
            accessor: "Name",
            width: 250,
          },
          {
            id: ClientSort.Address1,
            Header: "Model",
            accessor: "Model",
          },
          {
            id: ClientSort.Address2,
            Header: "GTIN",
            accessor: "GTIN",
          },
          {
            id: ClientSort.City,
            Header: "Reference Value",
            accessor: "Ref",
          },
          {
            id: ClientSort.Region,
            Header: "Default Replacement Period",
            accessor: "ReplacementPeriod",
          },
          {
            id: ClientSort.Postcode,
            Header: "Custom Replacement Period",
            accessor: (filterType) => {
              return (<>
                <CustomReplacementPeriodCell value={filterType.CustomReplacementPeriod} id={filterType.Id} defaultReplacementPeriod={filterType.ReplacementPeriod}/>
              </>)
            },
          },
        ]}
        manualPagination={true}
        defaultSort={[
          {
            id: ClientSort.Name,
            desc: true,
          },
        ]}
        clickHandler={() => {}}
        sortStorageKey={"clientSort"}
      />
    )
}

export default BespokeFilterTypeList;